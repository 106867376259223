import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from 'redux/branchComparison/branchComparisonActions';
import { Select } from 'components/ui';

export default function CityDropDown({
  onChange,
  value,
  stateId,
  error,
  placeholder,
}) {
  const dispatch = useDispatch();
  const { data: cities, isLoading } = useSelector(
    (state) => state.branchComparison.cities,
  );

  useEffect(() => {
    if (stateId) {
      dispatch(getCities(stateId));
    }
  }, [stateId]);

  return (
    <Select
      name="city"
      isSearchable
      placeholder={placeholder ?? 'Select City'}
      options={cities.map((city) => ({ label: city.name, value: city.id }))}
      onChange={onChange}
      value={value}
      isLoading={isLoading}
      error={error}
      disabled={cities.length === 0}
    />
  );
}
