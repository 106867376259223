import { getEntityAPICallV2 } from 'apis/cxmeter';
import { useBranchUpdateQuery } from 'components/queries';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { showErrorToast } from 'utils/helpers';
import { branchSetupSchema } from 'utils/validations';

export default function useLocationSetup({ branchId, branch, onUpdate }) {
  const [isLoading, setIsLoading] = useState(!!branchId);
  const {
    isLoading: isSubmitting,
    onSubmit,
    errors,
  } = useBranchUpdateQuery(branchId, onUpdate);

  const formik = useFormik({
    initialValues: !_.isEmpty(branch)
      ? branch
      : {
          name: '',
          street_address: '',
          state: '',
          city: '',
          country: '',
          zip_code: '',
        },
    onSubmit,
    validationSchema: branchSetupSchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  const fetchBranchData = async () => {
    try {
      setIsLoading(true);
      const data = await getEntityAPICallV2(branchId);
      formik.setValues({
        ...data,
        country: data.country.id,
        city: data.city.id,
        state: data.state.id,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showErrorToast(e);
    }
  };

  useEffect(() => {
    if (branchId) {
      fetchBranchData(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    if (errors) {
      formik.setErrors(errors.response.data);
    }
  }, [errors]);

  return {
    formik,
    isLoading,
    isSubmitting,
    onSubmit,
  };
}
