/* eslint-disable no-underscore-dangle */
import { Modal, Spinner } from 'components/ui';
import { useFormik } from 'formik';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RichTextEditor } from 'components/richTextEditor';
import {
  EMAIL_EDITOR_TOOLBAR_CONFIG,
  EMAIL_MANAGEMENT_PAGINATION,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import TemplateOverlays from '../TemplateOverlays/TemplateOverlays';
import {
  createInviteTemplate,
  getInviteTemplateById,
  getInviteTemplates,
  updateInviteTemplateById,
} from 'redux/emailManagement/emailManagementActions';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { inviteTemplateValidationSchema } from 'utils/validations/emailMangementValidations';
import { toast } from 'react-toastify';
import FeedbackFormSelector from '../FeedbackFormSelector';
import { contentStateToHtml } from 'utils/helpers';
import '../_manageEmailModals.scss';

function InviteTemplateModal() {
  const { t } = useTranslation();
  const editorRef = useRef();
  const dispatch = useDispatchWithErrors();
  const {
    inviteTemplate: { data: inviteTemplate, loading: inviteTemplateLoading },
  } = useSelector((s) => s.emailManagement);

  const {
    setIsOpenInviteTemplateModal,
    inviteTemplateIdRef,
    params,
    setParams,
  } = useContext(EmailManagementFiltersContext);
  const inviteTemplateId = inviteTemplateIdRef.current;

  const [loading, setLoading] = useState(false);
  const [showFeedbackTemplates, setShowFeedbackTemplates] = useState(false);

  const handleModalClose = () => {
    inviteTemplateIdRef.current = null;
    setIsOpenInviteTemplateModal(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      html_message: '',
      entity_experience: '',
    },
    validationSchema: inviteTemplateValidationSchema,
    onSubmit: async (vals) => {
      const htmlMessage = contentStateToHtml(vals.html_message);
      const data = {
        ...vals,
        html_message: htmlMessage,
        entity_experience: vals.entity_experience?.id,
      };
      setLoading(true);
      if (inviteTemplateId) {
        const { isError } = await dispatch(
          updateInviteTemplateById({
            id: inviteTemplateId,
            data,
          }),
          formik,
        );
        if (!isError) {
          toast.success(t('settings.manageEmails.toasts.updateInviteTemplate'));
          handleModalClose();
          dispatch(getInviteTemplates(params));
        }
      } else {
        const { isError } = await dispatch(createInviteTemplate(data), formik);
        if (!isError) {
          setParams({ ...EMAIL_MANAGEMENT_PAGINATION });
          toast.success(t('settings.manageEmails.toasts.createInviteTemplate'));
          handleModalClose();
        }
      }
      setLoading(false);
    },
  });

  const openFeedbackTemplates = () => {
    setShowFeedbackTemplates(FEEDBACK_TEMPLATES_OVERLAY);
  };

  useEffect(() => {
    if (inviteTemplate && inviteTemplate?.id === inviteTemplateId) {
      formik.resetForm({
        values: { ...inviteTemplate },
      });
      editorRef.current.updateState(inviteTemplate.html_message);
    }
  }, [inviteTemplateId, inviteTemplate]);

  useEffect(() => {
    if (inviteTemplateId) {
      dispatch(getInviteTemplateById(inviteTemplateId));
    }
  }, [inviteTemplateId]);

  const { touched, errors, values } = formik;

  return (
    <Modal
      open
      onClose={handleModalClose}
      backDropClose={false}
      className="email-management-modals"
    >
      {inviteTemplateLoading || loading ? <Spinner /> : null}
      <Modal.Header
        title="Email Template"
        isEditable={inviteTemplateId}
        formik={formik}
        inputName="name"
        onClose={handleModalClose}
      />
      <Modal.Body>
        <article className="cxmeter-modal-body-content invite-template-modal">
          {inviteTemplateId ? null : (
            <div className="control-group">
              <label
                className={`control ${
                  touched.name && errors.name ? 'control-invalid' : ''
                }`}
              >
                <span className="required-field">
                  Template Name <span>*</span>
                </span>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter"
                  onChange={formik.handleChange}
                  value={values.name}
                />
                {touched.name && (
                  <span className="control-error">{errors.name}</span>
                )}
              </label>
            </div>
          )}
          <div className="email-body">
            <RichTextEditor
              setState={(htmlMessage) => {
                formik.setFieldValue('html_message', htmlMessage);
              }}
              content={values.html_message}
              error={formik.submitCount > 0 ? errors.html_message : ''}
              toolbarConfig={EMAIL_EDITOR_TOOLBAR_CONFIG}
              toolbarOnFocus={false}
              ref={editorRef}
              errorStyles={{}}
            />
          </div>
          <div className="add-templates-btns">
            <div>
              <FeedbackFormSelector
                openFeedbackTemplates={openFeedbackTemplates}
                selectedFeedbackForm={values.entity_experience?.title}
              />
            </div>
            {touched.entity_experience && (
              <span className="control-error">{errors.entity_experience}</span>
            )}
          </div>
        </article>
      </Modal.Body>
      <Modal.Footer
        isLoading={loading}
        primaryAction={formik.submitForm}
        primaryLabel={inviteTemplateId ? 'Update Template' : 'Create Template'}
        disablePrimaryAction={loading}
      />
      {showFeedbackTemplates ? (
        <TemplateOverlays
          formik={formik}
          currentOverlayPreview={showFeedbackTemplates}
          setCurrentOverlayPreview={setShowFeedbackTemplates}
        />
      ) : null}
    </Modal>
  );
}

export default InviteTemplateModal;
