import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  parentEntitySubEntityAdminUserAPICall,
  userEntityAdminsCreateAPICall,
  userEntityAdminsUpdateAPICall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getUserList = createAsyncThunk(
  'users/getUserList',
  async (params) =>
    parentEntitySubEntityAdminUserAPICall(params).catch(showErrorToast),
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (data, { rejectWithValue }) =>
    userEntityAdminsCreateAPICall(data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, data }, { rejectWithValue }) =>
    userEntityAdminsUpdateAPICall(id, data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);
