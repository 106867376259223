import CityDropDown from 'components/demographics/cityDropDown/CityDropDown';
import CountryDropDown from 'components/demographics/countryDropDown/CountryDropDown';
import StateDropDown from 'components/demographics/stateDropDown/StateDropDown';

export default function LocationInfoForm({ formik }) {
  return (
    <form>
      <label
        className={`control ${
          formik.touched.name && formik.errors.name ? 'control-invalid' : ''
        }`}
      >
        <input
          name="name"
          type="text"
          placeholder="Location Name *"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.touched.name && (
          <span className="control-error">{formik.errors.name}</span>
        )}
      </label>
      <label
        className={` control
            ${
              formik.touched.street_address && formik.errors.street_address
                ? 'control-invalid'
                : ''
            }
          `}
      >
        <input
          name="street_address"
          type="text"
          placeholder="Street Address *"
          value={formik.values.street_address}
          onChange={formik.handleChange}
        />
        {formik.touched.street_address && (
          <span className="control-error">{formik.errors.street_address}</span>
        )}
      </label>
      <CountryDropDown
        placeholder="Select Country *"
        value={formik.values.country}
        onChange={(id) => formik.setFieldValue('country', id)}
        error={
          formik.touched.country && formik.errors.country ? (
            <span className="control-error">{formik.errors.country}</span>
          ) : null
        }
      />

      <div className="onboarding-location-dropdowns">
        <StateDropDown
          placeholder="Select State *"
          onChange={(id) => formik.setFieldValue('state', id)}
          value={formik.values.state}
          countryId={formik.values.country}
          error={
            formik.touched.state && formik.errors.state ? (
              <span className="txt-error">{formik.errors.state}</span>
            ) : null
          }
        />
        <CityDropDown
          placeholder="Select City *"
          onChange={(id) => formik.setFieldValue('city', id)}
          value={formik.values.city}
          stateId={formik.values.state}
          error={
            formik.touched.city && formik.errors.city ? (
              <span className="txt-error">{formik.errors.city}</span>
            ) : null
          }
        />
        <label
          className={`control ${
            formik.touched.zip_code && formik.errors.zip_code
              ? 'control-invalid'
              : ''
          }`}
        >
          <input
            name="zip_code"
            type="text"
            placeholder="Zip Code *"
            value={formik.values.zip_code}
            onChange={formik.handleChange}
          />
          {formik.touched.zip_code ? (
            <span className="control-error">{formik.errors.zip_code}</span>
          ) : null}
        </label>
      </div>
    </form>
  );
}
