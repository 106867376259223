/* eslint-disable react/jsx-filename-extension */
import { faExternalLink, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'components/ui';
import Checkbox from 'components/ui/checkbox/Checkbox';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import {
  DELETE_SCHEDULE_PREFIX,
  DELETE_TEMPLATE_PREFIX,
} from 'utils/constants/manageEmails';
import { contentStateToHtml } from './global';
import { Popconfirm } from 'antd';
import { useState } from 'react';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { sendInviteReminderById } from 'redux/emailManagement/emailManagementActions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const getToColumnValue = (rec) => {
  const { groups, customers } = rec ?? {};
  if (isEmpty(groups) && isEmpty(customers)) return '-';
  return (
    <span className="groups-and-customer-info">
      {!isEmpty(groups) ? (
        <span className="group-name">
          <span>{groups.first_value}</span>
          <span className="more-info">
            (Group) {groups.count ? `+${groups.count} more` : ''}
          </span>
        </span>
      ) : null}
      {!isEmpty(customers) ? (
        <span className="customer-name">
          <span>{customers.first_value}</span>
          <span className="more-info">
            (Customer) {customers.count ? `+${customers.count} more` : ''}
          </span>
        </span>
      ) : null}
    </span>
  );
};

function SendReminderButton({ record, loading, updateInviteRecord }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const [sentReminderResponse, setSentReminderResponse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onSendInviteReminder = async () => {
    if (isOpen) setIsOpen(false);
    const { isError, data } = await dispatch(
      sendInviteReminderById({
        invite_record_ids: [record.id],
        params: {
          force: sentReminderResponse?.sendReminderForcefully ?? false,
        },
      }),
    );
    if (isError) {
      setIsOpen(true);
      setSentReminderResponse({
        sendReminderForcefully: true,
        description: data.response.data.detail,
      });
    } else {
      updateInviteRecord();
      toast.success(
        t('settings.manageEmails.toasts.reminderInvite', {
          reminder: 'Reminder',
        }),
      );
    }
  };

  const onOpenChange = (newOpen) => {
    if (!newOpen) {
      setIsOpen(newOpen);
      setSentReminderResponse(null);

      return;
    }
    if (sentReminderResponse?.sendReminderForcefully) {
      setIsOpen(true);
    } else {
      onSendInviteReminder();
    }
  };

  return (
    <Popconfirm
      placement="topLeft"
      title="Are you sure to send reminder"
      description={
        sentReminderResponse?.description ?? 'You have already sent reminder.'
      }
      onConfirm={onSendInviteReminder}
      onOpenChange={onOpenChange}
      okText="Yes"
      cancelText="No"
      open={isOpen}
      cancelButtonProps={{ className: 'button button-secondary' }}
      okButtonProps={{ className: 'button button-primary' }}
    >
      <button disabled={loading} className="send-reminder">
        <span>Send Reminder</span>
        {loading ? (
          <FontAwesomeIcon width={12} height={12} icon={faSpinner} />
        ) : (
          <FontAwesomeIcon icon={faExternalLink} />
        )}
      </button>
    </Popconfirm>
  );
}

export const getInboxColumns = (onAction) => [
  {
    title: 'SUBJECT',
    dataIndex: 'subject',
    key: 'subject',
    width: 'auto',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'DATE & TIME',
    render: (text, rec) =>
      rec.sent_datetime
        ? dayjs(rec.sent_datetime).format('MMMM DD • h:mm A')
        : '-',
    width: 210,
    key: 'datetime',
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: 'TO',
    render: (text, rec) => getToColumnValue(rec),
    width: 200,
    key: 'to',
  },
  {
    title: 'RESPONSES',
    render: (text, rec) => (
      <div className="inbox-response">
        <div className="response-count">
          <p>Received</p>
          <p className="count">{rec.received_count}</p>
        </div>
        <div className="seprator" />
        <div className="response-count">
          <p>Pending</p>
          <p className="count">{rec.pending_count}</p>
        </div>
      </div>
    ),
    width: 170,
    key: 'responses',
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    width: 100,
    render: (text, record) => (
      <span className="email-tables-actions">
        <button onClick={() => onAction(record)}>
          <Icon type="visibility" />
        </button>
      </span>
    ),
  },
];

export const getReceivedInboxTableColumns = (onShowReview) => [
  {
    title: 'TO',
    render: (text, rec) => rec.recipient.email,
  },
  {
    title: 'Date and Time',
    render: (text, rec) =>
      rec.received_at ? dayjs(rec.received_at).format('MMMM DD • h:mm A') : '-',
  },
  {
    title: 'ACTIONS',
    key: '',
    width: '3%',
    render: (text, record) =>
      record.feedback_id ? (
        <span className="email-sub-tables-actions">
          <button onClick={() => onShowReview(record)}>
            <FontAwesomeIcon icon={faExternalLink} />
          </button>
        </span>
      ) : null,
  },
];

export const getPendingInboxTableColumns = (
  onDeleteInvite,
  updateInviteRecord,
  rowsCount,
  selectedRows,
  handleSelectAllChange,
  handleSelectChange,
  pendingInvitesLoading,
) => {
  const selectedRowsCount = Object.keys(selectedRows).length;
  const isAllSeleted = selectedRowsCount === rowsCount && rowsCount > 0;

  return [
    {
      title: (
        <Checkbox
          checked={isAllSeleted}
          checkedStyles={{
            backgroundColor: '#DE0024',
            borderColor: '#DE0024',
          }}
          onChange={handleSelectAllChange}
        />
      ),
      dataIndex: 'checkbox',
      width: '1%',
      render: (_, record) => (
        <Checkbox
          checked={Boolean(selectedRows[record.id])}
          checkedStyles={{
            backgroundColor: '#DE0024',
            borderColor: '#DE0024',
          }}
          onChange={() => handleSelectChange(record)}
        />
      ),
    },
    {
      title: 'TO',
      render: (text, rec) => rec.recipient.email,
    },
    {
      title: 'Reminders Sent',
      render: (text, rec) => rec.reminders_count ?? 0,
    },
    {
      title: 'Last Reminded At',
      render: (text, rec) =>
        rec.last_reminded_at
          ? dayjs(rec.last_reminded_at).format('MMMM DD • h:mm A')
          : '-',
    },
    {
      title: 'ACTIONS',
      key: '',
      width: 200,
      render: (text, record) => {
        const deleteLoading = pendingInvitesLoading.deleteLoading[record.id];
        const reminderLoading =
          pendingInvitesLoading.reminderLoading[record.id];
        return (
          <span className="email-sub-tables-actions">
            <SendReminderButton
              record={record}
              updateInviteRecord={updateInviteRecord}
              loading={reminderLoading}
            />
            <button
              disabled={deleteLoading}
              onClick={() => onDeleteInvite(record)}
            >
              {deleteLoading ? (
                <FontAwesomeIcon width={24} height={24} icon={faSpinner} />
              ) : (
                <Icon type="delete" />
              )}
            </button>
          </span>
        );
      },
    },
  ];
};

export const getGroupsColumns = (onViewGroup, onForwardMail) => [
  {
    title: 'NAME',
    dataIndex: 'name',
    width: 'auto',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'TO',
    render: (r) => <span>{r.members} users</span>,
  },
  {
    title: 'ACTIONS',
    key: '',
    width: 135,
    render: (text, record) => (
      <span className="email-tables-actions">
        <button onClick={() => onViewGroup(record)}>
          <Icon type="visibility" />
        </button>
        <button onClick={() => onForwardMail(record)}>
          <Icon type="outgoing_mail" />
        </button>
      </span>
    ),
  },
];

export const getEmailSchedulesColumns = (
  onViewSchedule,
  onDeleteSchedule,
  deleteInviteSchedulesLoading,
) => [
  {
    title: 'SUBJECT',
    dataIndex: 'subject',
    width: 'auto',
  },
  {
    title: 'SCHEDULED AT',
    render: (text, rec) => dayjs(rec.scheduled_at).format('MMMM DD • h:mm A'),
    width: 'auto',
  },
  {
    title: 'TO',
    render: (text, rec) => getToColumnValue(rec),
  },
  {
    title: 'ACTIONS',
    key: '',
    width: 135,
    render: (text, record) => {
      const itemId = `${DELETE_SCHEDULE_PREFIX}-${record.id}`;
      const actionsDisabled = deleteInviteSchedulesLoading[itemId];
      return (
        <span
          style={{ opacity: actionsDisabled ? 0.4 : 1 }}
          className="email-tables-actions"
        >
          <button
            disabled={actionsDisabled}
            onClick={() => onViewSchedule(record)}
          >
            <Icon type="visibility" />
          </button>
          <button
            onClick={() => onDeleteSchedule(record)}
            disabled={actionsDisabled}
          >
            {actionsDisabled ? (
              <FontAwesomeIcon width={24} height={24} icon={faSpinner} />
            ) : (
              <Icon type="delete" />
            )}
          </button>
        </span>
      );
    },
  },
];

export const getEmailTemplatesColumns = (
  deleteInviteTemplatesLoading,
  onViewTemplate,
  onCompose,
  onDeleteTemplate,
) => [
  {
    title: 'NAME',
    dataIndex: 'name',
    width: 'auto',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: 'ACTIONS',
    key: '',
    width: '3%',
    render: (text, record) => {
      const itemId = `${DELETE_TEMPLATE_PREFIX}-${record.id}`;
      const actionsDisabled = deleteInviteTemplatesLoading[itemId];
      return (
        <span
          style={{ opacity: actionsDisabled ? 0.4 : 1 }}
          className="email-tables-actions"
        >
          <button
            disabled={actionsDisabled}
            onClick={() => onViewTemplate(record)}
          >
            <Icon type="visibility" />
          </button>
          <button
            disabled={actionsDisabled}
            className="outgoing_mail"
            onClick={() => onCompose(record)}
          >
            <Icon type="outgoing_mail" />
          </button>
          <button
            onClick={() => onDeleteTemplate(record)}
            disabled={actionsDisabled}
          >
            {deleteInviteTemplatesLoading[itemId] ? (
              <FontAwesomeIcon width={24} height={24} icon={faSpinner} />
            ) : (
              <Icon type="delete" />
            )}
          </button>
        </span>
      );
    },
  },
];

const getFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      v.forEach((val, index) => {
        formData.append(`${k}[${index}]`, JSON.stringify(val));
      });
    } else if (v) {
      formData.append(k, v);
    }
  });
  return formData;
};

export const formatGroupData = (values) => {
  return getFormData(values);
};

export const formatInviteDataFromAPI = (inviteSchedule) => {
  const {
    scheduled_at: scheduleAt,
    reminder_schedules: reminderSchedules,
    customers,
    ...restInviteData
  } = inviteSchedule;
  const inviteData = {
    ...restInviteData,
    members: customers,
    show_email_schedule: !isEmpty(scheduleAt),
    show_reminder_schedule: !isEmpty(reminderSchedules),
  };
  if (!isEmpty(scheduleAt)) {
    inviteData.scheduled_at = {
      date: dayjs(scheduleAt),
      time: dayjs(
        `${dayjs().format('YYYY-MM-DD')} ${dayjs(scheduleAt).format('HH:mmZ')}`,
      ),
    };
  }
  if (!isEmpty(reminderSchedules)) {
    const reminderSchedule = reminderSchedules[0];
    const customReminderDays = reminderSchedule.custom_reminder_day
      ? reminderSchedules.map((schedule) => schedule.custom_reminder_day)
      : [];
    inviteData.reminder_schedules = {
      custom_reminder_day: customReminderDays,
      ends_at: dayjs(reminderSchedule.ends_at),
      reminder_time: dayjs(reminderSchedule.reminder_time, 'hh:mm:ss'),
      reminder_type: reminderSchedule.reminder_type,
    };
  }
  return inviteData;
};

export const formatInviteDataFromForm = (values) => {
  let shceduleData = {
    ...values.reminder_schedules,
    reminder_time:
      values.reminder_schedules?.reminder_time?.format('HH:mm:ssZ'),
  };
  let schedule;
  const htmlMessage = contentStateToHtml(values.html_message);
  let invite = {
    ...omit(
      values,
      'show_reminder_schedule',
      'updateHTMLEditorState',
      'show_email_schedule',
    ),
    html_message: htmlMessage,
    entity_experience: values.entity_experience?.id,
  };
  if (!values.show_email_schedule) {
    invite = omit(invite, 'scheduled_at');
  } else {
    const { date, time } = invite.scheduled_at;
    invite.scheduled_at = `${date.format('YYYY-MM-DD')} ${time.format(
      'HH:mm:ssZ',
    )}`;
  }
  if (!values.show_reminder_schedule) {
    invite = omit(invite, 'reminder_schedules');
  } else if (invite.reminder_schedules.reminder_type !== 'Custom') {
    shceduleData = omit(shceduleData, 'custom_reminder_day');
    invite.reminder_schedules = [shceduleData];
  } else {
    schedule = shceduleData.custom_reminder_day.map((day) => ({
      ...shceduleData,
      custom_reminder_day: day,
    }));
    invite.reminder_schedules = schedule;
  }
  if (invite.groups.length) {
    invite.groups = invite.groups.map((g) => g.id);
  }

  const inviteScheduleFormData = getFormData(invite);
  return inviteScheduleFormData;
};
