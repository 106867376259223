import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getComparisonByBranch,
  getCities,
  getCountries,
  getLocationsV2,
  getStates,
  getComparisonByExperience,
  getComparisonByDateRange,
} from './branchComparisonActions';

const INITIAL_STATE = {
  isLoading: false,
  comparison: {},
  locations: {
    isLoading: false,
    data: [],
  },
  countries: {
    isLoading: false,
    data: [],
  },
  states: {
    isLoading: false,
    data: [],
  },
  cities: {
    isLoading: false,
    data: [],
  },
};

const branchComparisonSlice = createSlice({
  name: 'branchComparisonSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setBranchComparison: (state, action) => {
      state.comparison = action.payload;
    },
    onCountryChangeAction: (state) => {
      state.states.data = [];
      state.cities.data = [];
    },
    onStateChangeAction: (state) => {
      state.cities.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocationsV2.pending, (state) => {
      state.locations.isLoading = true;
    });
    builder.addCase(getLocationsV2.rejected, (state) => {
      state.locations.isLoading = false;
    });
    builder.addCase(getLocationsV2.fulfilled, (state, action) => {
      state.locations.isLoading = false;
      state.locations.data = action.payload;
    });

    builder.addCase(getCountries.pending, (state) => {
      state.countries.isLoading = true;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.countries.isLoading = false;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries.isLoading = false;
      state.countries.data = action.payload;
    });

    builder.addCase(getStates.pending, (state) => {
      state.states.isLoading = true;
    });
    builder.addCase(getStates.rejected, (state) => {
      state.states.isLoading = false;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.states.isLoading = false;
      state.states.data = action.payload;
    });

    builder.addCase(getCities.pending, (state) => {
      state.cities.isLoading = true;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.cities.getCities = false;
    });
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities.isLoading = false;
      state.cities.data = action.payload;
    });

    builder.addMatcher(
      isAnyOf(
        getComparisonByBranch.pending,
        getComparisonByExperience.pending,
        getComparisonByDateRange.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getComparisonByBranch.rejected,
        getComparisonByExperience.rejected,
        getComparisonByDateRange.rejected,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getComparisonByBranch.fulfilled,
        getComparisonByExperience.fulfilled,
        getComparisonByDateRange.fulfilled,
      ),
      (state, action) => {
        state.isLoading = false;
        state.comparison = action.payload;
      },
    );
    return builder;
  },
});
export const {
  setBranchComparison,
  onCountryChangeAction,
  onStateChangeAction,
} = branchComparisonSlice.actions;
export default branchComparisonSlice;
