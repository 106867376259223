import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import Yup from './Yup';
import { addressInfoSchema } from './common';

export const billingInfo = {
  name: Yup.string().requiredTrimmed(
    'Name cannot be only blank spaces.',
    'Name is required!',
  ),
  email: Yup.string().required('Email is required!').customEmail(),
};

export const paymentDetails = {
  cardholderName: Yup.string()
    .matches(
      onlyLettersAndSpacesRegex,
      'Card holder name can only contain letters',
    )
    .requiredTrimmed('Name cannot be only blank spaces.', 'Name is required!'),
};

export const billingInfoValidationSchema = Yup.object().shape({
  ...billingInfo,
});

export const billingContactValidationSchema = Yup.object().shape({
  ...addressInfoSchema,
});

export const paymentValidationSchema = Yup.object().shape({
  ...paymentDetails,
});

export const paymentAndBillingValidationSchema = Yup.object().shape({
  ...paymentDetails,
  ...addressInfoSchema,
});

export const editPaymentCardValidationSchema = Yup.object().shape({
  ...paymentDetails,
  expMonth: Yup.number().required('Expiry Month is required!'),
  expYear: Yup.number().required('Expiry Year is required!'),
});

export const isNumberKey = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
};
