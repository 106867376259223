import React from 'react';
import { Modal } from 'components/ui';
import AppQRCodes from './AppQRCodes';

export default function AppQRCodesModal({ onClose, isOpenModal }) {
  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header title="App QR Codes" onClose={onClose} />
      <Modal.Body>
        <div style={{ maxHeight: 'calc(100% - 100px' }}>
          <AppQRCodes />
        </div>
      </Modal.Body>
      <Modal.Footer primaryLabel="Close" primaryAction={onClose} />
    </Modal>
  );
}
