/* eslint-disable no-return-await */
import { getParentEntity, storeParentEntity, toFormData } from 'utils/helpers';
import {
  SEGMENT_LIST_API,
  ENTITY_EXPERIENCE_LIST_API,
  FEEDBACK_STATISTICS_BY_DRIVER_API,
  LOCATION_LIST_API,
  FEEDBACK_STATISTICS_BY_SEGMENT_API,
  FEEDBACK_STATISTICS_BY_TIMELINE_API,
  DRIVER_LOYALTY_AND_CONCERN_API,
  FEEDBACK_CUSTOMER_LIST_API,
  FEEDBACK_SURVEY_METRIC_API,
  HAPPINESS_INDEXES_BY_DAY_WEEK_MONTH_API,
  PARENT_ENTITY_SUB_ENTITY_ADMIN_USER_LIST_API,
  CITY_LIST_API,
  FEEDBACK_RATING_API,
  USER_ENTITY_ADMINS_CREATE_API,
  USER_ENTITY_ADMINS_UPDATE_API,
  EXPERIENCE_LIST_API,
  PARENT_ENTITY_TYPE_API,
  PARENT_ENTITY_LIST_API,
  PARENT_ENTITY_GET_API,
  PARENT_ENTITY_ADMIN_CREATE_API,
  ENTITY_EXPERIENCE_QRCODES_CREATE_API,
  PARENT_ENTITY_DIGEST_API,
  LUCKY_DRAW_WINNER_API,
  PARENT_ENTITY_SELECT_API,
  PARENT_ENTITY_API,
  ENTITY_LIST_API_V2,
  ENTITY_SURVEY_ASSIGNMENT,
  ENTITY_API_V2,
  BRANCH_ACTIVATION_API,
  COUNTRIES_LIST_API_V2,
  STATES_LIST_API_V2,
  CITIES_LIST_API_V2,
  ENTITY_SURVEY_CONTENT_V2,
  HELP_ITEM_API_V2,
  QR_API_V2,
  QR_ALL_PRINT_API,
  SAAS_AGREEMENT_VERSION_URL,
  USER_API_V2,
  PORTAL_FEEDBACK_URL,
  PORTAL_FEEDBACK_SUBMIT_URL,
  UPDATE_PORTAL_FEEDBACK_URL,
  ENTITY_LINKED_EXPERIENCES_LIST_API,
} from './urls';
import {
  deleteAPICall,
  getAPICall,
  patchAPICall,
  postAPICall,
  putAPICall,
} from './utils';

export async function segmentListAPICall() {
  return await getAPICall(SEGMENT_LIST_API);
}

export async function experienceListAPICall() {
  return await getAPICall(EXPERIENCE_LIST_API);
}

export async function createExperienceAPICall(name, industryType) {
  return await postAPICall(EXPERIENCE_LIST_API, {
    name,
    pe_type: industryType,
  });
}

export async function entityExperienceListAPICall() {
  return await getAPICall(ENTITY_EXPERIENCE_LIST_API);
}

export async function locationListAPICall() {
  return await getAPICall(LOCATION_LIST_API);
}

export async function feedbackStatisticsByDriverAPICall(params = {}) {
  return await getAPICall(FEEDBACK_STATISTICS_BY_DRIVER_API, params);
}

export async function feedbackStatisticsBySegmentsAPICall(params = {}) {
  return await getAPICall(FEEDBACK_STATISTICS_BY_SEGMENT_API, params);
}

export async function feedbackStatisticsByTimelineAPICall(params = {}) {
  return await getAPICall(FEEDBACK_STATISTICS_BY_TIMELINE_API, params);
}

export async function driverLoyaltyAndConcernAPICall(params = {}) {
  return await getAPICall(DRIVER_LOYALTY_AND_CONCERN_API, params);
}

export async function feedbackCustomerListAPICall(params = {}) {
  return await getAPICall(FEEDBACK_CUSTOMER_LIST_API, params);
}

export async function feedbackSurveyMetricAPICall(params = {}) {
  return await getAPICall(FEEDBACK_SURVEY_METRIC_API, params);
}

export async function happinessIndexesByDayWeekMonthAPICall() {
  return await getAPICall(HAPPINESS_INDEXES_BY_DAY_WEEK_MONTH_API);
}

export async function parentEntitySubEntityAdminUserAPICall(params = {}) {
  return await getAPICall(PARENT_ENTITY_SUB_ENTITY_ADMIN_USER_LIST_API, params);
}

export async function cityListAPICall() {
  return await getAPICall(CITY_LIST_API);
}

export async function entityCreateAPICallV2(data) {
  return await postAPICall(ENTITY_API_V2, data);
}
export async function entityUpdateAPICallV2(branchId, data) {
  return await putAPICall(`${ENTITY_API_V2}${branchId}/`, data);
}

export async function getEntityAPICallV2(branchId, data) {
  return await getAPICall(`${ENTITY_API_V2}${branchId}`, data);
}

export async function assignEntitySurveyForm(branchId, data) {
  return await postAPICall(ENTITY_SURVEY_ASSIGNMENT(branchId), data);
}

export async function listEntitySurveyAssignment(branchId, data) {
  return await getAPICall(ENTITY_SURVEY_ASSIGNMENT(branchId), data);
}
export async function updateEntitySurveyAssignment(branchId, data) {
  return await putAPICall(ENTITY_SURVEY_ASSIGNMENT(branchId), data);
}

export async function feedbackRatingAPICall() {
  return await getAPICall(FEEDBACK_RATING_API);
}

export async function entityListAPICallV2(params) {
  return await getAPICall(ENTITY_LIST_API_V2, params);
}

export async function entitySurveyContentV2(entityExpId, params = {}) {
  return await getAPICall(ENTITY_SURVEY_CONTENT_V2(entityExpId), params);
}

export async function userEntityAdminsCreateAPICall(data) {
  return await postAPICall(USER_ENTITY_ADMINS_CREATE_API, data);
}

export async function userEntityAdminsUpdateAPICall(userId, data) {
  return await putAPICall(`${USER_ENTITY_ADMINS_UPDATE_API}${userId}/`, data);
}

export async function parentEntityTypeAPICall(params) {
  return await getAPICall(PARENT_ENTITY_TYPE_API, params, true);
}

export async function parentEntityListAPICall() {
  return await getAPICall(PARENT_ENTITY_LIST_API);
}

export async function parentEntityGetAPICall(params = {}) {
  const parentEntity = getParentEntity();
  return await getAPICall(
    PARENT_ENTITY_GET_API(parentEntity?.id?.toString()),
    params,
  );
}

export async function parentEntityPatchAPICall(data) {
  const parentEntity = getParentEntity();
  return await patchAPICall(
    PARENT_ENTITY_GET_API(parentEntity?.id?.toString()),
    data,
  );
}

export async function luckyDrawWinnerListAPICall(params = {}) {
  return await getAPICall(LUCKY_DRAW_WINNER_API, params);
}

export async function luckyDrawWinnerCreateAPICall(data = {}) {
  return await postAPICall(LUCKY_DRAW_WINNER_API, data);
}

export async function parentEntityAdminCreateAPICall(data) {
  return await postAPICall(PARENT_ENTITY_ADMIN_CREATE_API, data);
}

export async function parentEntitySelectAPICall(parenEntityId) {
  return await postAPICall(
    `${PARENT_ENTITY_SELECT_API}${parenEntityId}/select/`,
  );
}

export async function singleParentEntitySelectAPICall() {
  try {
    const orgs = await parentEntityListAPICall();
    let selectedOrg = null;
    if (orgs.length === 1) {
      storeParentEntity(orgs[0]);
      selectedOrg = await parentEntitySelectAPICall(orgs[0].id);
    }
    return Promise.resolve({ orgs, selectedOrg });
  } catch (e) {
    return Promise.reject(e);
  }
}
export async function entityExperienceQRCodesCreateAPICall(data) {
  return await postAPICall(ENTITY_EXPERIENCE_QRCODES_CREATE_API, data);
}

export async function parentEntityDigestAPICall({ date }) {
  return await getAPICall(PARENT_ENTITY_DIGEST_API, { date });
}

export async function createOrgAPICall(data) {
  return await postAPICall(PARENT_ENTITY_API, data);
}

export async function updateOrgAPICall(data) {
  return await patchAPICall(
    `${PARENT_ENTITY_API}${data.id}/`,
    toFormData(data),
  );
}

export async function setBranchActivationAPICall(data) {
  return await patchAPICall(BRANCH_ACTIVATION_API, {
    ...data,
    last_onboarding_url: null,
  });
}

export async function getCountriesAPICall() {
  return await getAPICall(COUNTRIES_LIST_API_V2);
}

export async function getStatesAPICall(countryId) {
  return await getAPICall(STATES_LIST_API_V2(countryId));
}

export async function getCitiesAPICall(cityId) {
  return await getAPICall(CITIES_LIST_API_V2(cityId));
}

export async function createHelpItemAPICall(data) {
  return await postAPICall(HELP_ITEM_API_V2, data);
}

export async function qrListAPICall(data) {
  return await getAPICall(QR_API_V2(), data);
}
export async function initializeQrAPICall(data) {
  return await postAPICall(QR_API_V2('initialize/'), data);
}
export async function getQrAPICall(id) {
  return await getAPICall(QR_API_V2(`${id}/`));
}
export async function updateQrAPICall(id, data) {
  return await patchAPICall(QR_API_V2(`${id}/`), data);
}
export async function createQrAPICall(data) {
  return await postAPICall(QR_API_V2(), data);
}
export async function deleteQrAPICall(id) {
  return await deleteAPICall(QR_API_V2(id));
}

export async function shareQrAPICall(data) {
  return await postAPICall(QR_API_V2('share/'), data);
}

export async function printAllQrsAPICall() {
  return await getAPICall(QR_ALL_PRINT_API, {}, true, 'arraybuffer');
}

export async function getSaasAgreementVersionAPICall() {
  return await getAPICall(SAAS_AGREEMENT_VERSION_URL);
}

export async function postSaasAgreementVersionAPICall(data) {
  return await postAPICall(SAAS_AGREEMENT_VERSION_URL, data);
}

export async function patchSaasAgreementVersionAPICall(data) {
  return await patchAPICall(SAAS_AGREEMENT_VERSION_URL, data);
}

export async function patchUserAPICall(data) {
  return await patchAPICall(`${USER_API_V2()}me/`, data);
}

export async function getPortalFeedbackFormAPICall() {
  return getAPICall(PORTAL_FEEDBACK_URL);
}

export async function submitPortalFeedbackAPICall(id, data) {
  return postAPICall(
    PORTAL_FEEDBACK_SUBMIT_URL(id),
    data,
    {},
    true,
    'multipart/form-data',
  );
}

export async function updatePortalDeedbackAPICall(expId, feedbackId, data) {
  return putAPICall(
    UPDATE_PORTAL_FEEDBACK_URL(expId, feedbackId),
    data,
    true,
    'multipart/form-data',
  );
}

export async function entityLinkedExperiencesGetAPICall(params) {
  return await getAPICall(ENTITY_LINKED_EXPERIENCES_LIST_API, params);
}
