import {
  onlyLettersAndNumbersRegex,
  phoneNumberRegex,
} from 'utils/constants/regex';
import Yup from './Yup';
import { customExperienceSchema } from './common';

const organizationTypeSchema = customExperienceSchema('Organization type');

const regOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Organization name cannot be only blank spaces.',
      'Organization name is required!',
    )
    .matches(
      onlyLettersAndNumbersRegex,
      'Organization name cannot contain special characters',
    ),
  logo: Yup.mixed().nullable(),
  type: organizationTypeSchema,
  email: Yup.string().customEmail().notRequired(),
  tnc: Yup.boolean().oneOf(
    [true],
    'You need to agree to our terms and conditions to proceed!',
  ),
  phone_number: Yup.string()
    .notRequired()
    .matches(phoneNumberRegex, 'Please enter a valid phone number.')
    .range(
      9,
      15,
      'Must be at least 9 characters.',
      'Must not exceed 15 characters',
    ),
});

export default regOrganizationSchema;
