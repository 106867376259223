import { createContext } from 'react';

const EmailManagementFiltersContext = createContext({
  searchFilter: {},
  setSearchFilter() {},
  params: {},
  setParams() {},
});

export default EmailManagementFiltersContext;
