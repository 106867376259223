import { CHECKBOX, TEXT } from 'utils/constants/questionTypes';
import * as Yup from 'yup';

const optionSchema = Yup.object().shape({
  option_id: Yup.number(),
  label: Yup.string(),
  selected: Yup.boolean(),
});

const questionSchema = Yup.object()
  .shape({
    label: Yup.string(),
    question_type: Yup.string(),
    options: Yup.array(optionSchema),
    question_uuid: Yup.string(),
    feedback: Yup.string()
      .trim()
      .when('question_type', {
        is: TEXT,
        then: Yup.string()
          .required('Feedback is required for text questions')
          .min(1, 'Feedback cannot be empty'),
        otherwise: Yup.string().notRequired(),
      }),
  })
  .test(
    'checkbox-selected',
    'At least one option must be selected',
    (value) => {
      if (value.question_type === CHECKBOX) {
        return value.options.some((option) => option.selected === true);
      }
      return true;
    },
  );

const sectionSchema = Yup.object().shape({
  section_uuid: Yup.string(),
  questions: Yup.array()
    .of(questionSchema)
    .min(1, 'At least one question is required'),
});

const cancelPlanSchema = Yup.object().shape({
  forms: Yup.object().shape({ sections: Yup.array().of(sectionSchema) }),
});

export default cancelPlanSchema;
