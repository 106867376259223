import { createSlice } from '@reduxjs/toolkit';
import {
  editProfile,
  changePassword,
  getUserAndInitialiseData,
  getParentEntityAction,
  updateParentEntity,
  updateUser,
} from './authenticationActions';

const INITIAL_STATE = {
  isActiveOrg: true,
  user: {},
  parentEntity: {},
  token: localStorage.getItem('access_token'),
  isLoading: false,
  isAppLoading: true,
};

const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthenticationSlice: () => INITIAL_STATE,
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAppLoading = false;
    },
    setAppLoading: (state, action) => {
      state.isAppLoading = action.payload;
    },
    setParentEntity: (state, action) => {
      state.parentEntity = action.payload;
      state.isAppLoading = false;
    },
    setLastOnboardingStep: (state, action) => {
      state.parentEntity.last_onboarding_url = action.payload;
    },
    setQrLinked: (state, action) => {
      state.parentEntity.has_linked_qr_codes = action.payload;
    },
    activateOrg: (state) => {
      state.isActiveOrg = true;
    },
    deactivateOrg: (state) => {
      state.isActiveOrg = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParentEntityAction.fulfilled, (state, action) => {
      state.isActiveOrg = action.payload.is_active;
      state.parentEntity = action.payload;
    });
    builder.addCase(getUserAndInitialiseData.pending, (state) => {
      state.isAppLoading = true;
    });
    builder.addCase(getUserAndInitialiseData.rejected, (state) => {
      state.isAppLoading = false;
    });
    builder.addCase(getUserAndInitialiseData.fulfilled, (state, action) => {
      state.isAppLoading = false;
      state.user = action.payload.user;
      state.parentEntity = action.payload.parentEntity;
    });
    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(updateParentEntity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateParentEntity.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateParentEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.parentEntity.last_onboarding_url =
        action.payload.last_onboarding_url;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    return builder;
  },
});
export const {
  resetAuthenticationSlice,
  setUser,
  setParentEntity,
  setAppLoading,
  setLastOnboardingStep,
  setQrLinked,
  activateOrg,
  deactivateOrg,
} = authenticationSlice.actions;
export default authenticationSlice;
