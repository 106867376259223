import React from 'react';
import { useSelector } from 'react-redux';
import { QuestionAnalytics } from 'components';
import './_widgets.scss';
import { Card } from 'react-bootstrap';

export default function Widgets({ filters }) {
  const enabledWidgets = useSelector((state) => state.dashboard.enabledWidgets);
  return enabledWidgets.length ? (
    <section className="chartsWrapper">
      {enabledWidgets.map((widget) => (
        <Card className="chartCard" key={widget.id}>
          <QuestionAnalytics
            key={widget.id}
            questionUUID={widget.uuid}
            filters={filters}
          />
        </Card>
      ))}
    </section>
  ) : (
    <div className="emptyWidgets">
      Click Manage Widgets to enable your widgets
    </div>
  );
}
