import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/hooks';
import { URLS, RESET_PASSWORD } from 'utils/constants';
import { OnboardingLayout } from 'layouts';

export default function ResetPasswordSuccess() {
  const navigate = useNavigate();

  const onProceed = () => {
    navigate(URLS.LOGIN_URL);
  };

  usePageTitle(RESET_PASSWORD);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Password Recovered Successfully!">
          Your password has been recovered successfully.
          <br />
          To continue to your account, click Proceed.
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          onClickContinue={onProceed}
          continueText="Proceed"
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
