/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast } from 'utils/helpers';
import {
  entityListAPICallV2,
  experienceListAPICall,
  locationListAPICall,
} from 'apis/cxmeter';

export const getLocations = createAsyncThunk(
  'dashboard/getLocations',
  async () => locationListAPICall().catch(showErrorToast),
);
export const getBranches = createAsyncThunk(
  'dashboard/getBranches',
  async (params) => entityListAPICallV2(params).catch(showErrorToast),
);
export const getExperience = createAsyncThunk(
  'dashboard/getExperience',
  async () => experienceListAPICall().catch(showErrorToast),
);
