import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentReview } from 'redux/reviewsListing/reviewsListingActions';
import { ANONYMOUS, HAPPY } from 'utils/constants';
import { getSegmentIconSrc, getIconSrc } from 'utils/helpers/iconHelper';
import ReviewMetaSection from './reviewMetaSection';
import ReviewQuestionSection from './ReviewQuestionSection';
import ReviewResolutionSection from './ReviewResolutionSection';
import { clearCurrentReview } from 'redux/reviewsListing/reviewsListing.slice';
import { Spinner } from 'react-bootstrap';
import { ATTACHMENT } from 'utils/constants/questionTypes';
import ReviewComments from './ReviewComments';
import { useSearchParams } from 'react-router-dom';
import 'components/dashboard/configurationPanel/_configurationPanel.scss';
import './_feedback.scss';

function ReviewPanel({
  isOpen,
  setIsOpen,
  reviewId,
  reviewIndex,
  totalReviews,
  handleView,
  showNav = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useSelector(
    (state) => state.reviews.currentReview,
  );
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const handleCloseConfig = () => {
    setIsOpen(false);
    if (searchParams.get('openFeedback')) {
      searchParams.delete('openFeedback');
      setSearchParams(searchParams);
    }
  };

  const handleNextReview = () => {
    if (reviewIndex < totalReviews - 1) handleView(reviewIndex + 1);
  };

  const handlePrevReview = () => {
    if (reviewIndex) handleView(reviewIndex - 1);
  };

  const countAttachments = (questions) => {
    let totalCount = 0;
    const attachments = questions?.filter(
      (question) => question.question_type === ATTACHMENT,
    );
    if (attachments?.length) {
      attachments.forEach((attachment) => {
        const { answer } = attachment;
        totalCount += answer.images.length;
        totalCount += answer.videos.length;
        if (answer.audio) {
          totalCount += 1;
        }
      });
    }
    return totalCount;
  };

  useEffect(() => {
    if (reviewId) dispatch(getCurrentReview(reviewId));
    return () => dispatch(clearCurrentReview());
  }, [reviewId]);

  return (
    <article className={`feedback-panel ${isOpen ? 'feedback-open' : ''}`}>
      <div className="feedback-backdrop" onClick={() => setIsOpen(false)} />
      <div className="feedback-content">
        {isLoading ? (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        ) : (
          <>
            <header className="feedback-header">
              <div className="feedback-header-content">
                <img
                  alt="driver"
                  src={getSegmentIconSrc(data?.segment?.name)}
                />
                <hgroup className="feedback-title">
                  <h2>{data?.customer?.name || ANONYMOUS}</h2>
                  <time>
                    {(data?.created_at
                      ? dayjs(data?.created_at)
                      : dayjs()
                    ).format('ddd, MMM DD, YYYY  hh:mm A')}
                  </time>
                </hgroup>
              </div>
              <div className="feedback-header-controls">
                {data?.customer?.email && (
                  <a href={`mailto:${data.customer.email}`}>
                    <button
                      className="button button-primary feedback-header-controls-email-button"
                      type="button"
                    >
                      <img
                        alt="Email"
                        src="/assets/icons/icon_emailFeedback.svg"
                      />
                      Email
                    </button>
                  </a>
                )}
                {showNav && (
                  <>
                    <button
                      className="button button-arrow p-1"
                      type="button"
                      onClick={handlePrevReview}
                      disabled={reviewIndex === 0}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                      className="button button-arrow p-1"
                      type="button"
                      onClick={handleNextReview}
                      disabled={reviewIndex === totalReviews - 1}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </>
                )}
                <button
                  className="button p-1"
                  type="button"
                  onClick={handleCloseConfig}
                >
                  <img
                    alt="Close Feedback Button"
                    src="/assets/icons/icon_closeFeedback.svg"
                  />
                </button>
              </div>
            </header>
            <section className="feedback-meta-group">
              <ReviewMetaSection
                icon="/assets/icons/icon-devices.svg"
                heading="Device"
                value={data?.device}
              />
              <ReviewMetaSection
                icon="/assets/icons/icon_location2.svg"
                heading="Location"
                value={data?.entity?.name}
              />
              <ReviewMetaSection
                icon={
                  data?.experience?.name && getIconSrc(data?.experience?.name)
                }
                heading="Experience"
                value={data?.experience?.name}
              />
              {data?.customer?.email && (
                <ReviewMetaSection
                  icon="/assets/icons/icon_emailFeedbackMeta.svg"
                  heading="Email"
                  value={data?.customer?.email}
                />
              )}
            </section>
            <hr className="feedback-separator" />
            <section className="feedback-tabs">
              <button
                onClick={() => {
                  setActiveTab(0);
                }}
                className={activeTab === 0 && 'feedback-tabs-active'}
              >
                User Feedback
              </button>
              <button
                onClick={() => {
                  setActiveTab(1);
                }}
                className={activeTab === 1 && 'feedback-tabs-active'}
              >
                Attachments ({countAttachments(data?.questions)})
              </button>
              <button
                onClick={() => {
                  setActiveTab(3);
                }}
                className={activeTab === 3 && 'feedback-tabs-active'}
              >
                Team Comments
              </button>
            </section>
            {activeTab === 0 && (
              <section className="feedback-questions">
                {data?.questions
                  ?.filter((question) => question.question_type !== ATTACHMENT)
                  .map((question, idx) => (
                    <ReviewQuestionSection key={idx} question={question} />
                  ))}
              </section>
            )}
            {activeTab === 1 &&
              (countAttachments(data?.questions) ? (
                <section className="feedback-attachments">
                  {data?.questions
                    ?.filter(
                      (question) => question.question_type === ATTACHMENT,
                    )
                    .map((question, idx) => (
                      <ReviewQuestionSection key={idx} question={question} />
                    ))}
                </section>
              ) : (
                <p>There are no attachments</p>
              ))}
            {activeTab === 3 && (
              <section className="feedback-questions">
                <ReviewComments
                  comments={data?.feedback_comments}
                  feedbackId={data?.id}
                />
              </section>
            )}
            {data?.segment?.name !== HAPPY && (
              <ReviewResolutionSection review={data} />
            )}
          </>
        )}
      </div>
    </article>
  );
}

export default ReviewPanel;
