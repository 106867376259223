/* eslint-disable no-underscore-dangle */
import React, { Fragment, useEffect, useState } from 'react';
import { SurveyThumbnail } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteEntitySurvey,
  duplicateSurvey,
} from 'redux/surveys/surveysActions';
import { useNavigate } from 'react-router-dom';
import { FEEDBACK_FORMS_SETTINGS_URL } from 'utils/constants/urlConstants';

import './_surveyThumbnail.scss';

function SurveyThumbnails({
  surveyTemplates,
  entitySurveys,
  exp = null,
  value = null,
  setSelectedSurvey = null,
  onChange = null,
  handleAddNewForm = null,
  onEdit = null,
  isOnboarding = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedSurvey, deleteSurveysLoading } = useSelector(
    (s) => s.surveys,
  );

  const handleCreateNewSurvey = (expe) => {
    navigate(`${FEEDBACK_FORMS_SETTINGS_URL}/new?expId=${expe}`);
  };

  const openSurveyHandler = (survey) => {
    navigate(`${FEEDBACK_FORMS_SETTINGS_URL}/${survey?._id.$oid}`);
  };

  const handleDeleteSurvey = async (id) => {
    dispatch(deleteEntitySurvey(id));
  };

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);

  const handleDuplicateSurvey = async (survey) => {
    setIsCopyLoading(true);
    setIsDuplicate(true);
    await dispatch(duplicateSurvey(survey._id.$oid));
    setIsCopyLoading(false);
  };

  useEffect(() => {
    if (selectedSurvey != null && isDuplicate) {
      if (isOnboarding) {
        onEdit(selectedSurvey._id.$oid, exp, false);
      } else {
        openSurveyHandler(selectedSurvey);
      }
      setIsDuplicate(false);
    }
  }, [selectedSurvey]);

  const [sortedEntitySurveys, setSortedEntitySurveys] = useState([]);
  const [sortedTemplateSurveys, setSortedTemplateSurveys] = useState([]);

  const sortSurveys = (surveys) => {
    const allSurveys = Object.keys(surveys)
      .map((item) => {
        if (surveys[item]?.surveys) {
          return surveys[item]?.surveys.map((survey) => {
            return {
              ...survey,
              icon: surveys[item]?.icon,
            };
          });
        }
        return [];
      })
      .flat()
      .sort((a, b) => a.title.localeCompare(b.title));
    return allSurveys;
  };

  useEffect(() => {
    if (entitySurveys) {
      setSortedEntitySurveys(sortSurveys(entitySurveys));
    }
  }, [entitySurveys]);

  useEffect(() => {
    if (surveyTemplates) {
      setSortedTemplateSurveys(sortSurveys(surveyTemplates));
    }
  }, [surveyTemplates]);

  return (
    <div className="survey-cards-container">
      <h3>{t('settings.feedbackForms.customForms')}</h3>
      <div className="survey-cards">
        {sortedEntitySurveys.map((survey, index) => (
          <Fragment key={index}>
            <SurveyThumbnail
              key={index}
              icon={survey?.icon}
              onDelete={() => handleDeleteSurvey(survey._id.$oid)}
              onEdit={() => {
                if (onEdit) {
                  onEdit(survey._id.$oid, exp, false);
                } else {
                  openSurveyHandler(survey);
                }
              }}
              onCopy={() => handleDuplicateSurvey(survey)}
              onChange={(e) => {
                if (setSelectedSurvey) {
                  setSelectedSurvey(e);
                }
                if (onChange) {
                  onChange({ ...value, survey_id: e._id.$oid }, survey);
                }
              }}
              selected={value?.survey_id === survey._id.$oid}
              data={survey}
              isDeleteLoading={deleteSurveysLoading[survey._id.$oid]}
              isCopyLoading={isCopyLoading}
            />
          </Fragment>
        ))}
        <div
          className="addItem"
          onClick={() => {
            if (handleAddNewForm) {
              return handleAddNewForm(exp);
            }
            return handleCreateNewSurvey(exp);
          }}
          tabIndex={0}
          role="button"
        >
          <div className="addItem-icon">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <p>{t('settings.feedbackForms.addNew')}</p>
        </div>
      </div>
      {sortedTemplateSurveys.length ? (
        <h3>{t('settings.feedbackForms.surveyTemplates')}</h3>
      ) : null}
      <div className="survey-cards">
        {sortedTemplateSurveys.map((survey, index) => (
          <Fragment key={index}>
            <SurveyThumbnail
              key={index}
              icon={survey?.icon}
              onEdit={() => {
                if (onEdit) {
                  onEdit(survey._id.$oid, exp, true);
                } else {
                  openSurveyHandler(survey);
                }
              }}
              onChange={(e) => {
                if (setSelectedSurvey) {
                  setSelectedSurvey(e);
                }
                if (onChange) {
                  onChange({ ...value, survey_id: e._id.$oid }, survey);
                }
              }}
              data={survey}
              selected={value?.survey_id === survey._id.$oid}
              template
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default SurveyThumbnails;
