import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { OVERRIDE_STRINGS } from 'utils/constants';
import './_branchExpFilter.scss';
/**
 * @author
 * @function BranchList
 * */

function MultiBranchListFilter({
  branches = [],
  value,
  onChange,
  disabled,
  maxComparison = 2,
}) {
  return (
    <div>
      <MultiSelect
        hasSelectAll={false}
        id="branch"
        className="br-multi-select"
        overrideStrings={OVERRIDE_STRINGS}
        options={branches.map((branch) => ({
          label: branch.name,
          value: branch.id,
          disabled,
        }))}
        value={value}
        onChange={onChange}
        labelledBy="Location"
        closeOnChangedValue={value.length === maxComparison}
      />
    </div>
  );
}

export default MultiBranchListFilter;
