import React, { forwardRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

import './_sortableItem.scss';

function SortableItem({
  id,
  children,
  index = null,
  setSelectedSection = null,
  isSurveySection = false,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  if (isDragging && isSurveySection) {
    setSelectedSection(index);
  }

  const style = {
    transform: CSS.Transform.toString(
      isDragging
        ? {
            ...transform,
            scaleX: 1,
            scaleY: 1,
          }
        : transform,
    ),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="sortableItem">
      <FontAwesomeIcon
        icon={faGripVertical}
        {...listeners}
        {...attributes}
        className="dragIcon"
      />
      <div>{children}</div>
    </div>
  );
}

export const Item = forwardRef(({ id, ...props }, ref) => (
  <div {...props} ref={ref}>
    {id}
  </div>
));

export default SortableItem;
