/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  notificationsAPICall,
  updateNotificationsAPICall,
  archiveNotificationsAPICall,
} from 'apis/notifications';
import { showErrorToast } from 'utils/helpers';

export const getNotificationsAction = createAsyncThunk(
  'notification/getNotificationAction',
  async (params) => notificationsAPICall(params).catch(showErrorToast),
);

export const updateNotificationsAction = createAsyncThunk(
  'notification/updateNotificationAction',
  async (data) => updateNotificationsAPICall(data).catch(showErrorToast),
);

export const archiveNotificationsAction = createAsyncThunk(
  'notification/archiveNotificationAction',
  async (data) => archiveNotificationsAPICall(data).catch(showErrorToast),
);
