import React, { useState } from 'react';
import BillingInfoModal from 'components/billingInfoModal/BillingInfoModal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function BillingInformation() {
  const { t } = useTranslation();
  const [isOpenBillingInfoModal, setIsOpenBillingInfoModal] = useState(false);
  const { parentEntity, isActiveOrg } = useSelector((s) => s.authentication);
  return (
    <>
      <div className="billing-container">
        <div className="billing-header">
          <div>
            <h5 className="mt-3">{t('settings.payments.billing.title')}</h5>
            {isActiveOrg ? (
              <button
                type="button"
                onClick={() => setIsOpenBillingInfoModal(true)}
                className="btn p-0 text-primary"
              >
                Edit
              </button>
            ) : null}
          </div>
          <p className="cxmeter-subtitle">
            {t('settings.payments.billing.subTitle')}
          </p>
        </div>
        <div className="billing-details">
          <div>
            <h6>Name: </h6>
            <p>{parentEntity.name}</p>
          </div>
          <div>
            <h6>Email: </h6>
            <p>{parentEntity.email ?? '-'}</p>
          </div>
        </div>
      </div>
      <BillingInfoModal
        isOpenModal={isOpenBillingInfoModal}
        onClose={() => setIsOpenBillingInfoModal(false)}
      />
    </>
  );
}
