import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, Outlet } from 'react-router-dom';
import { SETTINGS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import './_setting.scss';
import SettingTabs from 'components/settingTabs/SettingTabs';
import {
  BRANCH_SETUP_SETTINGS_URL,
  FEEDBACK_FORMS_DETAIL_SETTINGS_URL,
  FEEDBACK_FORMS_SETTINGS_URL,
  PAYMENT_DASHBOARD_SETTINGS_URL,
  PAYMENT_PLANS_SETTINGS_URL,
  INVOICES_SETTINGS_URL,
  UPDATE_ORG_SETTINGS_URL,
  USERS_URL,
  SURVEYS_URL,
  MANAGE_EMAILS_SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { useTranslation } from 'react-i18next';

function Settings() {
  const location = useLocation();
  const { t } = useTranslation();

  usePageTitle(SETTINGS);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <SettingTabs>
      <SettingTabs.Menu>
        <SettingTabs.Item
          title={t('settings.feedbackForms.title')}
          to={FEEDBACK_FORMS_SETTINGS_URL}
          actvePaths={[FEEDBACK_FORMS_DETAIL_SETTINGS_URL]}
        />
        <SettingTabs.Item
          title={t('settings.qrSetup.menuTitle')}
          to={SURVEYS_URL}
        />
        <SettingTabs.Item
          title={t('settings.locations.menuTitle')}
          to={BRANCH_SETUP_SETTINGS_URL}
        />
        <SettingTabs.Item title={t('settings.users.title')} to={USERS_URL} />
        <SettingTabs.Item
          title={t('settings.manageEmails.title')}
          to={MANAGE_EMAILS_SETTINGS_URL}
          actvePaths={[MANAGE_EMAILS_SETTINGS_URL]}
        />
        <SettingTabs.Item
          title={t('settings.organization.title')}
          to={UPDATE_ORG_SETTINGS_URL}
        />
        <SettingTabs.Item
          title={t('settings.payments.menuTitle')}
          to={PAYMENT_DASHBOARD_SETTINGS_URL}
          actvePaths={[PAYMENT_PLANS_SETTINGS_URL]}
        />
        <SettingTabs.Item
          title={t('settings.invoices.title')}
          to={INVOICES_SETTINGS_URL}
        />
      </SettingTabs.Menu>
      <SettingTabs.Content>
        <Outlet />
      </SettingTabs.Content>
    </SettingTabs>
  );
}

export default Settings;
