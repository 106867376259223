import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configHelper } from 'utils/helpers';
import {
  getConfigWidgets,
  postConfigWidgets,
} from 'redux/dashboard/dashboardActions';
import {
  setConfigWidgets,
  setEnabledWidgets,
} from 'redux/dashboard/dashboardSlice';
import WidgetsConfigModal from 'components/modals/WidgetsConfigModal';
import './_configurationPanel.scss';

function ConfigurationPanel({ isOpen, setIsOpen }) {
  const [isDirty, setIsDirty] = useState(false);
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.configWidgets,
  );
  const { getSurveyIdFromQuestionId } = configHelper;

  const dispatch = useDispatch();

  const handleCloseConfig = () => {
    setIsOpen(false);
  };

  const handleSetEnabledWidgets = (configData) => {
    const enabledWidgets = [];
    configData?.forEach((survey) => {
      survey.questions.forEach((question) => {
        if (question.is_enabled) {
          enabledWidgets.push({
            id: question.id,
            uuid: question.uuid,
          });
        }
      });
    });
    dispatch(setEnabledWidgets(enabledWidgets));
  };

  const toggleQuestionSelection = (checked, questionId) => {
    const surveyId = getSurveyIdFromQuestionId(data, questionId);
    const tempSurveys = [...data];
    const surveyIndex = tempSurveys.findIndex(
      (survey) => survey.survey_id === surveyId,
    );
    const tempSurvey = { ...tempSurveys[surveyIndex] };
    const tempQuestions = [...tempSurveys[surveyIndex].questions];
    const questionIndex = tempSurvey.questions.findIndex(
      (question) => question.id === questionId,
    );
    const tempQuestion = { ...tempSurvey.questions[questionIndex] };
    tempQuestion.is_enabled = checked;
    tempQuestions[questionIndex] = tempQuestion;
    tempSurvey.questions = tempQuestions;
    tempSurveys[surveyIndex] = tempSurvey;
    handleSetEnabledWidgets(tempSurveys);
    dispatch(setConfigWidgets(tempSurveys));
    setIsDirty(true);
  };

  const handleSaveConfigurations = () => {
    const widgets = data.reduce((acc, val) => {
      acc.push(
        val.questions.map((v) => ({
          question: v.id,
          is_enabled: v.is_enabled,
        })),
      );
      return acc;
    }, []);
    dispatch(postConfigWidgets({ widgets: widgets.flat() }));
    setIsOpen(false);
    setIsDirty(false);
  };

  const handleCancelConfigurations = () => {
    dispatch(getConfigWidgets());
    handleSetEnabledWidgets(data);
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(getConfigWidgets());
  }, []);

  useEffect(() => {
    handleSetEnabledWidgets(data);
  }, [data]);

  return (
    <div>
      <div className="headerDropdownsWrapper spb">
        <span>Survey Drivers</span>
      </div>
      <WidgetsConfigModal
        data={data}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        isOpen={isOpen}
        isLoading={isLoading}
        toggleQuestionSelection={toggleQuestionSelection}
        onClose={handleCloseConfig}
        onCancel={handleCancelConfigurations}
        onSave={handleSaveConfigurations}
      />
    </div>
  );
}

export default ConfigurationPanel;
