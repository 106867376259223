import React, { useEffect, useState } from 'react';
import BillingContactModal from 'components/billingContactModal/BillingContactModal';
import PaymentCardModal from 'components/paymentCardModal/PaymentCardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
} from 'redux/payments/paymentsActions';
import {
  BACKUP_PAYMENT_CARD_ACTIONS,
  PRIMARY_PAYMENT_CARD_ACTIONS,
} from 'utils/constants';
import PaymentMethod from './PaymentMethod';

export default function PaymentMethods() {
  const dispatch = useDispatch();
  const {
    paymentMethods: { primaryPaymentMethods, backupPaymentMethods },
  } = useSelector((s) => s.payments);

  const [editCardDetail, setEditCardDetail] = useState(null);
  const [isOpenNewCardModal, setIsOpenNewCardModal] = useState(false);
  const [isOpenBillingContactModal, setIsOpenBillingContactModal] =
    useState(false);

  const primaryCardActionClickHandler = () => {
    setEditCardDetail(primaryPaymentMethods[0]);
    setIsOpenNewCardModal(true);
  };

  const backupCardActionClickHandler = (e) => {
    const { key } = e;
    if (key === 'make_primary') {
      dispatch(setDefaultPaymentMethod(backupPaymentMethods[0]?.id));
    } else if (key === 'remove') {
      dispatch(deletePaymentMethod(backupPaymentMethods[0]?.id));
    } else if (key === 'edit') {
      setEditCardDetail(backupPaymentMethods[0]);
      setIsOpenNewCardModal(true);
    }
  };

  const openAddCardModal = () => {
    setIsOpenNewCardModal(true);
    setEditCardDetail(null);
  };

  const onCloseAddPaymentModal = () => {
    setIsOpenNewCardModal(false);
    setEditCardDetail(null);
  };

  const onCloseBillingContactModal = () => {
    setIsOpenBillingContactModal(false);
  };

  const editBillingContactHandler = (paymentMethodId) => {
    setIsOpenBillingContactModal(true);
    setEditCardDetail(paymentMethodId);
  };

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  return (
    <>
      <div className="payment-methods-container">
        <div className="primary-payment-method-container">
          <PaymentMethod
            actionHandler={primaryCardActionClickHandler}
            actions={PRIMARY_PAYMENT_CARD_ACTIONS}
            cards={primaryPaymentMethods}
            methodName="Primary"
            onAddNewCard={openAddCardModal}
            onEditBillingContact={editBillingContactHandler}
          />
        </div>
        <div className="backup-payment-method-container">
          <PaymentMethod
            actionHandler={backupCardActionClickHandler}
            actions={BACKUP_PAYMENT_CARD_ACTIONS}
            cards={backupPaymentMethods}
            methodName="Backup"
            onAddNewCard={openAddCardModal}
            onEditBillingContact={editBillingContactHandler}
          >
            <p>
              The backup payment method will be used for any recurring charges
              if we are unable to successfully charge the primary payment method
            </p>
          </PaymentMethod>
        </div>
      </div>
      <BillingContactModal
        isOpenModal={isOpenBillingContactModal}
        onClose={onCloseBillingContactModal}
        editPaymentMethod={editCardDetail}
      />
      <PaymentCardModal
        isOpenModal={isOpenNewCardModal}
        onClose={onCloseAddPaymentModal}
        editPaymentMethod={editCardDetail}
      />
    </>
  );
}
