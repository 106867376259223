import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LOCALIZATION_URL } from 'apis/urls';
import { ENV } from 'config';
import { APP_ENV } from 'utils/constants';

const options = {
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

// Default path for local translation file: public/locales/
if (ENV !== APP_ENV.local) {
  options.backend = {
    loadPath: `${LOCALIZATION_URL}/{{lng}}?from_web=true`,
  };
}

i18n.use(LanguageDetector).use(HttpApi).use(initReactI18next).init(options);

export default i18n;
