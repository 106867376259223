/* eslint-disable react/no-array-index-key */
import React from 'react';
import './_orderSummary.scss';

function OrderSummary({ plan, costs }) {
  return (
    <div className="planWrapper">
      <div className="orderSummary">
        <h3>Your Subscription Summary</h3>
        <table>
          <thead className="costRow">
            <th>Item(s)</th>
            {costs.items && costs.items[0]?.key !== 'overall_cost' && (
              <th className="costD">Qty.</th>
            )}
            <th className="costD">Cost</th>
          </thead>
          <tbody>
            {costs.items &&
              (costs.items[0]?.key === 'overall_cost' ? (
                <tr className="costRow">
                  <td>{plan.title} Plan</td>
                  <td className="costD">
                    <p />
                    <p>${plan.overall_cost}</p>
                  </td>
                </tr>
              ) : (
                costs.items.map((cost) => (
                  <tr key={cost.key} className="costRow">
                    <td>{cost.key}</td>
                    <td className="costD">{cost.qty}</td>
                    <td className="costD">
                      <p>
                        {cost.qty} X ${cost.cost}
                      </p>
                      <p>${cost.qty * cost.cost || 0}</p>
                    </td>
                  </tr>
                ))
              ))}
          </tbody>
        </table>
        <div className="costTotalWrapper">
          <div className="costTotalRow">
            <p>Total</p>
            <p>
              $
              {costs.items.reduce(
                (total, { qty, cost }) => total + qty * (cost ?? 0),
                0,
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
