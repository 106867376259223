import BlockedOrgModal from 'components/blockedOrgModal/BlockedOrgModal';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { URLS } from 'utils/constants';

export default function RoutesWrapper() {
  const location = useLocation();
  const { user, isActiveOrg } = useSelector((state) => state.authentication);
  const showOnlyPaymentPage =
    !isActiveOrg &&
    !isEmpty(user) &&
    location.pathname !== URLS.PAYMENT_DASHBOARD_SETTINGS_URL;

  return showOnlyPaymentPage ? <BlockedOrgModal /> : <Outlet />;
}
