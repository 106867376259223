import { Icon } from 'components/ui';
import { DropdownButton } from 'react-bootstrap';
import './_CustomDropdownButton.scss';

export default function CustomDropdownButton({
  children,
  id,
  name,
  isRemoveable = false,
  value,
  onChange,
  title,
  onRemove,
  isClearable = false,
}) {
  const showCrossIcon = (isClearable && value) || isRemoveable;

  const onClearOrRemove = () => {
    if (isClearable && value) {
      onChange(undefined);
    } else if (showCrossIcon) {
      onRemove?.();
    }
  };

  const buttonTitle = (
    <span className="dropdown-title">
      <span className="title text-ellipsis">{title}</span>
      <span className="chevron-icon">
        <Icon
          className={showCrossIcon ? 'remove-dropdown' : ''}
          onClick={onClearOrRemove}
          type={showCrossIcon ? 'close' : 'arrow_drop_down'}
        />
      </span>
    </span>
  );

  return (
    <DropdownButton
      id={id}
      name={name}
      className="custom-dropdown-button"
      value={value?.id}
      onChange={onChange}
      title={buttonTitle}
    >
      {children}
    </DropdownButton>
  );
}
