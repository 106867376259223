/* eslint-disable implicit-arrow-linebreak */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  duplicateSurveyAPICall,
  deleteSurveyAPICall,
  getEntitySurveysAPICall,
  getSurveyAPICall,
  surveyCreateAPICall,
  surveyTemplateCreateAPICall,
  surveyTemplatesListAPICall,
  surveyUpdateAPICall,
} from 'apis/survey';
import { showErrorToast, surveyHelper } from 'utils/helpers';
import { setSelectedSurvey } from './surveysSlice';

const { convertToRawSurvey } = surveyHelper;

export const getEntitySurveyById = createAsyncThunk(
  'surveys/getEntitySurveyById',
  async (id, { dispatch }) =>
    getSurveyAPICall(id)
      .then((res) => {
        dispatch(setSelectedSurvey(convertToRawSurvey(res)));
      })
      .catch(showErrorToast),
);

export const getEntitySurveys = createAsyncThunk(
  'surveys/getEntitySurveys',
  async () => getEntitySurveysAPICall().catch(showErrorToast),
);

export const getSurveysTemplates = createAsyncThunk(
  'surveys/getSurveysTemplates',
  async () => surveyTemplatesListAPICall().catch(showErrorToast),
);

export const createSurveyTemplate = createAsyncThunk(
  'surveys/createSurveyTemplate',
  async (survey) => surveyTemplateCreateAPICall(survey).catch(showErrorToast),
);

export const createEntitySurvey = createAsyncThunk(
  'surveys/createEntitySurvey',
  async (survey) => surveyCreateAPICall(survey).catch(showErrorToast),
);

export const updateEntitySurvey = createAsyncThunk(
  'surveys/updateEntitySurvey',
  async ({ surveyId, survey }) =>
    surveyUpdateAPICall(surveyId, survey).catch(showErrorToast),
);

export const deleteEntitySurvey = createAsyncThunk(
  'surveys/deleteEntitySurvey',
  async (surveyId, { dispatch }) =>
    deleteSurveyAPICall(surveyId)
      .then((res) => {
        toast.success(res);
        dispatch(setSelectedSurvey(null));
        dispatch(getEntitySurveys());
      })
      .catch(showErrorToast),
);

export const duplicateSurvey = createAsyncThunk(
  'surveys/duplicateSurvey',
  async (surveyId, { dispatch }) =>
    duplicateSurveyAPICall(surveyId, null, true)
      .then((res) => {
        dispatch(setSelectedSurvey(convertToRawSurvey(res)));
      })
      .catch(showErrorToast),
);
