/* eslint-disable no-return-await */
import { deleteAPICall, getAPICall, patchAPICall, postAPICall } from './utils';
import {
  DOWNLOAD_CSV_TEMPLATE_API,
  INVITES_BY_ID_API,
  INVITES_API,
  INVITE_GROUP_BY_ID_API,
  INVITE_GROUPS_API,
  INVITE_TEMPLATE_API,
  INVITE_TEMPLATE_BY_ID_API,
  INVITE_SCHEDULE_API,
  INVITE_SCHEDULE_BY_ID_API,
  DELETE_INVITES_API,
  INVITE_REMINDER_API,
} from './urls';

export async function invitesAPICall(params) {
  return await getAPICall(INVITES_API, params);
}

export async function invitesByIdAPICall(id, params) {
  return await getAPICall(INVITES_BY_ID_API(id), params);
}

export async function inviteUpdateByIdAPICall(id, data) {
  return await patchAPICall(INVITES_BY_ID_API(id), data);
}

export async function inviteReminderByIdAPICall(data, params) {
  return await postAPICall(INVITE_REMINDER_API, data, params);
}

export async function invitePendingDeleteByIdAPICall(data) {
  return await deleteAPICall(DELETE_INVITES_API, data);
}

export async function inviteGroupsAPICall(params) {
  return await getAPICall(INVITE_GROUPS_API, params);
}

export async function inviteGroupCreateAPICall(data) {
  return await postAPICall(INVITE_GROUPS_API, data);
}

export async function inviteGroupByIdAPICall(id) {
  return await getAPICall(INVITE_GROUP_BY_ID_API(id));
}

export async function inviteGroupUpdateByIdAPICall(id, data) {
  return await patchAPICall(INVITE_GROUP_BY_ID_API(id), data);
}

export async function inviteSchedulesAPICall(params) {
  return await getAPICall(INVITE_SCHEDULE_API, params);
}

export async function inviteScheduleByIdAPICall(id) {
  return await getAPICall(INVITE_SCHEDULE_BY_ID_API(id));
}

export async function inviteScheduleCreateAPICall(data) {
  return await postAPICall(INVITES_API, data);
}

export async function inviteScheduleDeleteByIdAPICall(id) {
  return await deleteAPICall(INVITE_SCHEDULE_BY_ID_API(id));
}

export async function inviteTemplatesAPICall(params) {
  return await getAPICall(INVITE_TEMPLATE_API, params);
}

export async function inviteTemplateCreateAPICall(data) {
  return await postAPICall(INVITE_TEMPLATE_API, data);
}

export async function inviteTemplateByIdAPICall(id) {
  return await getAPICall(INVITE_TEMPLATE_BY_ID_API(id));
}

export async function inviteTemplateUpdateByIdAPICall(id, data) {
  return await patchAPICall(INVITE_TEMPLATE_BY_ID_API(id), data);
}

export async function inviteTemplateDeleteByIdAPICall(id) {
  return await deleteAPICall(INVITE_TEMPLATE_BY_ID_API(id));
}

export async function downloadCSVTemplateAPICall() {
  return await getAPICall(DOWNLOAD_CSV_TEMPLATE_API, {}, true, 'text/csv');
}
