import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
} from 'components/filters/filterTypes';
import { isNil } from 'lodash';
import {
  getComparisonByBranch,
  getComparisonByDateRange,
  getComparisonByExperience,
} from 'redux/branchComparison/branchComparisonActions';
import { CHECKBOX, RADIO } from 'utils/constants/questionTypes';

export const getComparisonParams = (
  comparisonFilters,
  otherFilters,
  comparisonMethod,
) => {
  const params = {};
  let comparisonAction;
  // To populate current selected filter method.
  comparisonFilters.forEach((filter, index) => {
    switch (comparisonMethod.id) {
      case DATE_RANGE_FILTER:
        params[`date_range[${index * 2}]`] = filter.startDate;
        params[`date_range[${index * 2 + 1}]`] = filter.endDate;
        comparisonAction = getComparisonByDateRange;
        break;
      case EXPERIENCE_FILTER:
        params[`experience_ids[${index}]`] = filter.id;
        comparisonAction = getComparisonByExperience;
        break;
      case BRANCH_FILTER:
        params[`entity_ids[${index}]`] = filter.id;
        comparisonAction = getComparisonByBranch;
        break;
      default:
        break;
    }
  });

  // To populate filters other than current selected filter method.
  Object.keys(otherFilters).forEach((key) => {
    const filter = otherFilters[key];
    switch (key) {
      case DATE_RANGE_FILTER:
        params['date_range[0]'] = filter?.startDate;
        params['date_range[1]'] = filter?.endDate;
        break;
      case EXPERIENCE_FILTER:
        params.experience_id = filter?.id;
        break;
      case BRANCH_FILTER:
        params.entity_id = filter?.id;
        break;
      default:
        break;
    }
  });

  return { params, comparisonAction };
};

const stripHtmlTags = (str) =>
  str.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '');

const getFixedOrNullValue = (value) =>
  isNil(value) ? null : +value.toFixed(1);

const getOptionValue = (option, type) => {
  const { count, percentage, average } = option ?? {};
  const value = type === RADIO || type === CHECKBOX ? count : average;
  return {
    percentage: getFixedOrNullValue(percentage),
    value: getFixedOrNullValue(value),
  };
};

const parseQuickStatsSegments = (
  result,
  segments,
  comparisonIndex,
  comparisonCount,
  t,
) => {
  segments.forEach((segment) => {
    const segmentId = segment.id;
    const value = isNil(segment.count) ? null : +segment.count.toFixed(1);
    const percentage = isNil(segment.percentage)
      ? null
      : +segment.percentage.toFixed(1);
    if (!result[segmentId]) {
      const valuesArray = new Array(comparisonCount).fill(null);
      const percentagesArray = new Array(comparisonCount).fill(null);

      valuesArray[comparisonIndex] = value;
      percentagesArray[comparisonIndex] = percentage;

      result[segmentId] = {
        key: t(`dashboard.quickStats.${segment.name}`),
        values: valuesArray,
        percentages: percentagesArray,
      };
    } else {
      result[segmentId].values[comparisonIndex] = value;
      result[segmentId].percentages[comparisonIndex] = percentage;
    }
  });
};

const parseLocationComparisoExperience = (experiences) => {
  const result = [];
  experiences.forEach((experience) => {
    experience.surveys.forEach((survey) => {
      const surveyData = {
        title: stripHtmlTags(`${experience.name} • ${survey.survey_title}`),
        data: survey.questions.map((question) => {
          return {
            title: stripHtmlTags(question.label),
            data: question.options.map((option) => {
              const values = [];
              const percentages = [];

              option.comparison.forEach((comp, index) => {
                const compData = Object.values(comp)[0];
                const { value, percentage } = getOptionValue(
                  compData,
                  question.question_type,
                );
                values[index] = value;
                percentages[index] = percentage;
              });
              return {
                key: stripHtmlTags(option.label),
                values,
                percentages,
                type: question.question_type,
              };
            }),
          };
        }),
      };

      result.push(surveyData);
    });
  });

  return result;
};

export const parseQuickStats = (quickStatsComparison, t) => {
  const result = {};
  const comparisonCount = quickStatsComparison.length;
  quickStatsComparison.forEach(({ stats: entityQuickStat }, index) => {
    parseQuickStatsSegments(
      result,
      entityQuickStat.segments,
      index,
      comparisonCount,
      t,
    );
  });
  return Object.values(result);
};

const parsePerformers = (comparedEntities) => {
  const result = {};
  const entityCount = comparedEntities.length;

  comparedEntities.forEach((entity, entityIndex) => {
    entity.performers.forEach((performer) => {
      const key = performer.option_id;
      const value = getFixedOrNullValue(performer.avg);
      const percentage = getFixedOrNullValue(performer.percentage);

      if (!result[key]) {
        const valuesArray = new Array(entityCount).fill(null);
        const percentagesArray = new Array(entityCount).fill(null);

        valuesArray[entityIndex] = value;
        percentagesArray[entityIndex] = percentage;

        result[key] = {
          key: stripHtmlTags(performer.label),
          optionId: performer.option_id,
          values: valuesArray,
          percentages: percentagesArray,
        };
      } else {
        result[key].values[entityIndex] = value;
        result[key].percentages[entityIndex] = percentage;
      }
    });
  });

  return Object.values(result);
};

const parseLocationComparison = (comparison, t) => {
  const {
    quick_stats: quickStatsData,
    top_performers: topPerformersData,
    worst_performers: worstPerformersData,
  } = comparison.basic_comparison[0] ?? {};
  const experienceComparison = comparison.experience_comparison;

  const data = [];
  const parsedQuickStats = parseQuickStats(quickStatsData, t);
  if (parsedQuickStats.length) {
    data.push({
      title: t('locationComparison.quickStats'),
      data: parsedQuickStats,
    });
  }
  const parsedTopPerformers = parsePerformers(topPerformersData);
  if (parsedTopPerformers.length) {
    data.push({
      title: t('locationComparison.topPerformers'),
      data: parsedTopPerformers,
    });
  }
  const parsedWorstPerformers = parsePerformers(worstPerformersData);
  if (parsedWorstPerformers.length) {
    data.push({
      title: t('locationComparison.worstPerformers'),
      data: parsedWorstPerformers,
    });
  }
  const parsedGeneralComparison = {
    title: t('locationComparison.generalComparison'),
    data,
  };

  return [
    parsedGeneralComparison,
    ...parseLocationComparisoExperience(experienceComparison),
  ];
};

const parseExperienceQuestionsComparison = (comparedExperiences, t) => {
  const questionComparison = {};
  const npsComparison = {};
  const experiencesCount = comparedExperiences.length;

  comparedExperiences.forEach((experience, experienceIndex) => {
    const npsStats = experience.nps_stats[0]?.segments ?? [];
    parseQuickStatsSegments(
      npsComparison,
      npsStats,
      experienceIndex,
      experiencesCount,
      t,
    );
    experience.surveys.forEach((survey) => {
      survey.questions.forEach((question) => {
        const questionTitle = stripHtmlTags(question.label).trim();
        const questionData = questionComparison[questionTitle];
        if (questionData) {
          question.options.forEach((option) => {
            const { value, percentage } = getOptionValue(
              option.comparison?.[0],
              question.question_type,
            );
            const key = stripHtmlTags(option.label);
            if (questionData[key]) {
              questionData[key].values[experienceIndex] = value;
              questionData[key].percentages[experienceIndex] = percentage;
            } else {
              const valuesArray = new Array(experiencesCount).fill(null);
              const percentagesArray = new Array(experiencesCount).fill(null);
              valuesArray[experienceIndex] = value;
              percentagesArray[experienceIndex] = percentage;
              questionData[key] = {
                key,
                values: valuesArray,
                percentages: percentagesArray,
              };
            }
          });
        } else {
          const data = {};
          question.options.forEach((option) => {
            const valuesArray = new Array(experiencesCount).fill(null);
            const percentagesArray = new Array(experiencesCount).fill(null);
            const { value, percentage } = getOptionValue(
              option.comparison?.[0],
              question.question_type,
            );
            const key = stripHtmlTags(option.label);
            valuesArray[experienceIndex] = value;
            percentagesArray[experienceIndex] = percentage;
            data[key] = {
              key,
              values: valuesArray,
              percentages: percentagesArray,
            };
          });
          questionComparison[questionTitle] = { ...data };
        }
      });
    });
  });

  const result = [];

  const parsedNpsComparison = Object.values(npsComparison);
  if (parsedNpsComparison.length) {
    result.push({
      title: t('locationComparison.generalComparison'),
      data: [
        {
          title: t('locationComparison.quickStats'),
          data: parsedNpsComparison,
        },
      ],
    });
  }

  const parsedQuestions = Object.keys(questionComparison).map((question) => ({
    title: question,
    data: Object.values(questionComparison[question]),
  }));
  if (parsedQuestions.length) {
    result.push({
      data: parsedQuestions,
    });
  }

  return result;
};

const parseExperienceComparison = (comparison, t) => {
  const experienceQuestionsComparison = parseExperienceQuestionsComparison(
    comparison,
    t,
  );
  return experienceQuestionsComparison;
};

const parseComparison = (comparison, comparisonMethod, t) => {
  if (comparisonMethod.id === BRANCH_FILTER) {
    const locationComparison = parseLocationComparison(comparison, t);
    return locationComparison ?? [];
  }
  if (comparisonMethod.id === EXPERIENCE_FILTER) {
    const experienceComparison = parseExperienceComparison(comparison, t);
    return experienceComparison ?? [];
  }
  return [];
};

export default parseComparison;
