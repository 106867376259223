/* eslint-disable no-return-await */
import { NOTIFICATIONS_API } from './urls';
import { getAPICall, postAPICall, patchAPICall } from './utils';

export async function notificationsAPICall(params) {
  return await getAPICall(NOTIFICATIONS_API, params);
}

export async function updateNotificationsAPICall(data) {
  return await postAPICall(NOTIFICATIONS_API, data);
}

export async function archiveNotificationsAPICall(data) {
  return await patchAPICall(`${NOTIFICATIONS_API}${data}`);
}
