/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import TriDotMenu from './components/TriDotMenu';
import { Switch } from 'antd';

export const QrListColumns = (
  onEdit,
  onDelete,
  onUpdateQr,
  onShareUrl,
  onPrint,
  onDuplicate,
  rowsLoading,
) => [
  {
    title: 'Location Name',
    dataIndex: 'entity',
    sorter: true,
    render: (text, rec) => rec?.entity_experience?.entity?.name,
  },
  {
    title: 'Customer Experience',
    dataIndex: 'experience',
    render: (text, rec) => rec?.entity_experience?.experience?.name,
    sorter: true,
  },
  {
    title: 'QR ID',
    dataIndex: 'serial',
    key: 'qrcode_uuid',
    sorter: true,
    render: (text, rec) => rec?.serial_number,
  },
  {
    title: 'Loopback',
    render: (text, rec) => {
      return rec?.is_recursive ? 'Yes' : 'No';
    },
  },
  {
    title: 'Activation Status',
    render: (text, rec, rowIndex) => {
      return (
        <Switch
          disabled={!!rowsLoading.is_active?.[rec.id]}
          loading={!!rowsLoading.is_active?.[rec.id]}
          checked={rec.is_active}
          onChange={() =>
            onUpdateQr(
              rec?.id,
              rec?.serial_number,
              { is_active: !rec.is_active },
              rowIndex,
              'is_active',
            )
          }
        />
      );
    },
  },
  {
    title: '',
    key: '',
    width: '3%',
    render: (text, record) => (
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div className="dotMenu" onClick={() => onPrint(record)}>
          <FontAwesomeIcon icon={faPrint} size="lg" />
        </div>
        <div className="dotMenu" onClick={() => onShareUrl(record)}>
          <FontAwesomeIcon icon={faShareNodes} size="lg" />
        </div>
        <TriDotMenu
          onEdit={() => onEdit(record)}
          onDelete={() => onDelete(record)}
          onDuplicate={() => onDuplicate(record)}
        />
      </div>
    ),
  },
];
