import { useEffect } from 'react';
import { Modal } from 'components/ui';
import { useFormik } from 'formik';
import PaymentBillingDetails from 'components/paymentBillingsDetails/PaymentBillingsDetails';
import { billingContactValidationSchema } from 'utils/validations';

import './_BillingContactModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPaymentMethods,
  updatePaymentMethod,
} from 'redux/payments/paymentsActions';

export default function BillingContactModal({
  isOpenModal,
  onClose,
  editPaymentMethod,
}) {
  const dispatch = useDispatch();
  const { isPaymentMethodLoading } = useSelector((state) => state.payments);

  const formik = useFormik({
    initialValues: {
      street_address: '',
      country: '',
      state: '',
      city: '',
      zip_code: '',
    },
    onSubmit: async (values) => {
      dispatch(
        updatePaymentMethod({
          id: editPaymentMethod.id,
          data: {
            billing_address: {
              zip_code: values.zip_code,
              city: values.city,
              street_address: values.street_address,
            },
          },
        }),
      ).then(() => {
        onClose();
        dispatch(getPaymentMethods());
      });
    },
    validateOnChange: true,
    validationSchema: billingContactValidationSchema,
  });

  useEffect(() => {
    const billing = editPaymentMethod?.billing_address;
    if (billing) {
      formik.setValues({
        country: billing.country.id,
        city: billing.city.id,
        state: billing.state.id,
        street_address: billing.street_address,
        zip_code: billing.zip_code,
      });
    } else {
      formik.resetForm();
    }
  }, [editPaymentMethod, isOpenModal]);

  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header title="Update Billing Address" onClose={onClose} />
      <Modal.Body>
        <PaymentBillingDetails formik={formik} />
      </Modal.Body>
      <Modal.Footer
        isLoading={isPaymentMethodLoading}
        secondaryAction={onClose}
        primaryLabel="Update"
        primaryAction={formik.handleSubmit}
      />
    </Modal>
  );
}
