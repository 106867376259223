/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { LocationFilter } from 'components/locationFilter';
import { DateFilter } from 'components/dateFilter';
import { SingleDateFilter } from 'components/singleDateFilter';
import {
  BranchExpFilter,
  BranchListFilter,
  ExpListFilter,
} from 'components/branchExpFilter';
// eslint-disable-next-line import/named
import { StatusFilter, ReviewFilter } from 'components/reviewsFilters';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches, getExperience } from 'redux/filters/filterActions';
import { get, has } from 'lodash';
import MultiBranchListFilter from 'components/branchExpFilter/MultiBranchListFilter';
import {
  LOCATION_FILTER,
  SINGLE_DATE_FILTER,
  BRANCH_EXP_FILTER,
  SEARCH_FILTER,
  REVIEW_FILTER,
  STATUS_FILTER,
  DATE_RANGE_FILTER,
  BRANCH_FILTER,
  EXPERIENCE_FILTER,
  MULTI_BRANCH_FILTER,
} from './filterTypes';
import './_filters.scss';
import { Icon } from 'components/ui';
import { Searchbar } from 'components/searchbar';

// filter object example:
// filter = {
//   searchFilter: '',
//   locationFilter: {branch,city,state},
//   singleDateFilter: '2023-12-11',
//   branchExpFilter: {},
//   reviewFilter: {},
//   statusFilter: {},
//   dateRangeFilter: {},
//   MULTI_BRANCH_LIST: [id,id],
// }

function Filters({
  value,
  addMainWrapper = true,
  addControlsWrapper = true,
  onChange,
  shouldFetchBranches,
  shouldNotFetchBranches,
  shouldFetchExps,
  shouldNotFetchExps,
  disableFilter = [],
  ...rest
}) {
  const dispatch = useDispatch();
  const { branchList, expList } = useSelector((state) => state.filters);

  const onFilterChange = (key) => (val) => {
    onChange({ ...value, [key]: val });
  };

  useEffect(() => {
    if (
      (has(value, BRANCH_FILTER) ||
        has(value, MULTI_BRANCH_FILTER) ||
        shouldFetchBranches) &&
      !shouldNotFetchBranches
    ) {
      dispatch(getBranches({ is_active: true, with_experiences: true }));
    }
    if (
      (has(value, EXPERIENCE_FILTER) || shouldFetchExps) &&
      !shouldNotFetchExps
    ) {
      dispatch(getExperience());
    }
  }, []);

  /* Search bar */
  const SearchFilter = has(value, SEARCH_FILTER) ? (
    <div style={{ flex: 1 }}>
      <Searchbar
        value={value[SEARCH_FILTER]}
        onChange={onFilterChange(SEARCH_FILTER)}
      />
      {value[SEARCH_FILTER] && (
        <p className="button button-primary mt-3">
          <span>{value[SEARCH_FILTER]}</span>
          <button onClick={() => onFilterChange(SEARCH_FILTER)('')}>
            <Icon type="close" />
          </button>
        </p>
      )}
    </div>
  ) : null;

  const DropdownFilters = (
    <>
      {/* Select Location */}
      {has(value, LOCATION_FILTER) && (
        <LocationFilter
          value={get(value, LOCATION_FILTER, {})}
          onChange={onFilterChange(LOCATION_FILTER)}
        />
      )}

      {/* Select by date */}
      {has(value, SINGLE_DATE_FILTER) && (
        <SingleDateFilter
          value={get(value, SINGLE_DATE_FILTER)}
          onChange={onFilterChange(SINGLE_DATE_FILTER)}
        />
      )}
      {has(value, BRANCH_EXP_FILTER) && (
        <BranchExpFilter
          value={get(value, BRANCH_EXP_FILTER)}
          onChange={onFilterChange(BRANCH_EXP_FILTER)}
        />
      )}
      {has(value, BRANCH_FILTER) && (
        <BranchListFilter
          branches={branchList}
          value={get(value, BRANCH_FILTER)}
          onChange={onFilterChange(BRANCH_FILTER)}
          placeholder={rest.branchPlaceholder}
          {...rest}
        />
      )}
      {has(value, MULTI_BRANCH_FILTER) && (
        <MultiBranchListFilter
          branches={branchList}
          value={get(value, MULTI_BRANCH_FILTER)}
          onChange={onFilterChange(MULTI_BRANCH_FILTER)}
          disabled={disableFilter.includes(MULTI_BRANCH_FILTER)}
        />
      )}
      {has(value, EXPERIENCE_FILTER) && (
        <ExpListFilter
          experiences={expList}
          value={get(value, EXPERIENCE_FILTER)}
          onChange={onFilterChange(EXPERIENCE_FILTER)}
          placeholder={rest.experiencePlaceholder}
          {...rest}
        />
      )}

      {has(value, REVIEW_FILTER) && (
        <ReviewFilter
          value={get(value, REVIEW_FILTER)}
          onChange={onFilterChange(REVIEW_FILTER)}
        />
      )}
      {has(value, STATUS_FILTER) && (
        <StatusFilter
          value={get(value, STATUS_FILTER)}
          onChange={onFilterChange(STATUS_FILTER)}
        />
      )}
      {/* Select Date Range */}
      {has(value, DATE_RANGE_FILTER) && (
        <DateFilter
          value={get(value, DATE_RANGE_FILTER)}
          onChange={onFilterChange(DATE_RANGE_FILTER)}
          showText={rest.dateRangePlaceholder}
        />
      )}
    </>
  );

  const DropdownFiltersWrapper = addControlsWrapper ? (
    <div className="controlsWrap">{DropdownFilters}</div>
  ) : (
    DropdownFilters
  );

  return addMainWrapper ? (
    <div className="MainFilterWrap">
      {SearchFilter}
      {DropdownFiltersWrapper}
    </div>
  ) : (
    <>
      {SearchFilter}
      {DropdownFiltersWrapper}
    </>
  );
}
export default Filters;
