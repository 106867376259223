/* eslint-disable camelcase */
import { Select } from 'components/ui';
import Icon from 'components/ui/icon/Icon';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getRandomColor } from 'utils/helpers';
import './_organizationForm.scss';

export default function OrganizationForm({ form, isEditing, avatarRef }) {
  const colorRef = useRef(getRandomColor());
  const logoRef = useRef();
  const { parentEntity } = useSelector((state) => state.authentication);
  const {
    name = '',
    logo = '',
    industry_type: type = null,
    industry_type_name = '',
    email = '',
    phone_number = '',
  } = parentEntity ?? {};
  const { isLoading, types } = useSelector(
    (state) => state.settings.industryTypes,
  );

  useEffect(() => {
    if (parentEntity) {
      form.resetForm({
        values: {
          ...form.values,
          name,
          logo,
          type,
          industry_type_name,
          email,
          phone_number,
        },
      });
    }
  }, [parentEntity]);

  return (
    <form className={`cxmeter-form ${isEditing ? 'small' : ''}`}>
      <label
        className={`control ${
          form.touched.name && form.errors.name ? 'control-invalid' : ''
        }`}
      >
        <input
          className="input"
          name="name"
          placeholder="Organization Name *"
          value={form.values.name}
          onChange={form.handleChange}
        />
        {form.touched.name ? (
          <span className="control-error">{form.errors.name}</span>
        ) : null}
      </label>
      <label
        className={`control ${
          form.touched.email && form.errors.email ? 'control-invalid' : ''
        }`}
      >
        <input
          className="input"
          name="email"
          type="text"
          placeholder="Email Address"
          value={form.values?.email}
          onChange={form.handleChange}
        />
        {form.touched.email && (
          <span className="control-error">{form.errors.email}</span>
        )}
      </label>
      <label
        className={`control ${
          form.touched.phone_number && form.errors.phone_number
            ? 'control-invalid'
            : ''
        }`}
      >
        <input
          name="phone_number"
          type="text"
          placeholder="Phone Number"
          value={form.values?.phone_number}
          onChange={form.handleChange}
        />
        {form.touched.phone_number && (
          <span className="control-error">{form.errors.phone_number}</span>
        )}
      </label>
      {isEditing ? (
        <label className="control">
          <input disabled value={form.values.industry_type_name} />
        </label>
      ) : (
        <Select
          name="type"
          value={form.values?.type?.id || form.values?.type}
          placeholder="Select Business Type *"
          disabled={isEditing}
          isSearchable
          isLoading={isLoading}
          onChange={(value) => {
            if (value?.isCustom) {
              form.setFieldValue('type', value);
            } else {
              form.setFieldValue('type', { id: value, isCustom: false });
            }
          }}
          options={types}
          error={
            form.touched.type && form.errors.type ? (
              <span className="control-error">{form.errors.type}</span>
            ) : null
          }
          allowCustomOption
          customOptionPlaceholder="Enter Industry Type"
        />
      )}
      <div style={{ order: isEditing ? 1 : 0 }}>
        <h3 className="onboarding-field-title">Organization Logo</h3>
        <h4 className="onboarding-field-subtitle">
          Add logo or create custom avatar
        </h4>
        <div className="avatarContainer">
          <label className="control image-control">
            <span>
              <Icon type="add" />
              Upload
            </span>
            <input
              ref={logoRef}
              type="file"
              name="logo"
              accept="image/*"
              onChange={(event) => {
                form.setFieldValue('logo', event.target.files[0]);
              }}
            />
          </label>
          {!form.values.logo && form.values.name && (
            <div
              ref={avatarRef}
              className=" control image-control profile-image"
              style={{ background: `${colorRef.current}` }}
            >
              <p>{_.get(form, 'values.name[0]', '')}</p>
            </div>
          )}
        </div>
        {form.values.logo ? (
          <p className="image-control-name">
            <button
              type="button"
              onClick={() => {
                form.setFieldValue('logo', '');
                logoRef.current.value = '';
              }}
              className="button delete-btn"
            >
              <Icon type="delete" />
            </button>
          </p>
        ) : null}
        {form?.values?.logo && (
          <img
            /* eslint-disable-next-line no-nested-ternary */
            src={
              form?.values?.logo
                ? typeof form?.values?.logo === 'string'
                  ? `${form?.values?.logo}`
                  : `${URL.createObjectURL(form?.values?.logo)}`
                : ''
            }
            alt="Logo"
            style={{ maxHeight: '4rem' }}
          />
        )}
      </div>
    </form>
  );
}
