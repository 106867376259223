/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import LocationInfoForm from '../components/locationInfoForm/LocationInfoForm';
import ExperienceForm from '../components/experienceForm/ExperienceForm';
import 'pages/onboarding/regOrgaisation/_regOrganisation.scss';
import './_locationDetailForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { URLS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsV2 } from 'redux/branchComparison/branchComparisonActions';

export default function LocationDetailForm() {
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { locations } = useSelector((s) => s.branchComparison);

  useEffect(() => {
    dispatch(getLocationsV2());
  }, [locationId]);

  return (
    <div className="locationDetailContainer">
      <button
        onClick={() => navigate(URLS.BRANCH_SETUP_SETTINGS_URL)}
        type="button"
        className="button button-back"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>Back To Locations</span>
      </button>
      <Collapse
        defaultActiveKey={locationId === 'new' && ['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: '1',
            label: (
              <div>
                <h4>
                  {locationId === 'new'
                    ? 'Location Information'
                    : locations.data.filter((loc) => loc.id === +locationId)[0]
                        ?.name || 'Location Information'}
                </h4>
                <p>Location information goes here.</p>
              </div>
            ),
            children: <LocationInfoForm />,
          },
        ]}
      />
      {locationId !== 'new' && <ExperienceForm />}
    </div>
  );
}
