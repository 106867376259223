import { createSlice } from '@reduxjs/toolkit';

import { RESOLVED_STATUS } from 'utils/constants';
import {
  getCurrentReview,
  getReviews,
  getSegments,
  postComment,
  postFeedBackNote,
  updateFeedBackNote,
  resolveFeedback,
  getFirstFeedback,
} from './reviewsListingActions';

const INITIAL_STATE = {
  reviewsList: {
    isLoading: false,
    data: [],
    count: 0,
  },
  next: null,
  previous: null,
  currentReview: {
    isLoading: false,
    data: {
      feedback_comments: [],
    },
  },
  segments: {
    data: [],
    isLoading: false,
  },
  firstFeedback: null,
};

const reviewsListingSlice = createSlice({
  name: 'reviewsListingSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearCurrentReview: (state) => {
      state.currentReview = INITIAL_STATE.currentReview;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReviews.pending, (state) => {
      state.reviewsList.isLoading = true;
    });
    builder.addCase(getReviews.rejected, (state) => {
      state.reviewsList.isLoading = false;
    });
    builder.addCase(getReviews.fulfilled, (state, action) => {
      state.reviewsList.count = action.payload.count;
      state.reviewsList.isLoading = false;
      state.reviewsList.data = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
    });
    builder.addCase(getSegments.pending, (state) => {
      state.segments.isLoading = true;
    });
    builder.addCase(getSegments.rejected, (state) => {
      state.segments.isLoading = false;
    });
    builder.addCase(getSegments.fulfilled, (state, action) => {
      state.segments.isLoading = false;
      state.segments.data = action.payload;
    });
    builder.addCase(getCurrentReview.pending, (state) => {
      state.currentReview.isLoading = true;
    });
    builder.addCase(getCurrentReview.rejected, (state) => {
      state.currentReview.isLoading = false;
    });
    builder.addCase(getCurrentReview.fulfilled, (state, action) => {
      state.currentReview.data = action.payload;
      state.currentReview.isLoading = false;
    });
    builder.addCase(postFeedBackNote.pending, () => {
      // state.currentReview.isLoading = true;
    });
    builder.addCase(postFeedBackNote.rejected, () => {
      // state.currentReview.isLoading = false;
    });
    builder.addCase(postFeedBackNote.fulfilled, (state, action) => {
      state.currentReview.isLoading = false;
      const feedbackId = action.meta.arg.feedback;
      const revIndex = state.reviewsList.data.findIndex(
        (rev) => rev.id === feedbackId,
      );
      state.reviewsList.data[revIndex].status = RESOLVED_STATUS;
      state.currentReview.data.status = RESOLVED_STATUS;
      state.currentReview.data.feedback_note = {
        id: action.payload.id,
        note: action.payload.note,
      };
    });
    builder.addCase(updateFeedBackNote.pending, () => {
      // state.currentReview.isLoading = true;
    });
    builder.addCase(updateFeedBackNote.rejected, () => {
      // state.currentReview.isLoading = false;
    });
    builder.addCase(updateFeedBackNote.fulfilled, () => {
      // state.currentReview.isLoading = false;
    });
    builder.addCase(postComment.pending, () => {});
    builder.addCase(postComment.rejected, () => {});
    builder.addCase(postComment.fulfilled, (state, action) => {
      state.currentReview.data.feedback_comments.push(action.payload);
    });
    builder.addCase(resolveFeedback.pending, (state) => {
      state.currentReview.isLoading = true;
    });
    builder.addCase(resolveFeedback.rejected, (state) => {
      state.currentReview.isLoading = false;
    });
    builder.addCase(resolveFeedback.fulfilled, (state) => {
      const currentReview = state.reviewsList.data.filter(
        (review) => state.currentReview.data.id === review.id,
      )[0];
      if (currentReview) currentReview.status = RESOLVED_STATUS;
    });

    builder.addCase(getFirstFeedback.pending, (state) => {
      state.currentReview.isLoading = true;
    });
    builder.addCase(getFirstFeedback.rejected, (state) => {
      state.currentReview.isLoading = false;
    });
    builder.addCase(getFirstFeedback.fulfilled, (state, action) => {
      state.firstFeedback =
        action.payload?.results?.length && action.payload.results[0];
      state.currentReview.isLoading = false;
    });

    return builder;
  },
});

export const { clearCurrentReview } = reviewsListingSlice.actions;
export default reviewsListingSlice;
