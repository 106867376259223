import { useNavigate, useParams } from 'react-router-dom';
import { LocationInfoForm as LocationForm } from 'components/forms';
import { useLocationSetup } from 'components/hooks';
import { URLS } from 'utils/constants';

export default function LocationInfoForm() {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { isLoading, isSubmitting, formik } = useLocationSetup({
    branchId: locationId === 'new' ? undefined : locationId,
    branch: {},
    onUpdate: (data) =>
      navigate(`${URLS.BRANCH_SETUP_SETTINGS_URL}/${data.id}`),
  });

  return (
    <div className="onboarding-form">
      <LocationForm formik={formik} />
      <button
        aria-busy={isSubmitting || isLoading ? true : null}
        type="button"
        className="button button-primary"
        onClick={formik.handleSubmit}
        style={{ marginTop: '1rem' }}
      >
        Save
      </button>
    </div>
  );
}
