import { useMemo, useState } from 'react';
import { Icon } from 'components/ui';
import { v4 as uuidv4 } from 'uuid';
import { Filters } from 'components';
import { DATE_RANGE_FILTER } from 'components/filters/filterTypes';

function SelectedComparisonFilter({
  comparisonMethod,
  filterIndex,
  selectedComparisonFilters,
  onChangeSelectedComparisonFilter,
}) {
  const [comparisonFilters, setComparisonFilters] = useState({
    [comparisonMethod.id]: selectedComparisonFilters[filterIndex] ?? {},
  });

  const disabledItems = useMemo(() => {
    const items = {};
    selectedComparisonFilters.forEach((item, index) => {
      if (item.id && filterIndex !== index) {
        items[item.id] = true;
      }
    });
    return items;
  }, [selectedComparisonFilters]);

  const onFilterChange = (value) => {
    const filters = [...selectedComparisonFilters];
    filters[filterIndex] = value[comparisonMethod.id];
    onChangeSelectedComparisonFilter(filters);
    setComparisonFilters(value);
  };

  const onRemoveFilter = (e) => {
    e?.stopPropagation();
    const filters = [...selectedComparisonFilters];
    filters.splice(filterIndex, 1);
    onChangeSelectedComparisonFilter(filters);
  };

  return (
    <Filters
      addMainWrapper={false}
      addControlsWrapper={false}
      disabledItems={disabledItems}
      shouldNotFetchBranches
      shouldNotFetchExps
      isRemoveable={selectedComparisonFilters.length > 2}
      onRemove={onRemoveFilter}
      value={comparisonFilters}
      onChange={onFilterChange}
      branchPlaceholder={`Choose Location ${filterIndex + 1}`}
      experiencePlaceholder={`Choose Experience ${filterIndex + 1}`}
      dateRangePlaceholder={
        comparisonFilters[DATE_RANGE_FILTER]?.label ?? 'Date Range'
      }
      hideAllOpt
    />
  );
}

export default function SelectedComparisonFilters({
  selectedComparisonFilters,
  onChangeSelectedComparisonFilter,
  addSelectedComparisonFilters,
  comparisonMethod,
}) {
  return (
    <>
      {selectedComparisonFilters.map((filter, index) => (
        <SelectedComparisonFilter
          key={filter?.id ?? uuidv4()}
          filterIndex={index}
          selectedComparisonFilters={selectedComparisonFilters}
          onChangeSelectedComparisonFilter={onChangeSelectedComparisonFilter}
          comparisonMethod={comparisonMethod}
        />
      ))}
      {selectedComparisonFilters.length > 2 ? null : (
        <button
          type="button"
          onClick={addSelectedComparisonFilters}
          className="add-comparison-filter"
        >
          <Icon type="add" />
        </button>
      )}
    </>
  );
}
