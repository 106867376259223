import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import colors from 'scss/_customVariables.scss';
import { convertToTitleCase, chartHelper } from 'utils/helpers';

import './_charts.scss';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PlainDoughnutChart({ analytics, title }) {
  const data = {
    labels: Object.keys(analytics).map((key) => convertToTitleCase(key)),
    datasets: [
      {
        label: title,
        data: Object.values(analytics),
        backgroundColor: [colors.primary, colors.danger, colors.secondary],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      responsive: false,
      tooltip: {
        enabled: false,
        external: chartHelper.externalTooltipHandlerPlainDoughnutChart,
      },
      legend: {
        padding: 40,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxHeight: 4,
          boxWidth: 4,
          padding: 10,
          font: {
            size: 12,
          },
        },
      },
      datalabels: {
        color: 'white',
        display: true,
        formatter: (value) => (value > 10 ? `${value}%` : ''),
      },
    },
  };

  return (
    <div className="ChartWrapper">
      <h4>{title}</h4>
      <div className="Chart">
        <Doughnut
          data={data}
          options={options}
          plugins={[ChartDataLabels]}
          width={300}
          height={150}
        />
      </div>
    </div>
  );
}
