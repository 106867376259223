import { convertToSnakeCase } from 'utils/helpers/global';
import {
  DELIVERY,
  DRIVE_THRU,
  ON_TIME,
  ORDER_ACCURACY,
  HAPPY,
  NEUTRAL,
} from 'utils/constants';

export const getIconSrc = (driver, experience) => {
  if (
    (experience === DELIVERY || experience === DRIVE_THRU) &&
    (driver === ON_TIME || driver === ORDER_ACCURACY)
  ) {
    driver = `${driver}_${experience}`;
  }
  return `/assets/icons/icon-${convertToSnakeCase(driver)}.svg`;
};

export const getSegmentIconSrc = (segmentName) => {
  const iconPath = '/assets/icons/';
  if (segmentName === HAPPY) return `${iconPath}icon_happy.svg`;
  if (segmentName === NEUTRAL) return `${iconPath}icon_neutral.svg`;
  return `${iconPath}icon_unhappy.svg`;
};
export default getIconSrc;
