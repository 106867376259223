import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { LineChart } from 'components/charts';
import {
  getHapinessOverTime,
  getHapinessOverTimeByEntity,
} from 'redux/dashboard/dashboardActions';

import { createSearchParams, useNavigate } from 'react-router-dom';
import { DAILY, WEEKLY, MONTHLY } from 'utils/constants';
import { REVIEWS_LISTING_URL } from 'utils/constants/urlConstants';
import {
  renameAndOmitKeys,
  formatDate,
  reviewHelper,
  getFilters,
} from 'utils/helpers';

import './_statisticsGraph.scss';

function HappinessOverTimeGraph({
  filters,
  breakdownBranches = null,
  showBreakDownButton = true,
  title = 'Happiness Trend',
}) {
  const [filter, setFilter] = useState(DAILY);
  const [showBranchBreakdown, setShowBranchBreakdown] = useState(
    breakdownBranches !== null,
  );
  const [showBranchBreakdownBtn, setShowBranchBreakdownBtn] = useState(true);
  const [showWeeklyFilter, setShowWeeklyFilter] = useState(true);
  const [showDailyFilter, setShowDailyFilter] = useState(true);
  const [showMonthlyFilter, setShowMonthlyFilter] = useState(true);
  const { getEndDate } = reviewHelper;
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.happinessOverTime,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeFilter = (_filter) => {
    if (_filter !== filter) {
      setFilter(_filter);
    }
  };

  const handleShowBranchBreakdown = () => {
    if (showBranchBreakdown) {
      dispatch(getHapinessOverTime(filters));
    } else {
      dispatch(getHapinessOverTimeByEntity(filters));
    }
    setShowBranchBreakdown(!showBranchBreakdown);
  };

  const parseData = () => {
    if (showBranchBreakdown && Array.isArray(data)) {
      let branchData = data;
      if (!isEmpty(breakdownBranches)) {
        branchData = data.filter((d) => breakdownBranches.includes(`${d.id}`));
      }
      return branchData?.reduce((acc, i) => {
        // eslint-disable-next-line no-unused-vars
        Object.entries(i.trends[filter] ?? {}).forEach(([key, value]) => {
          if (!acc[key]) {
            acc[key] = {};
          }
          // acc[key][i.name] = value.happiness_index;
          acc[key][i.name] =
            value.total === 0 ? undefined : value.happiness_index;
        });
        return acc;
      }, {});
    }

    // filters daily, weekly, or monthly data
    return get(data, filter, {});
  };

  // eslint-disable-next-line max-len
  const getEntityId = (name) =>
    data.find((entity) => entity.name.toLowerCase() === name.toLowerCase())?.id;

  const handleOnChartClick = ({ label, datasetLabel }) => {
    const filtersKeysToOmit = ['from_web', 'date_range[0]', 'date_range[1]'];
    const startDate = formatDate(label);
    const searchParams = {
      start_date: startDate,
      end_date: getEndDate(startDate, filter),
    };
    if (showBranchBreakdown) searchParams.entity = getEntityId(datasetLabel);

    navigate({
      pathname: REVIEWS_LISTING_URL,
      search: createSearchParams({
        ...searchParams,
        ...renameAndOmitKeys(getFilters(filters), {}, filtersKeysToOmit),
      }).toString(),
    });
  };

  useEffect(() => {
    if (filters?.entity) {
      setShowBranchBreakdownBtn(false);
      setShowBranchBreakdown(false);
    } else setShowBranchBreakdownBtn(true);

    if (!filters?.entity && showBranchBreakdown) {
      dispatch(getHapinessOverTimeByEntity(filters));
    } else {
      dispatch(getHapinessOverTime(filters));
    }

    setShowDailyFilter(true);
    setShowWeeklyFilter(true);
    setShowMonthlyFilter(true);
  }, [filters]);

  useEffect(() => {
    if (filters['date_range[0]'] && filters['date_range[1]']) {
      const startDate = dayjs(filters['date_range[0]'], 'YYYY-MM-DD');
      const endDate = dayjs(filters['date_range[1]'], 'YYYY-MM-DD');
      const months = Math.abs(startDate.diff(endDate, 'months'));
      if (months < 1) setShowMonthlyFilter(false);
      if (months >= 1) setShowDailyFilter(false);
      if (months >= 12) setShowWeeklyFilter(false);
      if (months >= 6) {
        setFilter(MONTHLY);
      }
      if (months < 6 && months >= 1) {
        setFilter(WEEKLY);
      }
      if (months < 1) {
        setFilter(DAILY);
      }
    }
  }, [filters]);

  return (
    <div className="happinessOverTimeContainer">
      <div className="statHolder">
        <div className="titleBtnsBlock">
          <h3>{title}</h3>
          <ul className="filterBtns">
            {showDailyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(DAILY)}
                  className={filter === DAILY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  Daily
                </span>
              </li>
            )}
            {showWeeklyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(WEEKLY)}
                  className={filter === WEEKLY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  Weekly
                </span>
              </li>
            )}
            {showMonthlyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(MONTHLY)}
                  className={filter === MONTHLY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  Monthly
                </span>
              </li>
            )}
          </ul>
        </div>
        {showBranchBreakdownBtn && showBreakDownButton && (
          <div className="breakdownBtnBlock">
            <button
              type="button"
              onClick={() => handleShowBranchBreakdown()}
              className={showBranchBreakdown ? 'active' : ''}
            >
              Breakdown by Location
            </button>
          </div>
        )}
        {isLoading ? (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        ) : (
          <div className="graphBody">
            <LineChart
              data={parseData()}
              onClick={handleOnChartClick}
              xLabel="Date"
              yLabel="Happiness"
              nps={data?.nps_benchmark}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HappinessOverTimeGraph;
