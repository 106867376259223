import React from 'react';

import './_chips.scss';
import {
  BRANCH_EXP_FILTER,
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  LOCATION_FILTER,
  REVIEW_FILTER,
  SEARCH_FILTER,
  SINGLE_DATE_FILTER,
  STATUS_FILTER,
} from 'components/filters/filterTypes';
import _ from 'lodash';

const ignoredChips = ['last_viewed_id', 'sort_order', 'multiBranchFilter'];

function Chips({ filters, onDelete, disableReset }) {
  const keys = Object.keys(filters).filter(
    (o) => !_.isEmpty(filters[o]) && !ignoredChips.includes(o),
  );

  const chipName = {
    [LOCATION_FILTER]: 'Location',
    [SINGLE_DATE_FILTER]: 'Date',
    [BRANCH_EXP_FILTER]: 'Location Experience',
    [BRANCH_FILTER]: 'Location',
    [EXPERIENCE_FILTER]: 'Experience',
    [SEARCH_FILTER]: 'Search',
    [REVIEW_FILTER]: 'Review',
    [STATUS_FILTER]: 'Status',
    [DATE_RANGE_FILTER]: 'Date Range',
  };

  const resetFilters = () => {
    const resettedFilters = {};
    keys.forEach((filter) => {
      resettedFilters[filter] = null;
    });
    onDelete({ ...filters, ...resettedFilters });
  };

  return (
    <div className="chipsWrapper">
      {keys.map((filter) => (
        <div className="chip" key={filter}>
          <span className="chip-title text-ellipsis">
            {filter === DATE_RANGE_FILTER
              ? `${filters[filter].startDate} - ${filters[filter].endDate}`
              : filters[filter]?.name || chipName[filter]}
          </span>
          <span
            className="closebtn"
            onClick={() => onDelete({ ...filters, [filter]: null })}
            role="button"
            tabIndex={0}
          >
            &times;
          </span>
        </div>
      ))}
      {!disableReset && keys.length > 0 && (
        <div
          className="resetBtn"
          onClick={() => resetFilters()}
          tabIndex={0}
          role="button"
        >
          Reset All
        </div>
      )}
    </div>
  );
}

export default Chips;
