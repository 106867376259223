import React, { useEffect, useState } from 'react';
import './_invoices.scss';
import { Loader } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { listInvoices } from 'redux/payments/paymentsActions';
import { Table } from 'antd';
import _ from 'lodash';
import { invoicesColumns } from './columns';
import useOrdering from 'utils/hooks/useOrdering';

export default function Invoices() {
  const [pageControl, setPageControl] = useState(null);
  const { isLoading, invoices } = useSelector((s) => s.payments);
  const dispatch = useDispatch();
  const [ordering, updateSorting, getOrderingForTable] = useOrdering([]);

  const onInvoiceClick = (rec) => {
    window.open(rec.pdf_url, '_blank');
  };

  const onChange = (pagination, filters, sorter) => {
    if (!_.isEmpty(sorter)) {
      updateSorting(sorter);
    }
  };

  useEffect(() => {
    dispatch(
      listInvoices({
        ordering: `${ordering.join(',')}`,
        ...pageControl,
      }),
    );
  }, [ordering, pageControl]);

  return (
    <main>
      {isLoading && <Loader />}
      <div className="invoicesContainer">
        <div className="heading mb-30">
          <span>
            <h3>Invoices</h3>
            <p>View invoice details</p>
          </span>
        </div>
        <div className="contentWrap">
          <Table
            columns={invoicesColumns(
              onInvoiceClick,
              getOrderingForTable(ordering),
            )}
            dataSource={invoices.results}
            onChange={onChange}
            pagination={{
              total: invoices.count,
              defaultPageSize: 10,
              onChange: (page, pageSize) =>
                setPageControl({ page, size: pageSize }),
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </main>
  );
}
