import React from 'react';
import { markupToText } from 'utils/helpers';

export default function ReviewRadio({ question }) {
  return (
    <div className="feedback-radios">
      {question.options.map((option) => (
        <div key={option.option_id}>
          <input
            type="radio"
            checked={question.answer === option.option_id}
            disabled
          />
          {markupToText(option.label)}
        </div>
      ))}
    </div>
  );
}
