import { Modal, Spinner } from 'components/ui';
import { useFormik } from 'formik';
import ToEmailInput from '../ToEmailInput';
import {
  createInviteGroup,
  getInviteGroupById,
  getInviteGroups,
  updateInviteGroupById,
} from 'redux/emailManagement/emailManagementActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { inviteGroupValidationSchema } from 'utils/validations/emailMangementValidations';
import { toast } from 'react-toastify';
import {
  EMAIL_GROUPS,
  EMAIL_MANAGEMENT_PAGINATION,
} from 'utils/constants/manageEmails';
import { formatGroupData } from 'utils/helpers/emailsManagementHelper';
import '../_manageEmailModals.scss';

function InviteGroupModal({ activeTab }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const {
    inviteGroup: { data: inviteGroup, loading: inviteGroupLoading },
  } = useSelector((s) => s.emailManagement);

  const {
    inviteGroupIdRef,
    setIsOpenGroupModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
    params,
    setParams,
  } = useContext(EmailManagementFiltersContext);
  const inviteGroupId = inviteGroupIdRef.current;

  const [loading, setLoading] = useState(false);
  const [newInviteGroupData, setNewInviteGroupData] = useState(null);

  const handleModalClose = () => {
    inviteGroupIdRef.current = null;
    setIsOpenGroupModal(false);
  };

  const composeEmailHandler = () => {
    handleModalClose();
    setIsOpenSendEmailModal(true);
    inviteEmailDataRef.current = { group: newInviteGroupData };
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      members: [],
      members_file: null,
    },
    validationSchema: inviteGroupValidationSchema,
    onSubmit: async (vals) => {
      const { emailInput, ...emailValues } = vals;
      if (emailInput && emailInput.trim()) {
        formik.setFieldError('invalidMembers', 'Please enter a valid email');
      } else {
        const inviteGroupFormData = formatGroupData(emailValues);
        setLoading(true);
        if (inviteGroupId) {
          const { isError } = await dispatch(
            updateInviteGroupById({
              id: inviteGroupId,
              data: inviteGroupFormData,
            }),
            formik,
          );
          if (!isError) {
            toast.success(t('settings.manageEmails.toasts.updateInviteGroup'));
            dispatch(getInviteGroups(params));
            handleModalClose();
          }
        } else {
          const { data, isError } = await dispatch(
            createInviteGroup(inviteGroupFormData),
            formik,
          );
          if (!isError) {
            toast.success(t('settings.manageEmails.toasts.createInviteGroup'));
            setNewInviteGroupData(data);
            if (activeTab.id === EMAIL_GROUPS) {
              setParams({ ...EMAIL_MANAGEMENT_PAGINATION });
            }
          }
        }
        setLoading(false);
      }
    },
  });

  const submitGroupForm = () => {
    setTimeout(() => {
      // To handle the onBlur Event called from Email To Input before form submission
      formik.submitForm();
    }, 0);
  };

  useEffect(() => {
    if (inviteGroup?.id === inviteGroupId) {
      formik.resetForm({ values: inviteGroup });
    }
  }, [inviteGroup, inviteGroupId]);

  useEffect(() => {
    if (inviteGroupId) {
      dispatch(getInviteGroupById(inviteGroupId));
    }
  }, [inviteGroupId]);

  const { touched, errors, values } = formik;

  return (
    <Modal
      open
      onClose={handleModalClose}
      backDropClose={false}
      className="email-management-modals"
    >
      {inviteGroupLoading ? <Spinner /> : null}
      <Modal.Header
        title="Create Group"
        isEditable={inviteGroupId}
        formik={formik}
        inputName="name"
        onClose={handleModalClose}
      />
      {newInviteGroupData ? (
        <Modal.Body>
          <article className="group-created-poster">
            <div className="poster">
              <img
                width={90}
                height={80}
                src="/assets/icons/icon_taskCompleted.svg"
                alt="Group Created"
              />
              <div>
                <h3>
                  {t(
                    'settings.manageEmails.modals.inviteGroup.groupCreated.title',
                  )}
                </h3>
                <p>
                  {t(
                    'settings.manageEmails.modals.inviteGroup.groupCreated.subTitle',
                    { name: newInviteGroupData.name },
                  )}
                </p>
              </div>
              <div className="poster-actions">
                <button
                  className="cstm-btn light-cstm-btn"
                  onClick={handleModalClose}
                >
                  Go Back
                </button>
                <button
                  onClick={composeEmailHandler}
                  className="cstm-btn primary-cstm-btn"
                >
                  Compose Email
                </button>
              </div>
            </div>
          </article>
        </Modal.Body>
      ) : (
        <>
          <Modal.Body>
            <article className="cxmeter-modal-body-content">
              {inviteGroupId ? null : (
                <div className="control-group">
                  <label
                    className={`control ${
                      touched.name && errors.name ? 'control-invalid' : ''
                    }`}
                  >
                    <span className="required-field">
                      Group Name <span>*</span>
                    </span>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter"
                      onChange={formik.handleChange}
                      value={values.name}
                    />
                    {touched.name && (
                      <span className="control-error">{errors.name}</span>
                    )}
                  </label>
                </div>
              )}
              <ToEmailInput formik={formik} />
            </article>
          </Modal.Body>
          <Modal.Footer
            isLoading={loading}
            primaryAction={submitGroupForm}
            primaryLabel={inviteGroupId ? 'Update Group' : 'Create Group'}
            disablePrimaryAction={loading}
          />
        </>
      )}
    </Modal>
  );
}

export default InviteGroupModal;
