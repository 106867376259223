import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import _ from 'lodash';

import { ENTITY_ADMIN, PARENT_ENTITY_ADMIN, URLS } from 'utils/constants';
import {
  showErrorToast,
  storeParentEntity,
  storeTokens,
  storeUser,
} from 'utils/helpers';

import { loginWithGoogleAPICall } from 'apis/authentication';
import './_loginWithGoogle.scss';
import { singleParentEntitySelectAPICall } from 'apis/cxmeter';
import { Loader } from 'components/loader';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';
import { toast } from 'react-toastify';

function LoginWithGoogle() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  const setUser = async (user, token) => {
    let selectedPE = { orgs: [], selectedOrg: null };

    storeTokens(token);
    storeUser(user);

    if (user.type === PARENT_ENTITY_ADMIN) {
      selectedPE = await singleParentEntitySelectAPICall();
      dispatch(getUserAndInitialiseData());
      if (selectedPE.orgs.length === 0) {
        return navigate(URLS.REGISTER_ORG_URL);
      }
    }
    if (
      user.is_superuser ||
      user.type === ENTITY_ADMIN ||
      selectedPE.orgs.length <= 1
    ) {
      if (!_.isEmpty(selectedPE.selectedOrg)) {
        storeParentEntity(selectedPE.selectedOrg);
      } else if (user.parent_entity) storeParentEntity(user.parent_entity);
      dispatch(getUserAndInitialiseData());
      navigate(URLS.DASHBOARD_URL);
    } else {
      dispatch(getUserAndInitialiseData());
      navigate(URLS.ORGANIZATION_URL);
    }
  };

  const handleLoginWithGoogle = ({ credential }) => {
    setLoading(true);
    loginWithGoogleAPICall({
      auth_token: credential,
    })
      // eslint-disable-next-line camelcase
      .then(async ({ user, token, parent_entity }) => {
        await setUser(user, token, parent_entity);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorToast(error);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="loginWithGoogleBtn">
      <GoogleLogin
        onSuccess={handleLoginWithGoogle}
        onError={() => {
          toast.success('Failed to Continue with Google');
        }}
        text="continue_with"
        theme="filled_blue"
      />
    </div>
  );
}

export default LoginWithGoogle;
