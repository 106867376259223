import { Table } from 'antd';
import { getEmailSchedulesColumns } from 'utils/helpers/emailsManagementHelper';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteInviteScheduleById,
  getInviteScheduleById,
  getInviteSchedules,
} from 'redux/emailManagement/emailManagementActions';
import {
  DELETE_SCHEDULE_PREFIX,
  EMAIL_MANAGEMENT_PAGINATION_LIMIT,
} from 'utils/constants/manageEmails';

function Schedules() {
  const dispatch = useDispatch();
  const {
    params,
    onChangePagination,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(EmailManagementFiltersContext);
  const {
    inviteSchedules: { data: inviteSchedules, loading },
    deleteEmailItems: { loading: deleteInviteSchedulesLoading },
  } = useSelector((s) => s.emailManagement);

  const onViewSchedule = (schedule) => {
    dispatch(getInviteScheduleById(schedule.id));
    setIsOpenSendEmailModal(true);
    inviteEmailDataRef.current = { inviteId: schedule.id };
  };

  const onDeleteSchedule = (schedule) => {
    dispatch(
      deleteInviteScheduleById({
        params,
        itemId: schedule.id,
        prefix: DELETE_SCHEDULE_PREFIX,
      }),
    );
  };

  useEffect(() => {
    dispatch(getInviteSchedules({ ...params }));
  }, [params]);

  return (
    <div>
      <div className="contentWrap">
        <Table
          className="left-align"
          scroll={{ x: 'max-content' }}
          columns={getEmailSchedulesColumns(
            onViewSchedule,
            onDeleteSchedule,
            deleteInviteSchedulesLoading,
          )}
          dataSource={inviteSchedules.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: inviteSchedules.count,
            defaultPageSize: EMAIL_MANAGEMENT_PAGINATION_LIMIT,
            onChange: onChangePagination,
          }}
        />
      </div>
    </div>
  );
}

export default Schedules;
