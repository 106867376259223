import { Table } from 'antd';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInviteGroups } from 'redux/emailManagement/emailManagementActions';
import { EMAIL_MANAGEMENT_PAGINATION_LIMIT } from 'utils/constants/manageEmails';
import { getGroupsColumns } from 'utils/helpers/emailsManagementHelper';

function Groups() {
  const dispatch = useDispatch();

  const {
    inviteGroups: { data: inviteGroups, loading },
  } = useSelector((s) => s.emailManagement);

  const {
    params,
    onChangePagination,
    inviteGroupIdRef,
    setIsOpenGroupModal,
    setIsOpenSendEmailModal,
    inviteEmailDataRef,
  } = useContext(EmailManagementFiltersContext);

  const editInviteGroupHandler = (inviteGroup) => {
    inviteGroupIdRef.current = inviteGroup.id;
    setIsOpenGroupModal(true);
  };

  const composeEmailHandler = (group) => {
    inviteEmailDataRef.current = { invite: { groups: [group] } };
    setIsOpenSendEmailModal(true);
  };

  useEffect(() => {
    dispatch(getInviteGroups({ ...params }));
  }, [params]);

  return (
    <div>
      <div className="contentWrap">
        <Table
          className="left-align"
          scroll={{ x: 'max-content' }}
          columns={getGroupsColumns(
            editInviteGroupHandler,
            composeEmailHandler,
          )}
          dataSource={inviteGroups.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: inviteGroups.count,
            defaultPageSize: EMAIL_MANAGEMENT_PAGINATION_LIMIT,
            onChange: onChangePagination,
          }}
        />
      </div>
    </div>
  );
}

export default Groups;
