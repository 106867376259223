import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBranches } from 'redux/filters/filterActions';
import { SurveyThumbnail } from 'components/surveyThumbnail';

export default function SurveySelector({ onChange, value, experiences = [] }) {
  const disptach = useDispatch();

  useEffect(() => {
    disptach(getBranches({ extra_data: true, with_experiences: true }));
  }, []);

  return (
    <div className="survey-modal-experiences">
      {experiences.length === 0 ? (
        <p>
          There is no Customer Experience added for this location. Please add a
          customer experience first.
        </p>
      ) : null}
      {experiences.map((exp) => (
        <SurveyThumbnail
          selected={value === exp.entity_experience_id}
          onChange={() => onChange(exp.entity_experience_id)}
          key={exp.id}
          icon={exp.experience_logo}
          data={{
            ...exp,
            preview: exp.survey_preview,
            title: exp.experience_name,
          }}
        />
      ))}
    </div>
  );
}
