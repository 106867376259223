import * as Yup from 'yup';
import { emailRegex } from 'utils/constants/regex';

Yup.addMethod(
  Yup.string,
  'requiredTrimmed',
  function (
    blankSpacesMessage = 'Input cannot be only blank spaces.',
    emptyMessage = 'This field is required',
  ) {
    return this.test('requiredTrimmed', (value, { createError, path }) => {
      // Check for empty string
      if (!value || value === '') {
        return createError({ path, message: emptyMessage });
      }
      // Check for only blank spaces
      if (value && value.trim().length === 0) {
        return createError({ path, message: blankSpacesMessage });
      }
      return true;
    });
  },
);

Yup.addMethod(
  Yup.string,
  'range',
  function (
    min = 2,
    max = 50,
    minMessage = 'Too short',
    maxMessage = 'Too long',
  ) {
    return this.test('range', (value, { createError, path }) => {
      // Return true if value is empty (handle required validation separately)
      if (!value || value === '') {
        return true;
      }
      // Check for Minimum
      if ((value ?? '').trim().length < min)
        return createError({ path, message: minMessage });
      // Check for Maximum
      if ((value ?? '').trim().length > max)
        return createError({ path, message: maxMessage });
      return true;
    });
  },
);

Yup.addMethod(
  Yup.string,
  'customEmail',
  function (message = 'Please enter a valid email') {
    return this.test('customEmail', message, (value, { createError, path }) => {
      // Return true if value is empty (handle required validation separately)
      if (!value) {
        return true;
      }
      if (!emailRegex.test(value)) {
        return createError({ path, message });
      }
      return true;
    });
  },
);

export default Yup;
