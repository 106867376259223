/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './_locationFilter.scss';
import { getLocations } from 'redux/filters/filterActions';
import iconLocationGray from '../../assets/icons/icon_location-gray.svg';

function LocationFilter({ value = {}, onChange }) {
  const locations = useSelector((state) => state.filters.locations);
  const [show, setShow] = useState(false);
  const [branch, _setBranch] = useState(value.branch);
  const [city, _setCity] = useState(value.city);
  const [state, _setState] = useState(value.state);
  const dispatch = useDispatch();

  function setCity(event, cityId) {
    event.preventDefault();
    if (city === cityId) {
      _setCity(null);
    } else {
      _setCity(cityId);
    }
  }

  function setState(event, stateId) {
    event.preventDefault();
    if (state === stateId) {
      _setState(null);
    } else {
      _setState(stateId);
    }
  }

  function setBranch(event, branchAddress) {
    event.preventDefault();
    if (branch === branchAddress) {
      _setBranch(null);
    } else {
      _setBranch(branchAddress);
    }
  }

  function toggleDropdown(isOpen, event) {
    if (event.source !== 'select') {
      setShow(isOpen);
    }
  }

  async function resetFilter() {
    _setState(null);
    _setBranch(null);
    _setCity(null);
    setShow(false);
    onChange({
      branch: null,
      city: null,
      state: null,
    });
  }

  async function applyFilter() {
    setShow(false);
    onChange({
      branch,
      city,
      state,
    });
  }

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  return (
    <div className="boxSHadow FilterButtonWrap Location">
      <Dropdown show={show} onToggle={toggleDropdown}>
        <Dropdown.Toggle className="LocationBtn" variant="" id="dropdown-basic">
          <img alt="Location Button" src={iconLocationGray} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="LocationDropdown">
            <div className="holder">
              <div className="head">
                <strong>Location Filter</strong>
              </div>
              <div className="content">
                <div className="holder">
                  <strong>State/Province</strong>
                  <ul>
                    {locations.states.map((_state, index) => (
                      <li key={index}>
                        <Dropdown.Item
                          className={
                            state === _state.state_id ? 'selected' : ''
                          }
                          onClick={(event) => setState(event, _state.state_id)}
                        >
                          {_state.state_name}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="holder">
                  <strong>city</strong>
                  <ul>
                    {locations.cities.map((_city, index) => (
                      <li key={index}>
                        <Dropdown.Item
                          className={city === _city.city_id ? 'selected' : ''}
                          onClick={(event) => setCity(event, _city.city_id)}
                        >
                          {_city.city_name}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="holder">
                  <strong>branch</strong>
                  <ul>
                    {locations.branches.map((_branch, index) => (
                      <li key={index}>
                        <Dropdown.Item
                          className={branch === _branch ? 'selected' : ''}
                          onClick={(event) => setBranch(event, _branch)}
                        >
                          {_branch}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="footer">
                <button
                  type="button"
                  disabled={!city && !state && !branch}
                  onClick={resetFilter}
                >
                  reset all
                </button>
                <button type="submit" onClick={applyFilter}>
                  apply
                </button>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default LocationFilter;
