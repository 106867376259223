import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/constants';

import './_errorPages.scss';

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="errorPages">
      <h1 className="errorCode">404</h1>
      <h4 className="errorMsg">Page not Found</h4>
      <button
        type="button"
        className="backBtn"
        onClick={() => navigate(URLS.DASHBOARD_URL)}
      >
        Go to Dashboard
      </button>
    </div>
  );
}

export default NotFound;
