import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomDropdownButton from 'components/customDropdownButton/CustomDropdownButton';
import { STATUS } from 'utils/constants';
import './_reviewsFilters.scss';
/**
 * @author
 * @function StatusFilter
 * */

function StatusFilter({ value, onChange, ...rest }) {
  const [isAllSelected, setIsAllSelected] = useState(false);

  const onChangeHandler = (id) => {
    if (id === null) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    onChange(id);
  };

  return (
    <div>
      <CustomDropdownButton
        id="status-list"
        name="status"
        value={value?.id}
        onChange={onChangeHandler}
        title={isAllSelected ? 'All' : value?.name || 'Status'}
        {...rest}
      >
        {STATUS?.map((status, i) => (
          <Dropdown.Item
            key={i}
            value={status.id}
            onClick={() => onChangeHandler(status)}
            active={status.id === value?.id}
          >
            {status.name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          id="all-questions"
          value={null}
          onClick={() => onChangeHandler(null)}
          active={!value}
        >
          All
        </Dropdown.Item>
      </CustomDropdownButton>
    </div>
  );
}

export default StatusFilter;
