import TextArea from 'antd/es/input/TextArea';
import Checkbox from 'components/ui/checkbox/Checkbox';
import { CHECKBOX, TEXT } from 'utils/constants/questionTypes';
import { markupToText } from 'utils/helpers';

function CheckboxFeedback({ formik, question, questionIndex, sectionIndex }) {
  const feedbackChangeHandler = (isSelected, optionIndex) => {
    const fieldPath = `forms.sections[${sectionIndex}].questions[${questionIndex}].options[${optionIndex}].selected`;
    formik.setFieldValue(fieldPath, isSelected);
  };

  return (
    <div className={`question-${question.question_type}-options`}>
      {question.options.map((option, index) => (
        <Checkbox
          key={option.option_id}
          id={option.option_id}
          label={markupToText(option.label)}
          checked={!!option.selected}
          checkedStyles={{
            backgroundColor: '#DE0024',
            borderColor: '#DE0024',
          }}
          onChange={() => feedbackChangeHandler(!option.selected, index)}
        />
      ))}
    </div>
  );
}

function TextFeedback({ formik, question, questionIndex, sectionIndex }) {
  const feedbackChangeHandler = ({ target: { value } }) => {
    const fieldPath = `forms.sections[${sectionIndex}].questions[${questionIndex}].feedback`;
    formik.setFieldValue(fieldPath, value);
  };
  return (
    <TextArea
      value={question.feedback ?? ''}
      onChange={feedbackChangeHandler}
      placeholder="Help us improve ..."
    />
  );
}

export default function CancelPlanFeedback({ question, ...props }) {
  switch (question.question_type) {
    case CHECKBOX:
      return <CheckboxFeedback question={question} {...props} />;
    case TEXT:
      return <TextFeedback question={question} {...props} />;
    default:
      return <div />;
  }
}
