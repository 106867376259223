import { HAPPY, NEUTRAL, UNHAPPY } from './global';

export const QuickStatsColors = {
  [NEUTRAL]: '#e2c103',
  [HAPPY]: '#01c400',
  [UNHAPPY]: '#e30058',
};

export const chartColors = [
  '#FF827A',
  '#7963CD',
  '#9F00AD',
  '#5BC7E6',
  '#FFC178',
  '#63CD81',
];

export const HorizontalBarChartGradientColors = {
  1: '#f22c2c',
  2: '#ff9100',
  3: '#fae31e',
  4: '#a2d500',
  5: '#16c211',
};
