import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { EMAIL_MANAGEMENT_PAGINATION_LIMIT } from 'utils/constants/manageEmails';
import { useDispatch, useSelector } from 'react-redux';
import { getInvites } from 'redux/emailManagement/emailManagementActions';
import InboxSubTabs from './InboxSubTabs/InboxSubTabs';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { getInboxColumns } from 'utils/helpers/emailsManagementHelper';

function Inbox({ subTabHandler, activeSubTab }) {
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { params, onChangePagination } = useContext(
    EmailManagementFiltersContext,
  );
  const dispatch = useDispatch();
  const {
    invites: { data: invites, loading },
  } = useSelector((s) => s.emailManagement);

  useEffect(() => {
    if (!activeSubTab) dispatch(getInvites({ ...params }));
  }, [params]);

  const activateSubTab = (record) => {
    setSelectedRecord(record);
    const { date, subject } = record;
    subTabHandler(true, subject, date);
  };

  return (
    <div className="inbox-table">
      {!activeSubTab ? (
        <Table
          className="left-align"
          columns={getInboxColumns(activateSubTab)}
          scroll={{ x: 'max-content' }}
          dataSource={invites.results}
          loading={loading}
          pagination={{
            showSizeChanger: false,
            current: params.page,
            total: invites.count,
            defaultPageSize: EMAIL_MANAGEMENT_PAGINATION_LIMIT,
            onChange: onChangePagination,
          }}
        />
      ) : (
        <InboxSubTabs
          selectedRecord={selectedRecord}
          activeSubTab={activeSubTab}
        />
      )}
    </div>
  );
}

export default Inbox;
