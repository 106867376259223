/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';

export const invoicesColumns = (onInvoiceClick, ordering) => [
  {
    title: 'Date & Time',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => dayjs(text).format('MM/DD/YYYY, hh:mm A'),
    defaultSortOrder: ordering?.created_at,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
    render: (text) => `$${text}`,
    defaultSortOrder: ordering?.total_amount,
    sorter: {
      multiple: 2,
    },
  },
  {
    title: 'Invoice',
    dataIndex: 'stripe_invoice_number',
    key: 'stripe_invoice_number',
    defaultSortOrder: ordering?.stripe_invoice_number,
    sorter: {
      multiple: 3,
    },
    render: (text, record) => (
      <a onClick={() => onInvoiceClick(record)}>
        <FontAwesomeIcon icon={faFilePdf} />
        &nbsp;&nbsp;
        {text}
      </a>
    ),
  },
];
