import { Filters } from 'components';
import { DATE_RANGE_FILTER } from 'components/filters/filterTypes';
import { Icon } from 'components/ui';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { COMPARISON_METHODS } from 'utils/constants/compareFeedbacks';

function ComparisonControls({ comparisonMethod, onChangeComparisonParams }) {
  const populateFilters = () => {
    const filters = {
      [DATE_RANGE_FILTER]: {},
    };
    COMPARISON_METHODS.forEach((method) => {
      if (comparisonMethod.id !== method.id) filters[method.id] = {};
    });
    return filters;
  };
  const [comparisonFilters, setComparisonFilters] = useState(populateFilters);

  const resetFiltersHandler = () => {
    setComparisonFilters(populateFilters);
  };

  useEffect(() => {
    onChangeComparisonParams(comparisonFilters);
  }, [comparisonFilters]);

  useEffect(() => {
    setComparisonFilters(populateFilters);
  }, [comparisonMethod]);

  const showResetBtn = useMemo(
    () => Object.values(comparisonFilters).find((filter) => !isEmpty(filter)),
    [comparisonFilters],
  );

  const dateFilterPlaceholder = useMemo(() => {
    const { startDate, endDate } = comparisonFilters[DATE_RANGE_FILTER];
    return startDate && endDate ? `${startDate} to ${endDate}` : 'Date Range';
  }, [comparisonFilters[DATE_RANGE_FILTER]]);

  return (
    <div className="comparison__filters-section">
      <Icon type="filter_alt" />
      <div className="comparison__controls">
        <Filters
          shouldFetchExps
          shouldFetchBranches
          value={comparisonFilters}
          onChange={setComparisonFilters}
          branchPlaceholder="Location"
          experiencePlaceholder="Experience"
          dateRangePlaceholder={dateFilterPlaceholder}
          isClearable
          hideAllOpt
        />
      </div>
      {showResetBtn && (
        <button
          type="button"
          onClick={resetFiltersHandler}
          className="comparison__controls-reset"
        >
          Reset Filters
        </button>
      )}
    </div>
  );
}

export default ComparisonControls;
