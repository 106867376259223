import React, { useState } from 'react';
import { List, Popconfirm, Popover } from 'antd';
import '../_qrSetup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

export default function TriDotMenu({ onEdit, onDelete, onDuplicate }) {
  const [open, setOpen] = useState();
  return (
    <Popover
      visible={open}
      onVisibleChange={setOpen}
      content={
        <List bordered>
          <List.Item
            onClick={() => {
              setOpen(false);
              onEdit();
            }}
          >
            Modify
          </List.Item>
          <List.Item
            onClick={() => {
              setOpen(false);
              onDuplicate();
            }}
          >
            Duplicate QR Design
          </List.Item>
          <Popconfirm
            title="Are You Sure"
            description="Do you want to delete this record"
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            onConfirm={() => {
              setOpen(false);
              onDelete();
            }}
          >
            <List.Item>Remove</List.Item>
          </Popconfirm>
        </List>
      }
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayClassName="dotMenu"
    >
      <div className="dotMenu" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      </div>
    </Popover>
  );
}
