import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { PieChart, HorizontalBarChart } from 'components/charts';
import { RatingPerformers } from 'components/ratingPerformers';
import { getQuestionsAnalytics } from 'redux/dashboard/dashboardActions';
import { CHECKBOX, RATING, TEXT } from 'utils/constants/questionTypes';
import { getFilters, markupToText, renameAndOmitKeys } from 'utils/helpers';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { REVIEWS_LISTING_URL } from 'utils/constants/urlConstants';
import { Tooltip } from 'antd';
import { WordCloud } from 'components';

function QuestionAnalytics({ questionUUID, filters }) {
  const dispatch = useDispatch();
  const questionAnalytics = useSelector(
    (state) => state.dashboard.questionAnalytics,
  );
  const question = questionAnalytics[questionUUID];
  const [analytics, setAnalytics] = useState({});
  const navigate = useNavigate();

  const getOptionId = (label) =>
    // eslint-disable-next-line max-len, implicit-arrow-linebreak
    question?.data?.analytics.find(
      (analytic) =>
        markupToText(analytic.label).toLowerCase() === label.toLowerCase(),
    ).option_id;

  const handleOnChartClick = ({ label }) => {
    const filtersKeyMap = {
      'date_range[0]': 'start_date',
      'date_range[1]': 'end_date',
    };
    const filtersKeysToOmit = ['from_web'];
    navigate({
      pathname: REVIEWS_LISTING_URL,
      search: createSearchParams({
        option_id: getOptionId(label),
        type: question.data?.question_type,
        ...renameAndOmitKeys(
          getFilters(filters),
          filtersKeyMap,
          filtersKeysToOmit,
        ),
      }).toString(),
    });
  };

  useEffect(() => {
    dispatch(getQuestionsAnalytics({ questionId: questionUUID, filters }));
  }, [filters]);

  useEffect(() => {
    if (question?.data) {
      const { analytics: qAnalytics, question_type: qType } = question.data;
      if (qAnalytics && qType && qType !== TEXT) {
        setAnalytics(
          qAnalytics
            .map((analytic) => {
              return {
                ...analytic,
                label: analytic?.label?.replace(/&nbsp;/g, ' ').trim(),
              };
            })
            .reduce((acc, i) => {
              acc[markupToText(i.label)] = i.count;
              return acc;
            }, {}),
        );
      }
    }
  }, [question]);

  if (!question || question.isLoading) return <Spinner />;
  if (question.data?.question_type === RATING) {
    return (
      <RatingPerformers
        isQuestion
        questionAnalytics={question?.data?.analytics || []}
        title={markupToText(question?.data?.label)}
      />
    );
  }
  if (question.data?.question_type === TEXT) {
    return (
      <WordCloud
        data={question?.data?.analytics}
        label={question?.data?.label}
      />
    );
  }
  return (
    <div className="chartContainer">
      <Tooltip title={markupToText(question?.data?.label ?? '')}>
        <h3
          className="chart-title"
          dangerouslySetInnerHTML={{ __html: question?.data?.label }}
        />
      </Tooltip>
      {question.data?.question_type === CHECKBOX ? (
        <HorizontalBarChart
          data={analytics}
          label="Responses"
          onClick={handleOnChartClick}
        />
      ) : (
        <PieChart analytics={analytics} small onClick={handleOnChartClick} />
      )}
    </div>
  );
}

export default QuestionAnalytics;
