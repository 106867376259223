import * as Yup from 'yup';
import { customExperienceSchema } from './common';

const experienceTypeSchema = customExperienceSchema('Experience name');

const branchFormSetupSchema = Yup.object().shape({
  forms: Yup.array().of(
    Yup.object().shape({
      experience: experienceTypeSchema,
      survey_id: Yup.string().required('Please select one of the surveys'),
    }),
  ),
});

export default branchFormSetupSchema;
