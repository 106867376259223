import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast } from 'utils/helpers';

import {
  getEntityAPICallV2,
  listEntitySurveyAssignment,
  parentEntityGetAPICall,
  parentEntityTypeAPICall,
  setBranchActivationAPICall,
  updateOrgAPICall,
} from 'apis/cxmeter';
import { toast } from 'react-toastify';
import { setParentEntity } from 'redux/authentication/authenticationSlice';
import _ from 'lodash';
import getLangsAPICall from 'apis/localization';

// eslint-disable-next-line import/prefer-default-export
export const getOrg = createAsyncThunk('settings/getOrg', async (params) =>
  parentEntityGetAPICall(params).catch(showErrorToast),
);

export const updateOrg = createAsyncThunk(
  'settings/updateOrg',
  async (params, { dispatch, getState }) =>
    updateOrgAPICall(params)
      .then((data) => {
        const parentEntity = _.get(
          getState(),
          'authentication.parentEntity',
          {},
        );
        dispatch(setParentEntity({ ...parentEntity, ...data }));
        return data;
      })
      .catch(showErrorToast),
);

export const getBranch = createAsyncThunk(
  'settings/getBranch',
  async (params) => getEntityAPICallV2(params).catch(showErrorToast),
);

export const getBranchAssignments = createAsyncThunk(
  'settings/getBranchAssignments',
  async (params) => listEntitySurveyAssignment(params).catch(showErrorToast),
);

export const changeBranchStatus = createAsyncThunk(
  'settings/changeBranchStatus',
  async (params) =>
    setBranchActivationAPICall(params)
      .then((data) => {
        toast.success('Location has been updated');
        return data;
      })
      .catch((e) => Promise.reject(e.response.data.detail)),
);

export const getLocalization = createAsyncThunk(
  'settings/getLocalization',
  async (params) => getLangsAPICall(params).catch(showErrorToast),
);

export const getIndustryTypes = createAsyncThunk(
  'settings/getIndustryTypes',
  async (params) => parentEntityTypeAPICall(params).catch(showErrorToast),
);
