import { Button, Upload } from 'antd';
import _ from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import React from 'react';
import { ALLOWED_SURVEY_IMAGES_TYPES_STRING } from 'utils/constants';

export default function UploadButton({
  children,
  showUploadList = true,
  onChange,
  ...props
}) {
  const onSelect = (f) => {
    onChange(f);
    return false;
  };

  return (
    <Upload
      {...props}
      beforeUpload={onSelect}
      showUploadList={showUploadList}
      onRemove={() => onChange(null)}
      accept={ALLOWED_SURVEY_IMAGES_TYPES_STRING}
    >
      {_.isEmpty(children) ? (
        <Button icon={<UploadOutlined />}>Upload Custom</Button>
      ) : (
        children
      )}
    </Upload>
  );
}
