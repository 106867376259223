import CustomDropdownButton from 'components/customDropdownButton/CustomDropdownButton';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

/**
 * @author
 * @function BranchList
 * */

function ExpListFilter({
  value,
  disabledItems = {},
  onChange,
  experiences = [],
  hideAllOpt = false,
  placeholder,
  ...rest
}) {
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleChange = (expId) => {
    if (expId === null) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    const val = experiences.find((x) => x.id === expId);
    onChange(val);
  };

  return (
    <div>
      <CustomDropdownButton
        id="experience-list"
        name="experience-name"
        value={value?.id}
        onChange={handleChange}
        title={
          isAllSelected
            ? 'All'
            : value?.name ?? placeholder ?? 'Select Experience'
        }
        {...rest}
      >
        {experiences.map((branch, i) => (
          <Dropdown.Item
            key={i}
            value={branch.id}
            onClick={() => handleChange(branch.id)}
            active={branch.id === value?.id}
            disabled={disabledItems[branch.id]}
          >
            {branch.name}
          </Dropdown.Item>
        ))}

        {!hideAllOpt && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item
              id="all-experiences"
              value={null}
              onClick={() => handleChange(null)}
              active={!value}
            >
              All
            </Dropdown.Item>
          </>
        )}
      </CustomDropdownButton>
    </div>
  );
}

export default ExpListFilter;
