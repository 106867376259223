import { BACKEND_URL } from '../config';

export const AUTHENTICATION_API = `${BACKEND_URL}/api/v1/authentication`;
export const AUTHENTICATION_API_V2 = `${BACKEND_URL}/api/v2/authentication`;
export const LOGIN_API = `${AUTHENTICATION_API}/login/`;
export const LOGIN_API_V2 = `${AUTHENTICATION_API_V2}/login/`;
export const ACTIVATION_API = `${AUTHENTICATION_API_V2}/activate-account/`;
export const SIGNUP_API = `${AUTHENTICATION_API}/signup/`;
export const SIGNUP_API_V2 = `${AUTHENTICATION_API_V2}/signup/`;
export const USER_API_V2 = (id) =>
  id
    ? `${AUTHENTICATION_API_V2}/users/${id}/`
    : `${AUTHENTICATION_API_V2}/users/`;
export const FORGOT_PASSWORD_API = `${AUTHENTICATION_API_V2}/reset-password-token/send/`;
export const RESET_PASSWORD_API = `${AUTHENTICATION_API_V2}/reset-password/`;
export const LOGIN_WITH_GOOGLE_API = `${AUTHENTICATION_API_V2}/signin/google/`;
export const TOKEN_REFRESH_API = `${AUTHENTICATION_API}/token/refresh/`;
export const CHANGE_PASSWORD_API = (id) =>
  `${AUTHENTICATION_API_V2}/users/${id}/change-password/`;

export const CXMETER_API = `${BACKEND_URL}/api/v1/smartsign`;
export const CXMETER_API_V2 = `${BACKEND_URL}/api/v2/smartsign`;
export const MAPBOX_API = `${BACKEND_URL}/api/v1/maps`;
export const SEGMENT_LIST_API = `${CXMETER_API}/segments/`;
export const EXPERIENCE_LIST_API = `${CXMETER_API_V2}/experiences/`;
export const SUBSCRIPTION_PLANS_API = (queryParams) =>
  `${BACKEND_URL}/api/v1/payments/subscription-plans/?${queryParams || ''}`;
export const SUBSCRIPTION_DETAILS_API = `${BACKEND_URL}/api/v1/payments/subscription-details/`;
export const RESUBSCRIPTION_API = `${BACKEND_URL}/api/v1/payments/resubscribe/`;
export const BRANCH_ACTIVATION_API = `${CXMETER_API_V2}/entities/activate/`;
export const ENTITY_EXPERIENCE_LIST_API = `${CXMETER_API}/entities/experiences/`;
export const ENTITY_LINKED_EXPERIENCES_LIST_API = `${CXMETER_API_V2}/entity-experiences/`;
export const ALL_EXPERIENCES = `${CXMETER_API}/experiences/`;
export const LOCATION_LIST_API = `${CXMETER_API}/locations/`;
export const FEEDBACK_STATISTICS_BY_DRIVER_API = `${CXMETER_API}/drivers/feedback-statistics/`;
export const FEEDBACK_STATISTICS_BY_SEGMENT_API = `${CXMETER_API}/segments/feedback-statistics/`;
export const FEEDBACK_STATISTICS_BY_TIMELINE_API = `${CXMETER_API}/feedback-statistics/timeline/`;
export const DRIVER_LOYALTY_AND_CONCERN_API = `${CXMETER_API}/drivers/loyalty-concern/`;
export const FEEDBACK_CUSTOMER_LIST_API = `${CXMETER_API}/feedback-customers/`;
export const FEEDBACK_SURVEY_METRIC_API = `${CXMETER_API}/feedbacks/survey-metrics/`;
export const HAPPINESS_INDEXES_BY_DAY_WEEK_MONTH_API = `${CXMETER_API}/feedbacks/happiness-indexes/`;
export const PARENT_ENTITY_SUB_ENTITY_ADMIN_USER_LIST_API = `${CXMETER_API}/parent-entities/entities/entities-admins/users/`;
export const CITY_LIST_API = `${CXMETER_API}/cities/`;
export const ENTITY_API_V2 = `${CXMETER_API_V2}/entities/`;
export const ENTITY_SURVEY_ASSIGNMENT = (id) =>
  `${CXMETER_API_V2}/entities/${id}/entity-experiences/`;
export const ENTITY_LIST_API_V2 = `${CXMETER_API_V2}/entities/`;
export const ENTITY_SURVEY_CONTENT_V2 = (entityExpId) =>
  `${CXMETER_API_V2}/entity-experiences/${entityExpId}/email-content/`;
export const FEEDBACK_RATING_API = `${CXMETER_API}/feedbacks/ratings/`;
export const USER_ENTITY_ADMINS_CREATE_API = `${CXMETER_API_V2}/users/`;
export const USER_ENTITY_ADMINS_UPDATE_API = `${CXMETER_API}/users/entity-admins/`; // Append id at the end of url
export const PARENT_ENTITY_TYPE_API = `${CXMETER_API}/parent-entities/types/`;
export const PARENT_ENTITY_LIST_API = `${CXMETER_API}/parent-entities/`;
export const PARENT_ENTITY_GET_API = (id) =>
  `${CXMETER_API}/parent-entities/${id}/`;
export const PARENT_ENTITY_ADMIN_CREATE_API = `${CXMETER_API}/parent-entity-admins/`;
export const PARENT_ENTITY_SELECT_API = `${CXMETER_API}/parent-entities/`;
export const ENTITY_EXPERIENCE_QRCODES_CREATE_API = `${CXMETER_API}/entity_experience_qrcodes/`;
export const PARENT_ENTITY_DIGEST_API = `${CXMETER_API}/parent-entities/digest/`;
export const PARENT_ENTITY_API = `${CXMETER_API}/parent-entities/`;
export const LUCKY_DRAW_WINNER_API = `${CXMETER_API}/lucky-draw-winners/`;
export const MAPBOX_PLACES_API = `${MAPBOX_API}/coordinates/`;
export const COUNTRIES_LIST_API_V2 = `${CXMETER_API_V2}/countries/`;
export const STATES_LIST_API_V2 = (countryId) =>
  `${CXMETER_API_V2}/countries/${countryId}/states/`;
export const CITIES_LIST_API_V2 = (stateId) =>
  `${CXMETER_API_V2}/states/${stateId}/cities/`;
export const HELP_ITEM_API_V2 = `${CXMETER_API_V2}/help-items/`;
export const QR_API_V2 = (q = '') => `${CXMETER_API_V2}/qrcodes/${q}`;
export const QR_ALL_PRINT_API = `${CXMETER_API_V2}/qrcodes/pdf/`;

const SURVEY_URL = `${BACKEND_URL}/api/v1/surveys`;
export const ENTITY_SURVEY_API = `${SURVEY_URL}/parent-entity-experience-surveys/`;
export const SURVEY_TEMPLATES_API = `${SURVEY_URL}/experience-surveys/`;
export const ENTITY_SURVEYS_API = `${SURVEY_URL}/experience-surveys/`;
export const SURVEYS_LIST_API = `${SURVEY_URL}/surveys/`;
export const SURVEYS_API = (id) => `${SURVEY_URL}/surveys/${id}/`;
export const SURVEY_DUPLICATE_API = (id) => `${SURVEYS_API(id)}duplicate/`;

const PAYMENTS_URL = `${BACKEND_URL}/api/v1/payments`;
export const COSTS_API = (id) =>
  `${PAYMENTS_URL}/subscription-plans/${id}/cost/`;
export const SUBSCRIBE_API = (id) =>
  `${PAYMENTS_URL}/subscription-plans/${id}/subscribe/`;
export const UPGRADE_PLAN_API = (id) =>
  `${PAYMENTS_URL}/subscription-plans/${id}/upgrade_plan/`;
export const PAYMENT_METHODS_API = `${PAYMENTS_URL}/payment-methods/`;
export const UPDATE_PAYMENT_METHOD_API = (id) =>
  `${PAYMENTS_URL}/payment-methods/${id}/`;
export const SET_DEFAULT_PAYMENT_METHOD_API = (id) =>
  `${PAYMENTS_URL}/payment-methods/${id}/set_default/`;
export const INVOICES_API = `${PAYMENTS_URL}/invoices/`;

const ANALYTICS_URL = `${BACKEND_URL}/api/v1/analytics`;
const ANALYTICS_URL_V2 = `${BACKEND_URL}/api/v2/analytics`;
export const QUICKSTATS_API = `${ANALYTICS_URL}/feedbacks/`;
export const HAPPINESS_OVERTIME_API = `${ANALYTICS_URL}/feedback-trend/`;
export const QUESTION_ANALYTICS_API = (id) =>
  `${ANALYTICS_URL}/questions/${id}/`;
export const RATING_API = `${ANALYTICS_URL}/feedback-ratings/`;
export const HAPPINESS_OVERTIME_BY_ENTITY_API = `${ANALYTICS_URL}/feedback-trend-by-entity/`;
export const BRANCH_COMPARE_API = `${ANALYTICS_URL_V2}/entity-comparison/`;
export const EXPERIENCE_COMPARE_API = `${ANALYTICS_URL}/experience-comparison/`;
export const DATERANGE_COMPARE_API = `${ANALYTICS_URL}/date-comparison/`;

export const CONFIGURATIONS_URL = `${BACKEND_URL}/api/v1/configurations`;
export const CONFIG_WIDGETS_API = `${CONFIGURATIONS_URL}/widgets/`;

export const FEEDBACKS_URL = `${BACKEND_URL}/api/v1/feedbacks`;
export const REVIEWS_LISTING_API = `${FEEDBACKS_URL}/feedbacks/`;
export const REVIEW_NOTE_API = `${FEEDBACKS_URL}/feedback-notes/`;
export const NOTIFICATIONS_API = `${FEEDBACKS_URL}/notifications/`;
export const COMMENTS_API = (id) =>
  `${FEEDBACKS_URL}/comments/?feedback_id=${id}`;

export const LOCALIZATION_URL = `${BACKEND_URL}/api/v1/configurations/languages`;
export const SAAS_AGREEMENT_VERSION_URL = `${CXMETER_API_V2}/saas-agreement/`;

export const PORTAL_FEEDBACK_URL = `${BACKEND_URL}/api/v1/surveys/portal-surveys/`;
export const ENTITY_EXPERIENCE_FEEDBACK_URL = `${BACKEND_URL}/api/v1/feedbacks/entity_experiences`;
export const PORTAL_FEEDBACK_SUBMIT_URL = (id) =>
  `${ENTITY_EXPERIENCE_FEEDBACK_URL}/${id}/feedbacks/`;
export const UPDATE_PORTAL_FEEDBACK_URL = (expId, feedbackId) =>
  `${ENTITY_EXPERIENCE_FEEDBACK_URL}/${expId}/feedbacks/${feedbackId}/`;

export const COUNTRY_LOOKUP_API = 'https://extreme-ip-lookup.com/json/';

export const INVITE_API_URL = `${BACKEND_URL}/api/v1/invites`;
export const INVITES_API = `${INVITE_API_URL}/invites/`;
export const INVITE_REMINDER_API = `${INVITE_API_URL}/invites/send_reminders/`;
export const DELETE_INVITES_API = `${INVITE_API_URL}/invites/delete_records/`;
export const INVITES_BY_ID_API = (id) => `${INVITE_API_URL}/invites/${id}/`;
export const INVITE_GROUPS_API = `${INVITE_API_URL}/invite-groups/`;
export const INVITE_GROUP_BY_ID_API = (id) =>
  `${INVITE_API_URL}/invite-groups/${id}/`;
export const INVITE_SCHEDULE_API = `${INVITE_API_URL}/scheduled-invites/`;
export const INVITE_SCHEDULE_BY_ID_API = (id) =>
  `${INVITE_API_URL}/scheduled-invites/${id}/`;
export const INVITE_TEMPLATE_API = `${INVITE_API_URL}/invite-templates/`;
export const INVITE_TEMPLATE_BY_ID_API = (id) =>
  `${INVITE_API_URL}/invite-templates/${id}/`;
export const DOWNLOAD_CSV_TEMPLATE_API = `${INVITE_API_URL}/invite-groups/download_template_csv/`;
