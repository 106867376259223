/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRatingGraph } from 'redux/dashboard/dashboardActions';
import {
  CandleStickChart,
  HorizontalBarChart,
  LineChart,
} from 'components/charts';
import {
  markupToText,
  renameAndOmitKeys,
  formatDate,
  reviewHelper,
  getFilters,
} from 'utils/helpers';
import { RATING } from 'utils/constants/questionTypes';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { REVIEWS_LISTING_URL } from 'utils/constants/urlConstants';
import { Button, Tooltip } from 'antd';

import './_ratingPerformers.scss';

/**
 * @author
 * @function RatingPerformers
 * */
export function RatingPerformers({
  filters,
  type,
  isQuestion = false,
  questionAnalytics,
  title,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const { getDateRangeBetweenLabels, getEndDate } = reviewHelper;
  const graphs = { BAR: 0, CANDLESTICK: 1, LINE: 2 };
  const [graphType, setGraphType] = useState(graphs.CANDLESTICK);
  const performer = useSelector((state) => state.dashboard.performers[type]);
  let analytics = isQuestion ? questionAnalytics : performer?.data || [];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parseCandleStickData = () =>
    analytics?.reduce((acc, i) => {
      acc.push({
        x: i.counts,
        rating_stats: i.rating_stats,
        y: markupToText(i.label),
      });
      return acc;
    }, []);

  const parseLineChartData = () =>
    analytics.reduce((acc, i) => {
      // eslint-disable-next-line array-callback-return
      Object.entries(i.trend).map(([key, value]) => {
        if (!acc[key]) {
          acc[key] = {};
        }
        acc[key][markupToText(i.label)] = value;
      });
      return acc;
    }, {});

  const parseBarChartData = () =>
    analytics.reduce((acc, i) => {
      acc[markupToText(i.label)] = i.rating_stats.mean;
      return acc;
    }, {});

  const getOptionId = (label) => {
    const question = analytics.find((d) => markupToText(d.label) === label);
    return question.option_id;
  };

  const handleOnChartClick = ({ label, value, datasetLabel }) => {
    const filtersKeyMap = {
      'date_range[0]': 'start_date',
      'date_range[1]': 'end_date',
    };
    const filtersKeysToOmit = ['from_web'];
    const searchParams = { type: RATING };
    if (graphType === graphs.LINE) {
      const dateRange = getDateRangeBetweenLabels(Object.keys(data));
      searchParams.start_date = formatDate(label);
      searchParams.end_date = getEndDate(label, dateRange);
      searchParams.option_id = getOptionId(datasetLabel);
    } else {
      searchParams.option_id = getOptionId(label);
      searchParams.rating = value;
    }

    navigate({
      pathname: REVIEWS_LISTING_URL,
      search: createSearchParams({
        ...renameAndOmitKeys(
          getFilters(filters),
          filtersKeyMap,
          filtersKeysToOmit,
        ),
        ...searchParams,
      }).toString(),
    });
  };

  const tooltipFooter = ([{ dataIndex }]) => {
    return Object.entries(performer?.data[dataIndex].counts ?? {})
      .map(([k, v]) => `${v} rated ${k}`)
      .join('\n');
  };

  useEffect(() => {
    if (filters) dispatch(getRatingGraph({ ...filters, ranking: type }));
  }, [filters]);

  useEffect(() => {
    analytics = analytics.map((analytic) => {
      return {
        ...analytic,
        label: analytic?.label?.replace(/&nbsp;/g, ' ').trim(),
      };
    });

    switch (graphType) {
      case graphs.CANDLESTICK:
        setData(parseCandleStickData());
        break;
      case graphs.BAR:
        setData(parseBarChartData());
        break;
      case graphs.LINE:
        setData(parseLineChartData());
        break;
      default:
        throw Error(`invalid graph type ${graphType}`);
    }
  }, [graphType, analytics]);

  return (
    <div className="ratingGraphContainer chartContainer happinessOverTimeContainer">
      <div className="titleBtnsBlock">
        <Tooltip
          placement="top"
          title={<p dangerouslySetInnerHTML={{ __html: title }} />}
        >
          <h3
            className="title"
            title=""
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Tooltip>
        <ul className="filterBtns">
          <li>
            <span
              onClick={() => setGraphType(graphs.BAR)}
              className={graphType === graphs.BAR ? 'active' : ''}
              tabIndex={0}
              role="button"
            >
              Overall
            </span>
          </li>
          <li>
            <span
              onClick={() => setGraphType(graphs.CANDLESTICK)}
              tabIndex={0}
              role="button"
              className={graphType === graphs.CANDLESTICK ? 'active' : ''}
            >
              Distribution
            </span>
          </li>
          <li>
            <span
              onClick={() => setGraphType(graphs.LINE)}
              tabIndex={0}
              role="button"
              className={graphType === graphs.LINE ? 'active' : ''}
            >
              Trend
            </span>
          </li>
        </ul>
      </div>

      {performer?.isLoading ? (
        <Spinner />
      ) : (
        <div className="pieChartHolder">
          {graphType === graphs.CANDLESTICK && data?.map !== undefined && (
            <CandleStickChart
              showModal={isModalOpen}
              onModalClose={() => setIsModalOpen(false)}
              data={data}
              onClick={handleOnChartClick}
              title={title}
              boxColor="#4285f4"
              xLabel="Performance"
            />
          )}
          {graphType === graphs.LINE && (
            <LineChart
              data={data}
              autoScale={false}
              yScales={{ min: 0, max: 5 }}
              onClick={handleOnChartClick}
              xLabel="Date"
              yLabel="Rating Score"
            />
          )}
          {graphType === graphs.BAR && (
            <HorizontalBarChart
              data={data}
              label="Responses"
              showRatingGradient
              autoScale={false}
              xScales={{ min: 1, max: 5 }}
              onClick={handleOnChartClick}
              xLabel="Rating Score"
              tooltipFooter={tooltipFooter}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default RatingPerformers;
