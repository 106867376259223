import CustomDropdownButton from 'components/customDropdownButton/CustomDropdownButton';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

/**
 * @author
 * @function BranchList
 * */

function BranchListFilter({
  branches = [],
  value,
  disabledItems = {},
  hideAllOpt = false,
  placeholder,
  onChange,
  trimLimit,
  ...rest
}) {
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = (branchId) => {
    const val = branches.find((x) => x.id === branchId);
    onChange(val);
  };

  const getTitle = () => {
    let trimmedValue;
    if (value) {
      if (value.name) trimmedValue = value.name;
    }
    if (Array.isArray(value))
      trimmedValue = value.reduce((acc, item) => `${acc}${item.name}, `, '');
    if (trimmedValue) {
      if (trimLimit && trimmedValue.length > trimLimit) {
        return `${trimmedValue.slice(0, trimLimit)}...`;
      }
      return trimmedValue;
    }
    return selectAll ? 'All' : placeholder ?? 'Select Location';
  };

  return (
    <div>
      <CustomDropdownButton
        id="branch-list"
        name="branch-name"
        value={value?.id}
        onChange={handleChange}
        title={getTitle()}
        {...rest}
      >
        {branches.map((branch, i) => (
          <Dropdown.Item
            key={i}
            value={branch?.id}
            onClick={() => {
              setSelectAll(false);
              handleChange(branch.id);
            }}
            active={branch.id === value?.id}
            disabled={disabledItems[branch.id]}
          >
            {branch.name}
          </Dropdown.Item>
        ))}
        {!hideAllOpt && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item
              id="all-experiences"
              value={null}
              onClick={() => {
                setSelectAll(true);
                handleChange(null);
              }}
              active={!value}
            >
              All
            </Dropdown.Item>
          </>
        )}
      </CustomDropdownButton>
    </div>
  );
}

export default BranchListFilter;
