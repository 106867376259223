import { entityCreateAPICallV2, entityUpdateAPICallV2 } from 'apis/cxmeter';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function useBranchUpdateQuery(branchId, onComplete) {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  return {
    isLoading,
    onSubmit: ({ country, state, ...payload }) => {
      setIsLoading(true);
      (branchId
        ? entityUpdateAPICallV2(branchId, payload)
        : entityCreateAPICallV2(payload)
      )
        .then((res) => {
          toast.success(
            `Location ${branchId ? 'updated' : 'created'} successfully`,
          );
          onComplete?.(res);
        })
        .catch((error) => setErrors(error))
        .finally(() => setIsLoading(false));
    },
    errors,
  };
}
