import {
  formatSurveyQuestion,
  formatSurveySection,
} from 'utils/helpers/surveyHelpers';
import { RATING } from './questionTypes';

const sectionId = 'section-id-initial';
export const INITIAL_QUESTION_ID = 'initial-question';
export const CUSTOMER_DETAIL_FORM_TITLE =
  'Thank you for providing your feedback. Would you like to share your contact information so we can reach out to you for your feedback?';

export const INITIAL_SURVEY = {
  title: '',
  sections: [
    formatSurveySection(sectionId, [
      formatSurveyQuestion(INITIAL_QUESTION_ID, '', RATING),
    ]),
  ],
  is_template: false,
  is_collecting_customer_info: true,
};
