import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import Yup from './Yup';

const EmptyMembersValidation = (values, { createError }) => {
  const { members, members_file: membersFile, groups } = values;
  let isMembersPresent = false;
  isMembersPresent = members.find((m) => m.email);
  if (groups && !isMembersPresent) {
    isMembersPresent = groups.find((g) => g.id);
  }
  if (!isMembersPresent && !membersFile) {
    return createError({
      message: `Please select a member file${
        groups ? ', a group,' : ''
      } or enter an email address.`,
      path: 'members_file',
    });
  }
  return true;
};

const TextEditorValidation = Yup.mixed().test(
  'email-body-validation',
  (body, { createError, path }) => {
    if (!body) return createError({ message: 'Email body is required', path });
    if (!body && typeof body === 'string') {
      return createError({ message: 'Email body is required', path });
    }
    // let bodyHtml = body;
    if (typeof body !== 'string') {
      const bodyPlainText = body.getPlainText();
      if (bodyPlainText.trim() === '') {
        return createError({ message: 'Email body is required', path });
      }
      // eslint-disable-next-line no-underscore-dangle
      // bodyHtml = createMarkup(body).__html;
    }
    // const regex = /<a\s+[^>]*href\s*=\s*["'][^"']*["'][^>]*>(.*?)<\/a>/is; // Regex to check if email body contains at least one valid link.
    // if (!regex.test(bodyHtml)) {
    //   return createError({
    //     message: 'Email body should contains at least one link',
    //     path,
    //   });
    // }

    return true;
  },
);

export const inviteGroupValidationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .requiredTrimmed(
        'Group name cannot be only blank spaces.',
        'Group name is required!',
      )
      .matches(onlyLettersAndSpacesRegex, 'Group name can only contain letters')
      .range(
        2,
        128,
        'Must be at least 2 characters',
        'Must not exceed 128 characters',
      ),
    members: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        }),
      )
      .required('Members are required'),
    members_file: Yup.mixed()
      .nullable() // Allows null or undefined values
      .test(
        'fileType',
        'Only CSV files are allowed',
        (value) => !value || (value && value.type === 'text/csv'),
      ),
  })
  .test('non-empty-members', EmptyMembersValidation);

export const inviteEmailValidationSchema = Yup.object()
  .shape({
    subject: Yup.string()
      .requiredTrimmed(
        'Subject cannot be only blank spaces.',
        'Subject is required!',
      )
      .matches(onlyLettersAndSpacesRegex, 'Subject can only contain letters')
      .range(
        2,
        256,
        'Must be at least 2 characters',
        'Must not exceed 256 characters',
      ),
    members: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        }),
      )
      .required('Members are required'),
    groups: Yup.array(),
    members_file: Yup.mixed()
      .nullable() // Allows null or undefined values
      .test(
        'fileType',
        'Only CSV files are allowed',
        (value) => !value || (value && value.type === 'text/csv'),
      ),
    entity_experience: Yup.mixed()
      .required('Feedback form is required')
      .test(
        'valid-feedback',
        'Feedback form is required',
        (entityExperience) => {
          const id = entityExperience?.id ?? entityExperience;
          return typeof id === 'string' || typeof id === 'number';
        },
      ),
    html_message: TextEditorValidation,
    updateHTMLEditorState: Yup.boolean(),
    show_email_schedule: Yup.boolean(),
    scheduled_at: Yup.object().when('show_email_schedule', {
      is: true,
      then: Yup.object().shape({
        date: Yup.mixed().required('Schedule email date is required'),
        time: Yup.mixed().required('Schedule email time is required'),
      }),
      otherwise: Yup.object().notRequired(),
    }),
    show_reminder_schedule: Yup.boolean(),
    reminder_schedules: Yup.object().when('show_reminder_schedule', {
      is: true,
      then: Yup.object().shape({
        reminder_type: Yup.string()
          .required('Reminder type is required')
          .oneOf(
            [
              'Daily',
              'Weekly',
              'Monthly',
              'Annually',
              'Every Weekday',
              'Custom',
            ],
            'Invalid reminder type',
          ),

        reminder_time: Yup.mixed().required('Reminder time is required'),
        custom_reminder_day: Yup.array()
          .of(
            Yup.string().oneOf(
              [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ],
              'Invalid day',
            ),
          )
          .when('reminder_type', {
            is: 'Custom',
            then: Yup.array()
              .min(1, 'At least one day is required for Custom reminder type')
              .required('Custom reminder days are required'),
            otherwise: Yup.array(),
          }),
        ends_at: Yup.mixed().required('End date is required'),
      }),
      otherwise: Yup.object().notRequired(),
    }),
  })
  .test('non-empty-members', EmptyMembersValidation);

export const inviteTemplateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Group name cannot be only blank spaces.',
      'Group name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'Group name can only contain letters')
    .range(
      2,
      128,
      'Must be at least 2 characters',
      'Must not exceed 128 characters',
    ),
  entity_experience: Yup.mixed()
    .required('Feedback form is required')
    .test('valid-feedback', 'Feedback form is required', (entityExperience) => {
      const id = entityExperience?.id ?? entityExperience;
      return typeof id === 'string' || typeof id === 'number';
    }),
  html_message: TextEditorValidation,
});
