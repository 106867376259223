import { createSlice } from '@reduxjs/toolkit';
import { storeParentEntity } from 'utils/helpers';
import {
  changeBranchStatus,
  getBranch,
  getBranchAssignments,
  getIndustryTypes,
  getLocalization,
  getOrg,
  updateOrg,
} from './settingsActions';

const INITIAL_STATE = {
  isLoading: false,
  org: {},
  industryTypes: {
    isLoading: false,
    types: [],
  },
  branch: {},
  branchAssignments: [],
  localization: {
    isLoading: false,
    data: [],
  },
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getOrg.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrg.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOrg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.org = action.payload;
    });
    builder.addCase(updateOrg.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrg.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateOrg.fulfilled, (state, action) => {
      state.isLoading = false;
      storeParentEntity({ ...action.payload });
    });
    builder.addCase(changeBranchStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeBranchStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeBranchStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranch.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.branch = action.payload;
    });
    builder.addCase(getBranchAssignments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranchAssignments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBranchAssignments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.branchAssignments = action.payload;
    });
    builder.addCase(getLocalization.pending, (state) => {
      state.localization.isLoading = true;
    });
    builder.addCase(getLocalization.rejected, (state) => {
      state.localization.isLoading = false;
    });
    builder.addCase(getLocalization.fulfilled, (state, action) => {
      state.localization.isLoading = false;
      state.localization.data = action.payload;
    });
    builder.addCase(getIndustryTypes.pending, (state) => {
      state.industryTypes.isLoading = true;
    });
    builder.addCase(getIndustryTypes.rejected, (state) => {
      state.industryTypes.isLoading = false;
    });
    builder.addCase(getIndustryTypes.fulfilled, (state, action) => {
      state.industryTypes.isLoading = false;
      const types = action.payload ?? [];
      state.industryTypes.types = types.map((t) => ({
        label: t.name,
        value: t.id,
      }));
    });

    return builder;
  },
});
export default settingsSlice;
