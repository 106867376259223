import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, Outlet } from 'react-router-dom';
import { SETTINGS, URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import SettingTabs from 'components/settingTabs/SettingTabs';

function EditProfileTabs() {
  const location = useLocation();

  usePageTitle(SETTINGS);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, []);

  return (
    <SettingTabs>
      <SettingTabs.Menu>
        <SettingTabs.Item
          title="Edit Profile"
          to={URLS.EDIT_USER_PROFILE_URL}
        />
        <SettingTabs.Item title="Change Password" to={URLS.CHANGE_PASSWORD} />
      </SettingTabs.Menu>
      <SettingTabs.Content>
        <Outlet />
      </SettingTabs.Content>
    </SettingTabs>
  );
}

export default EditProfileTabs;
