import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getInviteById,
  getInvites,
  getInviteGroupById,
  getInviteGroups,
  getInviteTemplates,
  getInviteTemplateById,
  deleteInviteTemplateById,
  getInviteTemplatesForEmailModal,
  getInviteGroupsForEmailModal,
  getInviteSchedules,
  getInviteScheduleById,
  deleteInviteScheduleById,
  deletePendingInviteById,
  sendInviteReminderById,
} from './emailManagementActions';
import { PAGINATED_RESPONSE } from 'utils/constants';

const SEND_INVITE_REMINDER = 'send-invite-reminder';
const DELETE_PENDING_INVITE = 'delete-pending-invite';

const TABLE_DATA = {
  data: PAGINATED_RESPONSE,
  loading: false,
};
const SINGLE_ITEM_DATA = {
  data: null,
  loading: false,
};

const INITIAL_STATE = {
  invites: TABLE_DATA,
  invite: SINGLE_ITEM_DATA,
  inviteGroups: TABLE_DATA,
  emailModalInviteGroups: TABLE_DATA,
  inviteGroup: SINGLE_ITEM_DATA,
  inviteSchedules: TABLE_DATA,
  inviteSchedule: SINGLE_ITEM_DATA,
  inviteTemplates: TABLE_DATA,
  inviteTemplate: SINGLE_ITEM_DATA,
  emailModalInviteTemplates: TABLE_DATA,
  pendingInvitesLoading: {
    isMultiple: false,
    reminderLoading: {},
    deleteLoading: {},
  },
  deleteEmailItems: {
    loading: {},
  },
};

const pendingInvitesLoadingHandler =
  (showLoading, loadingType) =>
  (state, { meta: { arg } }) => {
    const { invite_record_ids: itemsIds } = arg;
    const newLoadings = {};
    itemsIds.forEach((id) => {
      newLoadings[id] = showLoading;
    });
    if (loadingType === SEND_INVITE_REMINDER) {
      state.pendingInvitesLoading.reminderLoading = {
        ...state.pendingInvitesLoading.reminderLoading,
        ...newLoadings,
      };
    } else if (loadingType === DELETE_PENDING_INVITE) {
      state.pendingInvitesLoading.deleteLoading = {
        ...state.pendingInvitesLoading.deleteLoading,
        ...newLoadings,
      };
    }
    state.pendingInvitesLoading.isMultiple = showLoading
      ? itemsIds.length > 1
      : false;
  };

const deleteItemLoadingHandler =
  (showLoading) =>
  (state, { meta: { arg } }) => {
    const { itemId, prefix } = arg;
    state.deleteEmailItems.loading = {
      ...state.deleteEmailItems.loading,
      [`${prefix}-${itemId}`]: showLoading,
    };
  };

const emailManagementSlice = createSlice({
  name: 'emailManagementSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetEmailModalInviteTemplates: (state) => {
      state.emailModalInviteTemplates = TABLE_DATA;
    },
    resetEmailModalInviteGroups: (state) => {
      state.emailModalInviteGroups = TABLE_DATA;
    },
    resetInviteSchedule: (state) => {
      state.inviteSchedule = SINGLE_ITEM_DATA;
    },
  },
  extraReducers: (builder) => {
    // INVITES
    builder.addCase(getInvites.pending, (state) => {
      state.invites.loading = true;
    });
    builder.addCase(getInvites.rejected, (state) => {
      state.invites.loading = false;
    });
    builder.addCase(getInvites.fulfilled, (state, action) => {
      state.invites.loading = false;
      state.invites.data = action.payload;
    });
    builder.addCase(getInviteById.pending, (state) => {
      state.invite.loading = true;
    });
    builder.addCase(getInviteById.rejected, (state) => {
      state.invite.loading = false;
    });
    builder.addCase(getInviteById.fulfilled, (state, action) => {
      state.invite.loading = false;
      state.invite.data = action.payload;
    });
    // INVITES GROUPS
    builder.addCase(getInviteGroups.pending, (state) => {
      state.inviteGroups.loading = true;
    });
    builder.addCase(getInviteGroups.rejected, (state) => {
      state.inviteGroups.loading = false;
    });
    builder.addCase(getInviteGroups.fulfilled, (state, action) => {
      state.inviteGroups.loading = false;
      state.inviteGroups.data = action.payload;
    });
    builder.addCase(getInviteGroupsForEmailModal.pending, (state) => {
      state.emailModalInviteGroups.loading = true;
    });
    builder.addCase(getInviteGroupsForEmailModal.rejected, (state) => {
      state.emailModalInviteGroups.loading = false;
    });
    builder.addCase(
      getInviteGroupsForEmailModal.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.emailModalInviteGroups.loading = false;
        const { page } = arg ?? {};
        // If it is first Page
        if (!page || page < 2) {
          state.emailModalInviteGroups.data = payload;
        } else {
          const prevResult = [...state.emailModalInviteGroups.data.results];
          state.emailModalInviteGroups.data = {
            ...payload,
            results: [...prevResult, ...payload.results],
          };
        }
      },
    );
    builder.addCase(getInviteGroupById.pending, (state) => {
      state.inviteGroup.loading = true;
    });
    builder.addCase(getInviteGroupById.rejected, (state) => {
      state.inviteGroup.loading = false;
    });
    builder.addCase(getInviteGroupById.fulfilled, (state, action) => {
      state.inviteGroup.loading = false;
      state.inviteGroup.data = action.payload;
    });

    // INVITES SCHEDULES
    builder.addCase(getInviteSchedules.pending, (state) => {
      state.inviteSchedules.loading = true;
    });
    builder.addCase(getInviteSchedules.rejected, (state) => {
      state.inviteSchedules.loading = false;
    });
    builder.addCase(getInviteSchedules.fulfilled, (state, action) => {
      state.inviteSchedules.loading = false;
      state.inviteSchedules.data = action.payload;
    });
    builder.addCase(getInviteScheduleById.pending, (state) => {
      state.inviteSchedule.loading = true;
    });
    builder.addCase(getInviteScheduleById.rejected, (state) => {
      state.inviteSchedule.loading = false;
    });
    builder.addCase(getInviteScheduleById.fulfilled, (state, action) => {
      state.inviteSchedule.loading = false;
      state.inviteSchedule.data = action.payload;
    });

    // INVITES TEMPLATES
    builder.addCase(getInviteTemplates.pending, (state) => {
      state.inviteTemplates.loading = true;
    });
    builder.addCase(getInviteTemplates.rejected, (state) => {
      state.inviteTemplates.loading = false;
    });
    builder.addCase(getInviteTemplates.fulfilled, (state, action) => {
      state.inviteTemplates.loading = false;
      state.inviteTemplates.data = action.payload;
    });
    builder.addCase(getInviteTemplateById.pending, (state) => {
      state.inviteTemplate.loading = true;
    });
    builder.addCase(getInviteTemplateById.rejected, (state) => {
      state.inviteTemplate.loading = false;
    });
    builder.addCase(getInviteTemplateById.fulfilled, (state, action) => {
      state.inviteTemplate.loading = false;
      state.inviteTemplate.data = action.payload;
    });

    // INVITE TEMPLATE FOR EMAIL MODAL LISTING
    builder.addCase(getInviteTemplatesForEmailModal.pending, (state) => {
      state.emailModalInviteTemplates.loading = true;
    });
    builder.addCase(getInviteTemplatesForEmailModal.rejected, (state) => {
      state.emailModalInviteTemplates.loading = false;
    });
    builder.addCase(
      getInviteTemplatesForEmailModal.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.emailModalInviteTemplates.loading = false;
        const { page } = arg ?? {};
        // If it is first Page
        if (!page || page < 2) {
          state.emailModalInviteTemplates.data = payload;
        } else {
          const prevResult = [...state.emailModalInviteTemplates.data.results];
          state.emailModalInviteTemplates.data = {
            ...payload,
            results: [...prevResult, ...payload.results],
          };
        }
      },
    );

    builder.addCase(
      sendInviteReminderById.pending,
      pendingInvitesLoadingHandler(true, SEND_INVITE_REMINDER),
    );
    builder.addCase(
      sendInviteReminderById.rejected,
      pendingInvitesLoadingHandler(false, SEND_INVITE_REMINDER),
    );
    builder.addCase(
      sendInviteReminderById.fulfilled,
      pendingInvitesLoadingHandler(false, SEND_INVITE_REMINDER),
    );

    // Email Mangement Delete APIs Loading
    builder.addCase(
      deletePendingInviteById.pending,
      pendingInvitesLoadingHandler(true, DELETE_PENDING_INVITE),
    );
    builder.addCase(
      deletePendingInviteById.rejected,
      pendingInvitesLoadingHandler(false, DELETE_PENDING_INVITE),
    );
    builder.addCase(
      deletePendingInviteById.fulfilled,
      pendingInvitesLoadingHandler(false, DELETE_PENDING_INVITE),
    );

    builder.addMatcher(
      isAnyOf(
        deleteInviteTemplateById.pending,
        deleteInviteScheduleById.pending,
        deletePendingInviteById.pending,
      ),
      deleteItemLoadingHandler(true),
    );
    builder.addMatcher(
      isAnyOf(
        deleteInviteTemplateById.rejected,
        deleteInviteScheduleById.rejected,
        deletePendingInviteById.rejected,
      ),
      deleteItemLoadingHandler(false),
    );
    builder.addMatcher(
      isAnyOf(
        deleteInviteTemplateById.fulfilled,
        deleteInviteScheduleById.fulfilled,
        deletePendingInviteById.fulfilled,
      ),
      deleteItemLoadingHandler(false),
    );
    return builder;
  },
});

export const {
  resetEmailModalInviteTemplates,
  resetEmailModalInviteGroups,
  resetInviteSchedule,
} = emailManagementSlice.actions;
export default emailManagementSlice;
