import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStates } from 'redux/branchComparison/branchComparisonActions';
import { Select } from 'components/ui';

export default function StateDropDown({
  onChange,
  value,
  countryId,
  error,
  placeholder,
}) {
  const dispatch = useDispatch();
  const { data: states, isLoading } = useSelector(
    (state) => state.branchComparison.states,
  );

  useEffect(() => {
    if (countryId) {
      dispatch(getStates(countryId));
    }
  }, [countryId]);

  return (
    <Select
      name="state"
      isSearchable
      placeholder={placeholder ?? 'Select State'}
      options={states.map((state) => ({ label: state.name, value: state.id }))}
      onChange={onChange}
      value={value}
      isLoading={isLoading}
      error={error}
      disabled={states.length === 0}
    />
  );
}
