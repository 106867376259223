import React, { useEffect } from 'react';
import { LocationThumbnail } from 'components/locationThumbnail';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches } from 'redux/filters/filterActions';

import './_locationSelector.scss';

export default function LocationSelector({ onChange, value }) {
  const disptach = useDispatch();
  const { branchList } = useSelector((state) => state.filters);

  useEffect(() => {
    disptach(getBranches({ extra_data: true, with_experiences: true }));
  }, []);

  return (
    <div className="location-selector__container">
      {branchList.map((branch) => (
        <LocationThumbnail
          selectOnMount
          selected={value?.id === branch.id}
          key={branch.id}
          onSelect={() => onChange(branch)}
        >
          {branch.name}
        </LocationThumbnail>
      ))}
    </div>
  );
}
