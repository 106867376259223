import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postPortalFeedbackForm,
  updatePortalFeedbackForm,
} from 'redux/portalFeedback/portalFeedbackActions';
import { formatTime } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';

export default function useSubmitFeedback() {
  const dispatch = useDispatch();
  const startTime = useRef(null);
  const expId = useRef(null);
  const segmentId = useRef(null);
  const [uuid] = useState(uuidv4()); // ensures uuid does not change until the component is mounted
  const { user } = useSelector((state) => state.authentication);
  const { submittedFormId } = useSelector((state) => state.portalFeedback);

  const setStartTime = () => {
    startTime.current = new Date();
  };

  const setExpId = (id) => {
    expId.current = id;
  };

  const submitFeedback = (segment, feedback) => {
    segmentId.current = segment;
    const now = new Date();
    const feedbackTime = Math.abs(now - startTime.current);
    const formData = new FormData();
    formData.append('entity_experience', expId.current);
    formData.append('feedback_time', formatTime(feedbackTime));
    formData.append('segment', segment);
    formData.append('uuid', uuid);
    feedback.forEach(({ key, value }) => {
      formData.append(key, value);
    });
    return dispatch(
      postPortalFeedbackForm({
        expId: expId.current,
        formData,
      }),
    );
  };

  useEffect(() => {
    if (submittedFormId) {
      const formData = new FormData();
      formData.append('name', `${user.first_name} ${user.last_name}`);
      formData.append('email', user.email);
      formData.append('uuid', uuid);
      formData.append('segment', segmentId.current);
      formData.append('entity_experience', expId.current);
      dispatch(
        updatePortalFeedbackForm({
          expId: expId.current,
          feedbackId: submittedFormId,
          data: formData,
        }),
      );
    }
  }, [submittedFormId]);
  return { submitFeedback, setExpId, setStartTime };
}
