/* eslint-disable react/no-array-index-key */
import React from 'react';

import './_stepper.scss';
import Icon from 'components/ui/icon/Icon';

export default function Stepper({ steps, selectedStep }) {
  const selectedStepIndex = steps.findIndex(
    (o) => o.name === selectedStep.name,
  );

  return (
    <ul className="stepper">
      {steps.map((step, i) => {
        return (
          <li
            key={step.name}
            className={`stepper-step ${
              i < selectedStepIndex ? 'stepper-done' : ''
            } ${step.name === selectedStep.name ? 'stepper-current' : ''}`}
          >
            {i < selectedStepIndex ? (
              <Icon type="done" />
            ) : (
              <span>{i + 1}</span>
            )}
            <p>{step.name}</p>
          </li>
        );
      })}
    </ul>
  );
}
