import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Filters } from 'components/filters';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { Table } from 'antd';
import { Icon } from 'components/ui';
import { Spinner } from 'react-bootstrap';
import { getUserList } from 'redux/users/usersActions';
import { getLocationsV2 } from 'redux/branchComparison/branchComparisonActions';
import getUserColumns from './columns';
import UserModal from './userModal/UserModal';
import './_userPage.scss';
import _ from 'lodash';

function UserPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userList } = useSelector((state) => state.users);
  const { locations } = useSelector((state) => state.branchComparison);
  const loggedInUser = useSelector((state) => state.authentication.user);

  const [params, setParams] = useState({
    page: 1,
    size: 10,
    sort_by: 'created_at',
    sort_order: 'ascend',
  });
  const [filterStore, setFilterStore] = useState({
    [SEARCH_FILTER]: null,
  });
  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({});

  useEffect(() => {
    dispatch(getLocationsV2());
  }, []);

  useEffect(() => {
    dispatch(getUserList({ ...params, ...filterStore }));
  }, [params, filterStore]);

  const openModalToEditUser = (user) => {
    setEditingUser(user ?? {});
    setNewUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setNewUserModalOpen(false);
    setEditingUser({});
  };

  const onChange = (pagination, filters, sorter) => {
    if (!_.isEmpty(sorter)) {
      setParams({
        ...params,
        page: pagination.current,
        sort_by: sorter.field,
        sort_order: sorter.order,
      });
    }
  };

  const user = useMemo(
    () => ({
      ...editingUser,
      entities: editingUser.entities?.map(({ id }) => id) ?? [],
    }),
    [editingUser],
  );

  return (
    <>
      <section className="dataHolder">
        <div className="filterContainer">
          <Filters value={filterStore} onChange={setFilterStore} />
          <div>
            <button
              className="button button-primary add-user"
              type="button"
              onClick={() => openModalToEditUser()}
            >
              <Icon type="person_add" />
              {t('settings.users.addUser')}
            </button>
          </div>
        </div>
      </section>
      <Table
        loading={userList.isLoading && { indicator: <Spinner /> }}
        columns={getUserColumns({
          onEdit: openModalToEditUser,
          loggedInUser,
          t,
        })}
        dataSource={userList.data}
        onChange={onChange}
        scroll={{ x: true }}
        pagination={{
          total: userList.count,
          defaultPageSize: 10,
          onChange: (page, pageSize) => setParams({ page, size: pageSize }),
        }}
      />
      <UserModal
        open={newUserModalOpen}
        locations={locations.data}
        handleModalClose={handleUserModalClose}
        user={user}
      />
    </>
  );
}

export default UserPage;
