import {
  BRANCH_FILTER,
  EXPERIENCE_FILTER,
} from 'components/filters/filterTypes';

export const COMPARISON_METHODS = [
  { id: BRANCH_FILTER, name: 'Location' },
  { id: EXPERIENCE_FILTER, name: 'Experience' },
];

export const PERCENTAGE_PREVIEW = 'percentage';
export const VALUES_PREVIEW = 'values';
export const BOTH_PREVIEW = 'both';

export const COMPARISON_PREVIEWS = [
  { id: VALUES_PREVIEW, name: 'Values' },
  {
    id: PERCENTAGE_PREVIEW,
    name: '%',
  },
  {
    id: BOTH_PREVIEW,
    name: 'Both(Val, %)',
  },
];
