/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './_branchCompare.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components';
import { isEmpty } from 'lodash';
import NoDataSection from './NoDataSection';
import parseComparison, {
  getComparisonParams,
} from 'utils/helpers/branchComparisonHelper';
import { useReactToPrint } from 'react-to-print';
import ComparisonSection from './ComparisonSection';
import {
  COMPARISON_METHODS,
  COMPARISON_PREVIEWS,
} from 'utils/constants/compareFeedbacks';
import NonSelectedComparisonFilters from './NonSelectedComparisonFilters';
import SelectedComparisonFilters from './SelectedComparisonFilters';
import { Icon } from 'components/ui';
import { setBranchComparison } from 'redux/branchComparison/branchComparisonSlice';
import dayjs from 'dayjs';

function BranchCompare() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reportRef = useRef();
  const locationBanner = useRef();
  const { parentEntity } = useSelector((state) => state.authentication);
  const { isLoading, comparison, locations } = useSelector(
    (state) => state.branchComparison,
  );

  const [comparisonMethod, setComparisonMethod] = useState(
    COMPARISON_METHODS[0],
  );
  const [comparisonPreview, setComparisonPreview] = useState(
    COMPARISON_PREVIEWS[0],
  );
  const [selectedComparisonFilters, setSelectedComparisonFilters] = useState([
    {},
    {},
  ]);
  const [selectedComparison, setSelectedComparison] = useState([]);
  const [comparisonParams, setComparisonParams] = useState({});

  useEffect(() => {
    if (isEmpty(comparison)) {
      setSelectedComparison({});
    } else {
      setSelectedComparison(parseComparison(comparison, comparisonMethod, t));
    }
  }, [comparison]);

  // To Clear the Old Comparison data when Component Unmount(Page got Changed)
  useEffect(
    () => () => {
      dispatch(setBranchComparison({}));
    },
    [],
  );

  const printTable = useReactToPrint({
    content: () => reportRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${parentEntity.name} comparison_${dayjs().format(
      'YYYYMMDD',
    )}`,
  });

  const handlePrint = () => {
    locationBanner.current.style.top = '0';
    printTable();
    locationBanner.current.style.top = '5rem';
  };

  const getBranchComparisonHandler = (comparisonFilters, otherFilters) => {
    const isValidFilters =
      comparisonFilters.filter((filter) => !isEmpty(filter)).length > 1;
    if (isValidFilters) {
      const { params, comparisonAction } = getComparisonParams(
        comparisonFilters,
        otherFilters,
        comparisonMethod,
      );
      dispatch(comparisonAction(params));
    }
  };

  const addSelectedComparisonFilters = () => {
    setSelectedComparisonFilters((p) => [...p, {}]);
  };

  const onChangeComparisonMethod = (method) => {
    if (method.id === comparisonMethod.id) return;
    setComparisonMethod(method);
    setSelectedComparisonFilters([{}, {}]);
    setSelectedComparison({});
  };

  const onChangeSelectedComparisonFilter = (newFilters) => {
    setSelectedComparisonFilters(newFilters);
    getBranchComparisonHandler(newFilters, comparisonParams);
  };

  const onChangeComparisonParams = (otherComparisonFilters) => {
    setComparisonParams(otherComparisonFilters);
    getBranchComparisonHandler(
      selectedComparisonFilters,
      otherComparisonFilters,
    );
  };

  return (
    <div className="comparison__container" ref={reportRef}>
      <div className="comparison__filters-container">
        <div className="comparison__header">
          <h2 className="m-0">Compare</h2>
          <button
            onClick={handlePrint}
            className="button comparison__print font-body"
            type="button"
          >
            <Icon type="print" />
            <span>{t('locationComparison.print')}</span>
          </button>{' '}
        </div>
        <div className="comparison__filters">
          <NonSelectedComparisonFilters
            comparisonMethod={comparisonMethod}
            onChangeComparisonMethod={onChangeComparisonMethod}
            comparisonPreview={comparisonPreview}
            setComparisonPreview={setComparisonPreview}
            onChangeComparisonParams={onChangeComparisonParams}
          />
        </div>
      </div>
      <div className="comparison__results-container">
        <div className="comparison__results">
          <div
            style={{
              gridTemplateColumns: `minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) ${
                selectedComparisonFilters.length > 2
                  ? 'minmax(100px, 1fr)'
                  : '40px'
              }`,
            }}
            className="results-header comparison__banner--sticky MainFilterWrap"
            ref={locationBanner}
          >
            <div>
              <h4>{comparisonMethod.name}s</h4>
              <p>Choose what you would like to compare</p>
            </div>
            <SelectedComparisonFilters
              selectedComparisonFilters={selectedComparisonFilters}
              onChangeSelectedComparisonFilter={
                onChangeSelectedComparisonFilter
              }
              addSelectedComparisonFilters={addSelectedComparisonFilters}
              comparisonMethod={comparisonMethod}
            />
          </div>
          <div>
            {isLoading || locations.isLoading ? (
              <div>
                <Loader />
              </div>
            ) : isEmpty(selectedComparison) ? (
              <NoDataSection />
            ) : (
              <div className="comparison__table">
                {selectedComparison.map((section, idx) => {
                  return (
                    <ComparisonSection
                      selectedComparisonFilters={
                        selectedComparisonFilters.length
                      }
                      previewType={comparisonPreview}
                      title={section.title}
                      data={section.data}
                      key={idx}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BranchCompare;
