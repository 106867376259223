import { useDispatch, useSelector } from 'react-redux';
import PendingInbox from './PendingInbox';
import ReceivedInbox from './ReceivedInbox';
import { useContext, useEffect } from 'react';
import { getInviteById } from 'redux/emailManagement/emailManagementActions';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { SEARCH_FILTER } from 'components/filters/filterTypes';

function InboxSubTabs({ activeSubTab, selectedRecord }) {
  const dispatch = useDispatch();
  const { subTabsSearch } = useContext(EmailManagementFiltersContext);
  const searchParams = subTabsSearch[SEARCH_FILTER];
  const {
    invite: { data: invite, loading },
  } = useSelector((s) => s.emailManagement);

  useEffect(() => {
    if (selectedRecord?.id)
      dispatch(
        getInviteById({
          id: selectedRecord.id,
          params: { search: searchParams },
        }),
      );
  }, [searchParams]);

  return (
    <>
      {activeSubTab.id === 'receive' && (
        <ReceivedInbox loading={loading} data={invite ?? {}} />
      )}
      {activeSubTab.id === 'pending' && (
        <PendingInbox
          loading={loading}
          inviteId={selectedRecord.id}
          searchParams={searchParams}
          data={invite ?? {}}
        />
      )}
    </>
  );
}

export default InboxSubTabs;
