import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'antd';
import './_surveyBuilderSection.scss';
import { SurveyBuilderQuestion } from '..';

function SurveyBuilderSection({
  id,
  surveyQuestions,
  isDeletable = true,
  setSortedQuestions,
  selectedSection,
  setSelectedSection,
  handleAddSection,
  handleRemoveSection,
  setQuestionsEdited,
  setIsSimilarErrorActive,
  duplicateOptionsSections,
  formik,
}) {
  const [questions, setQuestions] = useState(surveyQuestions);
  const [hasRatingQuestion, setHasRatingQuestion] = useState(false);

  const checkHasRatingQuestion = () => {
    const hasRating = questions.some(
      (question) => question.question_type === 'rating' && question.is_required,
    );
    setHasRatingQuestion(hasRating);
  };

  useEffect(() => {
    checkHasRatingQuestion(surveyQuestions);
  }, [questions]);

  useEffect(() => {
    setQuestions(surveyQuestions);
  }, [surveyQuestions]);

  const handleSetQuestion = (question) => {
    const idx = surveyQuestions.findIndex((q) => q.id === question.id);
    const temp = [...surveyQuestions];
    temp[idx] = question;
    setSortedQuestions(id, temp);
  };

  const handleSetSelectedSection = () => {
    setSelectedSection(id);
  };

  const sectionsErrors = formik.errors.sections;
  const sectionHasDuplicateError =
    sectionsErrors?.[id] || duplicateOptionsSections.current[id];

  return (
    <div className="sectionWrapper">
      <section
        className={`surveyBuilderSection ${
          selectedSection === id && 'selectedSurveyBuilderSection'
        } ${sectionHasDuplicateError ? 'invalidSurveyBuilderSection' : ''}`}
        tabIndex={0}
        role="button"
        onClick={handleSetSelectedSection}
      >
        {questions.map((question, i) => (
          <SurveyBuilderQuestion
            sectionId={id}
            key={question.id}
            question={question}
            isDeletable={isDeletable}
            setQuestion={handleSetQuestion}
            setQuestionsEdited={setQuestionsEdited}
            setIsSimilarErrorActive={setIsSimilarErrorActive}
            questionErrors={sectionsErrors?.[id]?.questions?.[i]}
            duplicateOptionsSections={duplicateOptionsSections}
          />
        ))}
      </section>
      <div className="surveySectionActions">
        <FontAwesomeIcon
          className="surveySectionActionItem"
          icon={faPlus}
          onClick={handleAddSection}
        />
        <Tooltip
          title={
            !isDeletable && hasRatingQuestion
              ? 'Sorry cannot delete this rating question as one rating question is required.'
              : ''
          }
        >
          <FontAwesomeIcon
            className={`surveySectionActionItem ${
              !isDeletable && hasRatingQuestion && 'disabled'
            }`}
            icon={faTrashAlt}
            onClick={
              !isDeletable && hasRatingQuestion ? null : handleRemoveSection
            }
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default SurveyBuilderSection;
