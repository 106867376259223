import { useDispatch } from 'react-redux';
import { populateFormikErrors } from 'utils/helpers';

const useDispatchWithErrors = () => {
  const dispatch = useDispatch();

  const dispatchAction = async (action, formik) => {
    const { error, payload, meta } = await dispatch(action);
    const { data } = payload?.response ?? {};
    if (error && data && formik) {
      populateFormikErrors(data, formik);
    }
    return { isError: Boolean(error), data: payload, meta };
  };

  return dispatchAction;
};

export default useDispatchWithErrors;
