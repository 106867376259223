import { useCallback, useMemo, useRef, useState } from 'react';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { EMAIL_MANAGEMENT_PAGINATION } from 'utils/constants/manageEmails';

function ManageEmailsLayout({ children }) {
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: '',
  });
  const [params, setParams] = useState({
    ...EMAIL_MANAGEMENT_PAGINATION,
    search: '',
  });

  const [subTabsSearch, setSubTabsSearch] = useState({
    [SEARCH_FILTER]: '',
  });

  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const inviteEmailDataRef = useRef();

  const [isOpenGroupModal, setIsOpenGroupModal] = useState(false);
  const inviteGroupIdRef = useRef();
  const [isOpenInviteTemplateModal, setIsOpenInviteTemplateModal] =
    useState(false);
  const inviteTemplateIdRef = useRef();

  // To sync the search params key with Backend requirement.
  const onChangeSearch = useCallback(
    (searchValue) => {
      const search = searchValue[SEARCH_FILTER];
      setParams((p) => ({ ...p, page: 1, search }));
      setSearchFilter(searchValue);
    },
    [setSearchFilter],
  );

  const onChangePagination = useCallback(
    (page, pageSize) => {
      setParams((p) => ({ ...p, page, page_size: pageSize }));
    },
    [setParams],
  );

  const contextValues = useMemo(
    () => ({
      searchFilter,
      setSearchFilter: onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      isOpenSendEmailModal,
      setIsOpenSendEmailModal,
      inviteEmailDataRef,
      isOpenGroupModal,
      setIsOpenGroupModal,
      inviteGroupIdRef,
      isOpenInviteTemplateModal,
      setIsOpenInviteTemplateModal,
      inviteTemplateIdRef,
    }),
    [
      searchFilter,
      onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      isOpenSendEmailModal,
      setIsOpenSendEmailModal,
      inviteEmailDataRef,
      isOpenGroupModal,
      setIsOpenGroupModal,
      inviteGroupIdRef,
      isOpenInviteTemplateModal,
      setIsOpenInviteTemplateModal,
      inviteTemplateIdRef,
    ],
  );

  return (
    <EmailManagementFiltersContext.Provider value={contextValues}>
      {children}
    </EmailManagementFiltersContext.Provider>
  );
}

export default ManageEmailsLayout;
