/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Loader from 'components/loader/Loader';
import _ from 'lodash';
import { entityListAPICallV2, experienceListAPICall } from 'apis/cxmeter';
import ExpListFilter from './ExpListFilter';
import BranchListFilter from './BranchListFilter';

import './_branchExpFilter.scss';

/**
 * @author
 * @function BranchList
 * */

function BranchExpFilter({ onChange, value }) {
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [allExperiences, setAllExperiences] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    Promise.allSettled([
      entityListAPICallV2({ is_active: true, with_experiences: true }),
      experienceListAPICall(),
    ])
      .then(([{ value: response }, { value: experiences }]) => {
        setBranches(response ?? []);
        setAllExperiences(experiences ?? []);
      })
      .finally(setLoading(false));
  };

  const onBranchChange = (branch) => {
    setSelectedBranch(branch);
    setSelectedExperience(null);
    if (onChange) onChange({ branch, experience: null });
  };

  const onExperienceChange = (experience) => {
    setSelectedExperience(experience);
    if (onChange) onChange({ branch: selectedBranch, experience });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let branch = null;
    let experience = null;
    if (branches.length > 0 && value?.branch) {
      branch = branches.find((x) => x.id == parseInt(value.branch, 10));
      setSelectedBranch(branch);
    }
    if (allExperiences.length > 0 && value?.experience) {
      experience = allExperiences.find((x) => x.id == value.experience);
      setSelectedExperience(experience);
    }
    if (branch || experience) onChange(branch, experience);
  }, [allExperiences]);

  const getBranchExperiences = () => {
    return allExperiences.filter((exp) => {
      return value.branch.experiences.find(
        (branchExp) => branchExp.id === exp.id,
      );
    });
  };

  return (
    <div className="branchExpFilterContainer">
      {loading && <Loader />}
      <BranchListFilter
        branches={branches}
        onChange={onBranchChange}
        value={selectedBranch}
      />
      {!_.isEmpty(selectedBranch) && (
        <ExpListFilter
          experiences={getBranchExperiences()}
          onChange={onExperienceChange}
          value={selectedExperience}
          hideAllOpt
        />
      )}
    </div>
  );
}

export default BranchExpFilter;
