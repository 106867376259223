import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  createEntitySurvey,
  createSurveyTemplate,
  deleteEntitySurvey,
  getEntitySurveyById,
  getEntitySurveys,
  getSurveysTemplates,
  updateEntitySurvey,
  duplicateSurvey,
} from './surveysActions';

const INITIAL_STATE = {
  isLoading: false,
  deleteSurveysLoading: {},
  selectedSurvey: null,
  entitySurveys: { data: {}, isEntitySurveysLoading: false },
  surveyTemplates: { data: {}, isSurveysTemplatesLoading: false },
};

const surveysSlice = createSlice({
  name: 'surveysSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedSurvey: (state, action) => {
      state.selectedSurvey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEntitySurveys.pending, (state) => {
      state.entitySurveys.isEntitySurveysLoading = true;
    });
    builder.addCase(getEntitySurveys.rejected, (state) => {
      state.entitySurveys.isEntitySurveysLoading = false;
    });
    builder.addCase(getEntitySurveys.fulfilled, (state, action) => {
      state.entitySurveys = {
        isEntitySurveysLoading: false,
        data: action.payload,
      };
    });
    builder.addCase(duplicateSurvey.fulfilled, (state, action) => {
      state.selectedSurvey = action.payload;
    });
    builder.addCase(getSurveysTemplates.pending, (state) => {
      state.surveyTemplates.isSurveysTemplatesLoading = true;
    });
    builder.addCase(getSurveysTemplates.rejected, (state) => {
      state.surveyTemplates.isSurveysTemplatesLoading = false;
    });
    builder.addCase(getSurveysTemplates.fulfilled, (state, action) => {
      state.surveyTemplates = {
        isSurveysTemplatesLoading: false,
        data: action.payload,
      };
    });
    builder.addCase(deleteEntitySurvey.pending, (state, { meta: { arg } }) => {
      state.deleteSurveysLoading[arg] = true;
    });
    builder.addMatcher(
      isAnyOf(deleteEntitySurvey.rejected, deleteEntitySurvey.fulfilled),
      (state, { meta: { arg } }) => {
        state.deleteSurveysLoading[arg] = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getEntitySurveyById.pending,
        createSurveyTemplate.pending,
        createEntitySurvey.pending,
        updateEntitySurvey.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getEntitySurveyById.rejected,
        getEntitySurveyById.fulfilled,
        createSurveyTemplate.rejected,
        createSurveyTemplate.fulfilled,
        createEntitySurvey.rejected,
        createEntitySurvey.fulfilled,
        updateEntitySurvey.rejected,
        updateEntitySurvey.fulfilled,
      ),
      (state) => {
        state.isLoading = false;
      },
    );
    return builder;
  },
});
export const { setSelectedSurvey } = surveysSlice.actions;
export default surveysSlice;
