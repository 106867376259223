import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Filters, Loader, DigestItem } from 'components';
import { parentEntityDigestAPICall } from 'apis/cxmeter';
import { usePageTitle } from 'utils/hooks';
import { DIGEST } from 'utils/constants';
import { SINGLE_DATE_FILTER } from 'components/filters/filterTypes';

function Digest() {
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [filters, setFilters] = useState({
    [SINGLE_DATE_FILTER]: null,
  });

  async function fetchDataAndUpdateState(date) {
    setLoading(true);
    try {
      setOrganizations(await parentEntityDigestAPICall({ date }));
    } catch (error) {
      toast.error(error.message ? error.message : error);
    }
    setLoading(false);
  }

  usePageTitle(DIGEST);

  useEffect(() => {
    fetchDataAndUpdateState(filters[SINGLE_DATE_FILTER]);
  }, [filters]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="tabContentWrap">
      <Filters value={filters} onChange={setFilters} />
      <h3>All Organizations Digest</h3>
      {organizations.length >= 1 && (
        <p>You can edit the message by typing in the box</p>
      )}
      {organizations.length < 1 ? (
        <p>No feedbacks for this day</p>
      ) : (
        organizations.map((org, idx) => (
          <DigestItem
            key={idx}
            entityName={org.name}
            entityLogo={org.logo}
            totalHappyCustomers={org.happy}
            totalNeutralCustomers={org.neutral}
            totalUnhappyCustomers={org.unhappy}
            totalCommentsAdded={org.comment}
            totalAudiosAdded={org.audio}
            totalImagesAdded={org.image}
            branchDetails={org.entities}
          />
        ))
      )}
    </div>
  );
}

export default Digest;
