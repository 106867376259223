import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Pagination from 'rc-pagination';
import dayjs from 'dayjs';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { DEFAULT_PAGE_SIZE, LUCKY_DRAW } from 'utils/constants';
import { showErrorToast } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';
import { Loader } from 'components';
import {
  luckyDrawWinnerListAPICall,
  luckyDrawWinnerCreateAPICall,
} from 'apis/cxmeter';

import './_luckydraw.scss';

export function LuckyDraw() {
  const [loading, setLoading] = useState(false);
  const [luckyDrawWinners, setLuckyDrawWinners] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    setLoading(true);
    try {
      setLuckyDrawWinners(
        await luckyDrawWinnerListAPICall({ page: currentPage }),
      );
    } catch (error) {
      toast.error(error.message ? error.message : error);
    }
    setLoading(false);
  };

  const performLuckyDraw = async () => {
    setLoading(true);
    try {
      await luckyDrawWinnerCreateAPICall();
      if (currentPage === 1) {
        await fetchData();
      } else {
        setCurrentPage(1);
      }
    } catch (error) {
      showErrorToast(error);
    }
    setLoading(false);
  };

  usePageTitle(LUCKY_DRAW);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="luckDrawContainer">
      <div className="heading">
        <span>
          <h3>Lucky Draw Winners</h3>
          <p>To select the winner of this month use the button on the right</p>
        </span>
        <button
          disabled={!luckyDrawWinners.can_do_lucky_draw}
          // eslint-disable-next-line react/no-unknown-property
          variant="primary"
          onClick={performLuckyDraw}
          type="button"
        >
          Perform Lucky Draw
        </button>
      </div>
      <section className="FeedBackListingWrap boxSHadow">
        <div className="holder">
          {_.get(luckyDrawWinners, 'results', []).length === 0 ? (
            <p className="noData"> No Winners Found</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Customer Name</th>
                  <th>Contact No</th>
                  <th>Lucky Draw Date</th>
                  <th>Winning Amount</th>
                </tr>
              </thead>
              <tbody>
                {_.get(luckyDrawWinners, 'results', []).map((winner, index) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * DEFAULT_PAGE_SIZE + index + 1}</td>
                    <td>{winner.name}</td>
                    <td>{winner.phone_number}</td>
                    <td>{dayjs(winner.winning_date).format('DD MMM, YYYY')}</td>
                    <td>{winner.winning_amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </section>
      <div className="custom-pagination">
        <Pagination
          simple
          current={currentPage}
          total={_.get(luckyDrawWinners, 'count', 0)}
          onChange={setCurrentPage}
          pageSize={DEFAULT_PAGE_SIZE}
        />
      </div>
    </div>
  );
}

export default LuckyDraw;
