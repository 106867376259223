import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import { addressInfoSchema } from './common';
import Yup from './Yup';

const branchSetupSchema = Yup.object().shape({
  name: Yup.string()
    .requiredTrimmed(
      'Location name cannot be only blank spaces.',
      'Location name is required!',
    )
    .matches(
      onlyLettersAndSpacesRegex,
      'Location name can only contain letters',
    )
    .range(0, 60, '', 'Must not exceed 64 characters'),
  ...addressInfoSchema,
});

export default branchSetupSchema;
