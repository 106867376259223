/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Loader } from 'components';
import DashboardAlerts from 'components/dashboardAlerts/DashboardAlerts';
import { useOnboardingRedirect } from 'utils/hooks';
import './_dashboardLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAndInitialiseData } from 'redux/authentication/authenticationActions';

export default function DashboardLayout() {
  const { isActiveOrg } = useSelector((s) => s.authentication);
  const { isLoading } = useOnboardingRedirect();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActiveOrg) dispatch(getUserAndInitialiseData());
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Header />
      <main className="cxmeter-main">
        <div className="cxmeter-container">
          <DashboardAlerts />
          <Outlet />
        </div>
      </main>
    </>
  );
}
