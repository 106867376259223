import { SEARCH_FILTER } from 'components/filters/filterTypes';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { useContext } from 'react';
import { EMAIL_MANAGEMENT_PAGINATION } from 'utils/constants/manageEmails';

function ManageEmailsTabs({ tabs, activeTab, setActiveTab }) {
  const { setSearchFilter, setParams, setSubTabsSearch } = useContext(
    EmailManagementFiltersContext,
  );
  const activeTabHandler = (tab) => {
    if (tab.id !== activeTab.id) {
      if (!tab.isSubTab) {
        setParams(EMAIL_MANAGEMENT_PAGINATION);
        setSearchFilter({ [SEARCH_FILTER]: '' });
      } else {
        setSubTabsSearch({ [SEARCH_FILTER]: '' });
      }
      setActiveTab((p) => ({
        ...tab,
        heading: p.heading,
        subHeading: p.subHeading,
      }));
    }
  };
  return (
    <div className="manage-emails-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`manage-emails-tab ${
            tab.id === activeTab.id ? 'active' : ''
          }`}
          onClick={() => activeTabHandler(tab)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
}

export default ManageEmailsTabs;
