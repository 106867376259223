import React, { useState } from 'react';
import SectionBanner from './SectionBanner';
import {
  BOTH_PREVIEW,
  PERCENTAGE_PREVIEW,
  VALUES_PREVIEW,
} from 'utils/constants/compareFeedbacks';
import { isNil } from 'lodash';

function ComparisonSection({
  selectedComparisonFilters,
  title,
  data,
  previewType,
}) {
  const [openSections, setOpenSections] = useState(
    Object.fromEntries(data.map((_, idx) => [idx, true])),
  );

  const toggleSection = (section) => {
    setOpenSections((prevState) => {
      return {
        ...prevState,
        [section]: !prevState[section],
      };
    });
  };

  const getGreaterFeedback = (answer, percentage, value) => {
    let hasGoodFeedback = false;
    const hasGoodValue =
      Math.max(...answer.values) === value &&
      Math.min(...answer.values) !== value;
    const hasGoodPercentage =
      Math.max(...(answer.percentages ?? [])) === percentage &&
      Math.min(...(answer.percentages ?? [])) !== percentage;
    switch (previewType.id) {
      case PERCENTAGE_PREVIEW:
        hasGoodFeedback = hasGoodPercentage;
        break;
      case VALUES_PREVIEW:
        hasGoodFeedback = hasGoodValue;
        break;
      case BOTH_PREVIEW:
        hasGoodFeedback = hasGoodPercentage;
        break;
      default:
        break;
    }
    return hasGoodFeedback;
  };

  return (
    <div className="comparison__content-container">
      {data.map((question, idx) => {
        return (
          <div key={idx} className="comparison__content-header">
            <SectionBanner
              title={title}
              section={question.title}
              id={idx}
              toggleSection={toggleSection}
              isOpen={openSections[idx]}
            />
            {question.data.map((answer) => {
              return (
                openSections[idx] && (
                  <div
                    className="comparison__content"
                    style={{
                      gridTemplateColumns: `minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) ${
                        selectedComparisonFilters > 2
                          ? 'minmax(100px, 1fr)'
                          : '40px'
                      }`,
                    }}
                    key={answer.key}
                  >
                    <p className="font-body">{answer.key}</p>
                    {answer.values.map((value, index) => {
                      const percentages = answer.percentages ?? [];
                      const hasGreaterFeedback = getGreaterFeedback(
                        answer,
                        percentages[index],
                        value,
                      );
                      const percentage = isNil(percentages[index])
                        ? null
                        : `${percentages[index]}%`;
                      return (
                        <div key={index} className="comparison__values">
                          <div className="compare__values-content">
                            <p
                              className={`font-body ${
                                hasGreaterFeedback ? 'high-feedback-value' : ''
                              }`}
                            >
                              {previewType.id === VALUES_PREVIEW ? (
                                <span>{value ?? '-'}</span>
                              ) : null}
                              {previewType.id === PERCENTAGE_PREVIEW ? (
                                <span>{percentage ?? '-'}</span>
                              ) : null}
                              {previewType.id === BOTH_PREVIEW ? (
                                <span>
                                  {isNil(value) && isNil(percentage) ? (
                                    '-'
                                  ) : (
                                    <>
                                      {value ?? '-'} , {percentage ?? '-'}
                                    </>
                                  )}
                                </span>
                              ) : null}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default ComparisonSection;
