import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import Inbox from 'components/manageEmails/Inbox/Inbox';
import Groups from 'components/manageEmails/Groups';
import Schedules from 'components/manageEmails/Schedule';
import Templates from 'components/manageEmails/Templates';
import {
  EMAIL_GROUPS,
  EMAIL_TEMPLATES,
  INBOX_EMAILS,
  MANAGE_EMAIL_SCHEDULE,
  MANAGE_EMAILS_TABS,
} from 'utils/constants/manageEmails';
import ManageEmailsLayout from 'layouts/emailManagementLayout/EmailManagementLayout';
import SendEmailModal from 'components/manageEmails/modals/ComposeEmail/SendEmailModal';
import ManageEmailsTabs from 'components/manageEmails/ManageEmailTabs';
import { Filters } from 'components';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import InviteGroupModal from 'components/manageEmails/modals/InviteGroup/InviteGroupModal';
import InviteTemplateModal from 'components/manageEmails/modals/InviteTemplate/InviteTemplateModal';
import './_manageEmails.scss';
import './_common.scss';

function EmailData() {
  const { t } = useTranslation();
  const {
    searchFilter,
    setSearchFilter,
    subTabsSearch,
    setSubTabsSearch,
    isOpenSendEmailModal,
    setIsOpenSendEmailModal,
    isOpenGroupModal,
    setIsOpenGroupModal,
    isOpenInviteTemplateModal,
    setIsOpenInviteTemplateModal,
  } = useContext(EmailManagementFiltersContext);

  const [activeTab, setActiveTab] = useState(MANAGE_EMAILS_TABS[0]);
  const [activeSubTab, setActiveSubTab] = useState(null);

  const subTabHandler = (isAcitve, heading, subHeading) => {
    setActiveSubTab(
      isAcitve ? { ...activeTab.subTabs[0], heading, subHeading } : null,
    );
    setActiveTab((p) => ({ ...p, isActiveSubTab: isAcitve }));
  };

  return (
    <main>
      <div className="manage-emails-container">
        <div className="manage-emails-header">
          <div className="heading">
            {activeTab.isActiveSubTab ? (
              <div>
                <button
                  className="back-to-main-tabs"
                  onClick={() => subTabHandler(false)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>Back</span>
                </button>
                <h3>{activeSubTab.heading}</h3>
                <p>{activeSubTab.subHeading}</p>
              </div>
            ) : (
              <>
                <h3>{t('settings.manageEmails.title')}</h3>
                <p>{t('settings.manageEmails.subTitle')}</p>
              </>
            )}
          </div>
          {activeTab.isActiveSubTab ? null : (
            <div className="controls">
              <button
                className="create-new-group cstm-btn primary-cstm-btn"
                size="large"
                onClick={() => setIsOpenGroupModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>
                  {t('settings.manageEmails.controls.createNewGroup')}
                </span>
              </button>
              <button
                className="compose-new cstm-btn secondary-cstm-btn"
                size="large"
                onClick={() => setIsOpenSendEmailModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('settings.manageEmails.controls.composeNew')}</span>
              </button>
            </div>
          )}
        </div>
        <div className="manage-emails-tabs-container">
          {activeTab.isActiveSubTab ? (
            <ManageEmailsTabs
              tabs={activeTab.subTabs}
              setActiveTab={setActiveSubTab}
              activeTab={activeSubTab}
            />
          ) : (
            <ManageEmailsTabs
              tabs={MANAGE_EMAILS_TABS}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          )}
          <div className="manage-emails-tabs-content">
            <div className="manage-emails-table-search">
              <Filters
                value={activeTab.isActiveSubTab ? subTabsSearch : searchFilter}
                addMainWrapper={false}
                onChange={
                  activeTab.isActiveSubTab ? setSubTabsSearch : setSearchFilter
                }
              />
              {activeTab.id === EMAIL_TEMPLATES ? (
                <button
                  onClick={() => setIsOpenInviteTemplateModal(true)}
                  className="create-new-tmeplate cstm-btn primary-cstm-btn"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Create Template</span>
                </button>
              ) : null}
            </div>
            {activeTab.id === INBOX_EMAILS ? (
              <Inbox
                subTabHandler={subTabHandler}
                activeSubTab={activeSubTab}
              />
            ) : null}
            {activeTab.id === EMAIL_GROUPS ? <Groups /> : null}
            {activeTab.id === MANAGE_EMAIL_SCHEDULE ? <Schedules /> : null}
            {activeTab.id === EMAIL_TEMPLATES ? <Templates /> : null}
          </div>
        </div>
      </div>
      {isOpenGroupModal ? <InviteGroupModal activeTab={activeTab} /> : null}
      {isOpenSendEmailModal ? <SendEmailModal activeTab={activeTab} /> : null}
      {isOpenInviteTemplateModal ? (
        <InviteTemplateModal activeTab={activeTab} />
      ) : null}
    </main>
  );
}

function ManageEmails() {
  return (
    <ManageEmailsLayout>
      <EmailData />
    </ManageEmailsLayout>
  );
}

export default ManageEmails;
