import { RichTextEditor } from 'components/richTextEditor';
import ToEmailInput from '../ToEmailInput';
import EmailModalInput from './EmailModalInput';
import {
  EMAIL_EDITOR_TOOLBAR_CONFIG,
  EMAIL_TEMPLATES_OVERLAY,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import EmailSchedule from './EmailSchedule';
import { useEffect, useRef } from 'react';
import { Icon } from 'components/ui';
import FeedbackFormSelector from '../FeedbackFormSelector';

function EmailComposition({ formik, setCurrentOverlayPreview }) {
  const editorRef = useRef();
  const { values, errors, touched } = formik;
  const { updateHTMLEditorState, html_message: htmlMessage } = values;

  useEffect(() => {
    if (editorRef && updateHTMLEditorState) {
      editorRef.current.updateState(htmlMessage);
      formik.setFieldValue('updateHTMLEditorState', false);
    }
  }, [updateHTMLEditorState]);

  return (
    <>
      <ToEmailInput formik={formik} isEmailModal />
      <EmailModalInput
        label="Subject"
        placeholder="Enter Subject"
        id="subject"
        formik={formik}
      />
      <div>
        <div className="email-body">
          <RichTextEditor
            ref={editorRef}
            showCustomLink
            setState={(message) =>
              formik.setFieldValue('html_message', message)
            }
            content={values.html_message}
            toolbarConfig={EMAIL_EDITOR_TOOLBAR_CONFIG}
            toolbarOnFocus={false}
            warningText="* The survey link will be automatically appended to the end of your message."
          />
        </div>
        {formik.submitCount > 0 && errors.html_message ? (
          <span className="control-error">{errors.html_message}</span>
        ) : null}
      </div>
      <div className="add-templates-btns">
        <div>
          <button
            className="cstm-btn light-cstm-btn"
            onClick={() => setCurrentOverlayPreview(EMAIL_TEMPLATES_OVERLAY)}
          >
            <Icon type="news" />
            Import Template
          </button>
          <FeedbackFormSelector
            openFeedbackTemplates={() =>
              setCurrentOverlayPreview(FEEDBACK_TEMPLATES_OVERLAY)
            }
            selectedFeedbackForm={values.entity_experience?.title}
          />
        </div>
        {touched.entity_experience && (
          <span className="control-error">{errors.entity_experience}</span>
        )}
      </div>
      <EmailSchedule formik={formik} />
    </>
  );
}

export default EmailComposition;
