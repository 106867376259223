import { createSlice } from '@reduxjs/toolkit';
import {
  createQr,
  deleteQr,
  getQr,
  getQrList,
  initializeQr,
  printAllQrs,
  shareQr,
  updateQr,
  updateQrListRow,
} from './qrSetupActions';

const INITIAL_STATE = {
  qrList: {
    isLoading: false,
    data: [],
    count: 0,
    rowsLoading: {},
  },
  isCreating: false,
  isDeleting: false,
  isEmailingQr: false,
  isInitializing: false,
  selectedQrCode: {},
  isPrintingAllQrs: false,
};

const qrSetupSlice = createSlice({
  name: 'qrSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    removeSelectedQr: (state) => {
      state.selectedQrCode = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQrList.pending, (state) => {
      state.qrList.isLoading = true;
      state.qrList.count = 0;
    });
    builder.addCase(getQrList.rejected, (state) => {
      state.qrList.isLoading = false;
    });
    builder.addCase(getQrList.fulfilled, (state, action) => {
      state.qrList.count = action.payload.count;
      state.qrList.isLoading = false;
      state.qrList.data = action.payload.results;
    });
    builder.addCase(createQr.pending, (state) => {
      state.isCreating = true;
    });
    builder.addCase(createQr.rejected, (state) => {
      state.isCreating = false;
    });
    builder.addCase(createQr.fulfilled, (state) => {
      state.isCreating = false;
    });
    builder.addCase(updateQr.pending, (state) => {
      state.isCreating = true;
    });
    builder.addCase(updateQr.rejected, (state) => {
      state.isCreating = false;
    });
    builder.addCase(updateQr.fulfilled, (state) => {
      state.isCreating = false;
    });
    builder.addCase(updateQrListRow.pending, (state, { meta: { arg } }) => {
      const { id, columnKey } = arg;
      const loading = state.qrList.rowsLoading;
      state.qrList.rowsLoading = {
        ...loading,
        [columnKey]: {
          ...loading.columnKey,
          [id]: true,
        },
      };
    });
    builder.addCase(updateQrListRow.rejected, (state, { meta: { arg } }) => {
      const { id, columnKey } = arg;
      const loading = state.qrList.rowsLoading;
      state.qrList.rowsLoading = {
        ...loading,
        [columnKey]: {
          ...loading.columnKey,
          [id]: false,
        },
      };
    });
    builder.addCase(updateQrListRow.fulfilled, (state, { meta: { arg } }) => {
      const { id, columnKey, rowIndex, params } = arg;
      const loading = state.qrList.rowsLoading;
      state.qrList.rowsLoading = {
        ...loading,
        [columnKey]: {
          ...loading.columnKey,
          [id]: false,
        },
      };
      state.qrList.data[rowIndex] = {
        ...state.qrList.data[rowIndex],
        ...params,
      };
    });
    builder.addCase(deleteQr.pending, (state) => {
      state.isDeleting = true;
    });
    builder.addCase(deleteQr.rejected, (state) => {
      state.isDeleting = false;
    });
    builder.addCase(deleteQr.fulfilled, (state) => {
      state.isInitializing = false;
    });
    builder.addCase(initializeQr.pending, (state) => {
      state.isInitializing = true;
    });
    builder.addCase(initializeQr.rejected, (state) => {
      state.isInitializing = false;
    });
    builder.addCase(initializeQr.fulfilled, (state, action) => {
      state.isInitializing = false;
      state.selectedQrCode = action.payload;
    });
    builder.addCase(getQr.pending, (state) => {
      state.isInitializing = true;
    });
    builder.addCase(getQr.rejected, (state) => {
      state.isInitializing = false;
    });
    builder.addCase(getQr.fulfilled, (state, action) => {
      state.isInitializing = false;
      state.selectedQrCode = action.payload;
    });
    builder.addCase(shareQr.pending, (state) => {
      state.isEmailingQr = true;
    });
    builder.addCase(shareQr.rejected, (state) => {
      state.isEmailingQr = false;
    });
    builder.addCase(shareQr.fulfilled, (state) => {
      state.isEmailingQr = false;
    });
    builder.addCase(printAllQrs.pending, (state) => {
      state.isPrintingAllQrs = true;
    });
    builder.addCase(printAllQrs.rejected, (state) => {
      state.isPrintingAllQrs = false;
    });
    builder.addCase(printAllQrs.fulfilled, (state) => {
      state.isPrintingAllQrs = false;
    });
    return builder;
  },
});
export default qrSetupSlice;
export const { removeSelectedQr } = qrSetupSlice.actions;
