/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { STRIPE_CLIENT_ID } from '../../config';
import { URLS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribedPlan } from 'redux/payments/paymentsActions';
import { Col, Row } from 'react-bootstrap';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(STRIPE_CLIENT_ID);

export default function PaymentSetupScreen() {
  const dispatch = useDispatch();
  const { data: subscribedPlan } =
    useSelector((s) => s.payments?.subscribedPlan) ?? {};
  const { state } = useLocation();
  const navigate = useNavigate();

  const branchActivationNaivgateHanlder = () => {
    navigate(URLS.BRANCH_ACTIVATION_URL);
  };

  useEffect(() => {
    if (!state?.plan && subscribedPlan && !subscribedPlan.is_active) {
      navigate(URLS.PAYMENT_PLANS_URL);
    }
  }, [state?.plan, subscribedPlan?.is_active]);

  useEffect(() => {
    dispatch(getSubscribedPlan());
  }, []);

  return (
    <>
      {!subscribedPlan ? null : (
        <>
          {subscribedPlan.is_active ? (
            <Row>
              <Col md={6}>
                <div className="h-100 d-flex flex-column gap-4 align-items-center justify-content-center">
                  <div>
                    You have already subscribed to&nbsp;
                    <b>{subscribedPlan.name}</b>
                    &nbsp;Plan.
                  </div>
                  <div>
                    <button
                      className="button button-primary"
                      type="button"
                      onClick={branchActivationNaivgateHanlder}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={6} className="d-none d-md-block">
                <div className="planWrapper" />
              </Col>
            </Row>
          ) : (
            <Elements
              stripe={stripePromise}
              appearance={{
                theme: 'flat',
              }}
            >
              <CheckoutForm />
            </Elements>
          )}
        </>
      )}
    </>
  );
}
