import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailTemplatesList from './EmailTemplatesList';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FeedbackTemplatesList from './FeedbackTemplatesList';
import {
  EMAIL_MANAGEMENT_PAGINATION,
  EMAIL_TEMPLATES_OVERLAY,
  FEEDBACK_TEMPLATES_OVERLAY,
} from 'utils/constants/manageEmails';
import { useState } from 'react';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { Filters } from 'components/filters';

function TemplateOverlays({
  formik,
  currentOverlayPreview,
  setCurrentOverlayPreview,
}) {
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: null,
  });
  const [params, setParams] = useState({
    ...EMAIL_MANAGEMENT_PAGINATION,
    search: '',
  });

  const loadMoreHandler = () => {
    setParams((p) => ({ ...p, page: p.page + 1 }));
  };

  const onTemplateConfirm = () => {
    setCurrentOverlayPreview(null);
  };

  const onSearchChange = (searchValue) => {
    const search = searchValue[SEARCH_FILTER];
    setSearchFilter(searchValue);
    setParams((p) => ({ ...p, page: 1, search }));
  };

  return (
    <div className="add-template-container">
      <button className="back-to-main-tabs" onClick={onTemplateConfirm}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>Back</span>
      </button>
      <h5>
        {currentOverlayPreview === FEEDBACK_TEMPLATES_OVERLAY
          ? 'Attach Feedback Form'
          : 'Import Template'}
      </h5>
      <div>
        <Filters
          value={searchFilter}
          addMainWrapper={false}
          onChange={onSearchChange}
        />
      </div>
      {currentOverlayPreview === FEEDBACK_TEMPLATES_OVERLAY ? (
        <FeedbackTemplatesList
          formik={formik}
          apiParams={params}
          loadMoreHandler={loadMoreHandler}
          onConfirm={onTemplateConfirm}
        />
      ) : null}
      {currentOverlayPreview === EMAIL_TEMPLATES_OVERLAY ? (
        <EmailTemplatesList
          formik={formik}
          apiParams={params}
          loadMoreHandler={loadMoreHandler}
          onConfirm={onTemplateConfirm}
        />
      ) : null}
    </div>
  );
}

export default TemplateOverlays;
