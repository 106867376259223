import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PasswordField } from 'components';
import { resetPasswordSchema } from 'utils/validations';
import { URLS, RESET_PASSWORD } from 'utils/constants';
import { resetPasswordAPICall } from 'apis/authentication';
import { usePageTitle } from 'utils/hooks';
import './_forgotPassword.scss';
import { OnboardingLayout } from 'layouts';

export default function ResetPassword() {
  usePageTitle(RESET_PASSWORD);

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get('email') || !searchParams.get('token'))
      navigate(URLS.FORGOT_PASSWORD_URL);
  }, []);

  const handleResetPassword = async (values) => {
    if (!searchParams.get('email') || !searchParams.get('token'))
      navigate(URLS.FORGOT_PASSWORD_URL);
    setLoading(true);
    try {
      const res = await resetPasswordAPICall({
        email: searchParams.get('email'),
        token: searchParams.get('token'),
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      toast.success(res);
      navigate(URLS.RESET_PASSWORD_SUCCESS_URL);
    } catch (e) {
      toast.error(e.response.data);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: handleResetPassword,
  });

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Reset Password">
          <form className="cxmeter-form" onSubmit={formik.handleSubmit}>
            <PasswordField
              id="password"
              placeholder="New Password *"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password}
            />
            <PasswordField
              id="confirmPassword"
              placeholder="Confirm New Password *"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </form>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={loading}
          onClickContinue={formik.handleSubmit}
          continueText="Reset Password"
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
