import React from 'react';
import { Modal } from 'components/ui';

export default function SurveyEditedModal({
  onClose,
  isOpenModal,
  primaryAction,
  secondaryAction,
}) {
  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header title="Change Existing or Create New?" onClose={onClose} />
      <Modal.Body>
        <div style={{ maxHeight: 'calc(100% - 100px' }}>
          Would you like to update the original question/option or start a new
          one?
          <br />
          Starting a new question will discontinue the original question and old
          submissions will not be linked to the new question.
          <br />
          If you update the original, all past submissions will remain linked to
          this question.
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryLabel="Update Original"
        primaryAction={primaryAction}
        secondaryLabel="Start New"
        secondaryAction={secondaryAction}
      />
    </Modal>
  );
}
