import { createSlice } from '@reduxjs/toolkit';
import { getUniqueObjects } from 'utils/helpers';

const INITIAL_STATE = {
  segments: [],
  experiences: [],
  entity_experiences: [],
  drivers: [],
  statistics: {
    total_feedbacks: 0,
    total_happy_feedbacks: 0,
    total_neutral_feedbacks: 0,
    total_unhappy_feedbacks: 0,
    total_feedbacks_in_last_30: 0,
    total_happy_feedbacks_in_last_30: 0,
  },
  locations: {
    branches: [],
    cities: [],
    states: [],
  },
  happiness: {
    daily: [],
    weekly: [],
  },
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: INITIAL_STATE,
  reducers: {
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    setEntityExperiences: (state, action) => {
      const entityExperiences = action.payload;
      state.experiences = getUniqueObjects(
        entityExperiences
          .map((entityExperience) => entityExperience.experience)
          .flat(),
      );
      state.drivers = getUniqueObjects(
        entityExperiences
          .map((entityExperience) => entityExperience.drivers)
          .flat(),
      );
      state.entity_experiences = entityExperiences;
    },
    setDrivers: (state, action) => {
      state.drivers = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setHappiness: (state, action) => {
      state.happiness = action.payload;
    },
  },
});

const { actions, reducer } = feedbackSlice;
export const { setSegments, setEntityExperiences, setLocations, setHappiness } =
  actions;

export default reducer;
