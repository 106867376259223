import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import Yup from './Yup';

const editProfileValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .requiredTrimmed(
      'First name cannot be only blank spaces.',
      'First name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'First name can only contain letters')
    .range(
      2,
      64,
      'Must be at least 2 characters',
      'Must not exceed 64 characters',
    ),

  last_name: Yup.string()
    .requiredTrimmed(
      'Last name cannot be only blank spaces.',
      'Last name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'Last name can only contain letters')
    .range(
      2,
      64,
      'Must be at least 2 characters',
      'Must not exceed 64 characters',
    ),
});

export default editProfileValidationSchema;
