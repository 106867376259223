import React from 'react';
import _ from 'lodash';
import {
  PieChartBlurPreview,
  TopPerformersBlurPreview,
  WidgetRatingBlurPreview,
  WorstPerformersBlurPreview,
} from 'components/statBlurWrapper';
import StatBlurWrapper from 'components/statBlurWrapper/StatBlurWrapper';

export default function WidgetsPreview() {
  const previewUrls = [
    WidgetRatingBlurPreview,
    PieChartBlurPreview,
    WorstPerformersBlurPreview,
    TopPerformersBlurPreview,
  ];

  return (
    <div>
      <span>Survey Drivers</span>
      <section className="chartsWrapper">
        {[1, 2, 3].map((v) => (
          <StatBlurWrapper
            key={v}
            showStat={false}
            previewUrl={previewUrls[_.random(0, previewUrls.length - 1)]}
            alt="Widget Preivew"
          />
        ))}
      </section>
    </div>
  );
}
