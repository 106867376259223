import { Modal } from 'components/ui';
import { Table } from 'antd';
import ReactDOM from 'react-dom';
import EmailManagementFiltersContext from 'contexts/EmailManagementContext';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePendingInviteById,
  getInviteById,
  getInvites,
  sendInviteReminderById,
} from 'redux/emailManagement/emailManagementActions';
import { getPendingInboxTableColumns } from 'utils/helpers/emailsManagementHelper';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function PendingInboxSummary({
  selectedPendingRecords,
  onEmptySelection,
  pendingInvitesLoading,
  updateInviteRecord,
  refreshInboxTable,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithErrors();
  const [showReminderModal, setShowReminderModal] = useState(false);

  const closeReminderModal = () => {
    setShowReminderModal(false);
  };

  const sendMultipleReminders = async (sendReminderForcefully = false) => {
    if (sendReminderForcefully && showReminderModal) {
      closeReminderModal();
    }
    const ids = Object.keys(selectedPendingRecords);
    const { isError } = await dispatch(
      sendInviteReminderById({
        invite_record_ids: ids,
        params: { force: sendReminderForcefully },
      }),
    );
    if (isError) {
      setShowReminderModal(true);
    } else {
      toast.success(
        t('settings.manageEmails.toasts.reminderInvite', {
          reminder: 'Reminders',
        }),
      );
      updateInviteRecord();
    }
  };

  const deleteMultipleInvites = async () => {
    const ids = Object.keys(selectedPendingRecords);
    await dispatch(
      deletePendingInviteById({
        invite_record_ids: ids,
      }),
    );
    refreshInboxTable();
    updateInviteRecord();
    onEmptySelection();
  };

  const selectedCount = Object.keys(selectedPendingRecords).length;
  if (selectedCount > 1) {
    const showLoading = pendingInvitesLoading.isMultiple;
    return (
      <>
        <Table.Summary.Row style={{ opacity: showLoading ? 0.5 : 1 }}>
          <Table.Summary.Cell
            className="selected-pending-rows-summary"
            colSpan={5}
          >
            <span className="summary-row">
              <span className="selected-users">
                <span className="selected-users-count">
                  <span>
                    {selectedCount} {selectedCount > 1 ? 'Users' : 'User'}
                  </span>
                  <span>Selected</span>
                </span>
                <button
                  disabled={showLoading}
                  className="cancel-selection cstm-btn"
                  onClick={onEmptySelection}
                >
                  Cancel
                </button>
              </span>
              <span className="selected-users-actions">
                <button
                  onClick={deleteMultipleInvites}
                  disabled={showLoading}
                  style={{ opacity: showLoading ? 0.75 : 1 }}
                  className="reomove-selected cstm-btn secondary-cstm-btn"
                >
                  Remove
                </button>
                <button
                  onClick={() => sendMultipleReminders()}
                  disabled={showLoading}
                  style={{ opacity: showLoading ? 0.75 : 1 }}
                  className="send-selected-reminder cstm-btn primary-cstm-btn"
                >
                  Send Reminder
                </button>
              </span>
            </span>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        {ReactDOM.createPortal(
          <Modal
            open={showReminderModal}
            onClose={closeReminderModal}
            backDropClose={false}
          >
            <Modal.Body>
              <p>{t('settings.manageEmails.modals.sendReminder.message')}</p>
            </Modal.Body>
            <Modal.Footer
              primaryAction={() => sendMultipleReminders(true)}
              primaryLabel="Send Reminder"
              secondaryAction={closeReminderModal}
            />
          </Modal>,
          document.body,
        )}
      </>
    );
  }
  return null;
}

function PendingInbox({
  inviteId,
  searchParams,
  data: { pending: pendingInvites = [] } = {},
  loading,
}) {
  const dispatch = useDispatch();
  const { params } = useContext(EmailManagementFiltersContext);
  const { pendingInvitesLoading } = useSelector((s) => s.emailManagement);
  const [selectedPendingRecords, setSelectedPendingRecords] = useState({});

  const refreshInboxTable = () => {
    dispatch(getInvites({ ...params }));
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const selectedRecords = {};
    if (isChecked) {
      pendingInvites.forEach((r) => {
        selectedRecords[r.id] = r;
      });
    }
    setSelectedPendingRecords(selectedRecords);
  };

  const selectedPendingRecordsToggler = (record) => {
    const selectedRecords = { ...selectedPendingRecords };
    const isSelectedRecord = selectedRecords[record.id];
    if (isSelectedRecord) {
      delete selectedRecords[record.id];
    } else {
      selectedRecords[record.id] = record;
    }
    setSelectedPendingRecords(selectedRecords);
  };

  const updateInviteRecord = () => {
    dispatch(
      getInviteById({
        id: inviteId,
        params: { search: searchParams },
      }),
    );
  };

  const onDeleteInvite = async (rec) => {
    await dispatch(
      deletePendingInviteById({
        invite_record_ids: [rec.id],
      }),
    );
    updateInviteRecord();
    refreshInboxTable();
    if (selectedPendingRecords[rec.id]) selectedPendingRecordsToggler(rec);
  };

  const onEmptySelection = () => {
    setSelectedPendingRecords({});
  };

  return (
    <Table
      className="left-align pending-inbox-table"
      loading={loading}
      columns={getPendingInboxTableColumns(
        onDeleteInvite,
        updateInviteRecord,
        pendingInvites?.length,
        selectedPendingRecords,
        handleSelectAllChange,
        selectedPendingRecordsToggler,
        pendingInvitesLoading,
      )}
      scroll={{ x: 'max-content' }}
      dataSource={pendingInvites}
      rowClassName={(r) => (selectedPendingRecords[r.id] ? 'selected' : '')}
      // eslint-disable-next-line react/no-unstable-nested-components
      summary={() => (
        <PendingInboxSummary
          pendingInvitesLoading={pendingInvitesLoading}
          onEmptySelection={onEmptySelection}
          selectedPendingRecords={selectedPendingRecords}
          updateInviteRecord={updateInviteRecord}
          refreshInboxTable={refreshInboxTable}
        />
      )}
    />
  );
}

export default PendingInbox;
