import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import './_richTextEditor.scss';

export const getFormattedState = (newContent) => {
  let editorState;
  if (!newContent) editorState = EditorState.createEmpty();
  else if (typeof newContent === 'string') {
    const contentBlock = htmlToDraft(newContent);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    editorState = EditorState.createWithContent(contentState);
  } else {
    editorState = EditorState.createWithContent(newContent);
  }
  return editorState;
};

function RichTextEditor(
  {
    showCustomLink,
    placeholder,
    setState,
    content,
    readOnly = false,
    error = '',
    toolbarOnFocus = true,
    toolbarConfig,
    warningText,
    errorStyles = { borderBottom: '1px solid red' },
    ...props
  },
  ref,
) {
  const [editorState, setEditorState] = useState(() =>
    getFormattedState(content),
  );

  const textEditorRef = useRef();

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve({ data: { link: reader.result } });
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const toolbarOptions = useMemo(() => {
    if (toolbarConfig) {
      let config = { ...toolbarConfig };
      if (toolbarConfig.image) {
        config = {
          ...config,
          image: {
            ...toolbarConfig.image,
            uploadCallback: uploadImageCallBack,
          },
        };
      }
      return config;
    }
    return {
      options: ['inline'],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
      },
    };
  }, [toolbarConfig]);

  useImperativeHandle(
    ref,
    () => ({
      updateState: (newContent) => {
        const formattedState = getFormattedState(newContent);
        setEditorState(formattedState);
        setState(formattedState.getCurrentContent());
      },
    }),
    [],
  );

  useEffect(() => {
    if (!content) setEditorState(EditorState.createEmpty());
  }, [content]);

  useEffect(() => {
    if (textEditorRef?.current) {
      setEditorState(EditorState.moveFocusToEnd(editorState));
    }
  }, []);

  const handleOnEditorStateChange = (event) => {
    setEditorState(event);
    setState(event.getCurrentContent());
  };

  return (
    <>
      {warningText ? <span className="warning-text">{warningText}</span> : null}
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={handleOnEditorStateChange}
        wrapperClassName={`${readOnly ? 'readonly-editor' : ''} editorWrapper`}
        editorClassName="editor"
        toolbarClassName="toolbar"
        toolbarOnFocus={toolbarOnFocus}
        readOnly={readOnly}
        toolbar={toolbarOptions}
        ref={textEditorRef}
        editorStyle={error ? errorStyles : {}}
        {...props}
      />
      {error && <span className="editor-error">{error}</span>}
    </>
  );
}

export default forwardRef(RichTextEditor);
