import { useContext, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './_createSurvey.scss';
import { surveyHelper, showErrorToast } from 'utils/helpers';
import {
  SurveyPreview,
  SurveyBuilder,
  Loader,
  SurveyCustomerDetail,
} from 'components';
import {
  INITIAL_SURVEY,
  URLS,
  PARENT_ENTITY_ADMIN,
  INITIAL_QUESTION_ID,
  BRANCH_SETUP_STEP,
} from 'utils/constants';
import { useDetectMobileScreen, useScreenshot } from 'utils/hooks';
import {
  getSurveyAPICall,
  surveyCreateAPICall,
  surveyTemplateCreateAPICall,
  surveyUpdateAPICall,
} from 'apis/survey';
import SimilarityConfirmModal from 'components/surveyBuilder/surveyBuilderAnswers/SimilarityConfirmModal';
import { RATING } from 'utils/constants/questionTypes';
import { OnboardingLayout } from 'layouts';
import { OnboardingBranchContext } from 'contexts';
import { useFormik } from 'formik';
import surveySchema from 'utils/validations/surveyValidations';
import { validateSurvey } from 'utils/helpers/surveyHelpers';
import { isEmpty } from 'lodash';

export default function CreateSurvey() {
  const { state } = useLocation();
  const { setBranch } = useContext(OnboardingBranchContext);

  const navigate = useNavigate();
  const duplicateOptionsSections = useRef({});
  const isMobileDevice = useDetectMobileScreen();
  // eslint-disable-next-line no-unused-vars
  const [image, takeScreenshot, { error }] = useScreenshot();

  const formik = useFormik({
    initialValues: { ...INITIAL_SURVEY, experience: state?.experience?.id },
    validationSchema: surveySchema,
  });
  const survey = formik.values;
  const [isPreviewOpen, setIsPreviewOpen] = useState(!isMobileDevice);
  const [loading, setLoading] = useState(false);
  const [isOpenSimilarityModal, setIsOpenSimilarityModal] = useState(false);
  const [isSimilarErrorActive, setIsSimilarErrorActive] = useState(false);
  const { user } = useSelector((s) => s.authentication);
  const ref = useRef(null);
  const { cleanSurvey, convertToRawSurvey, formatSurveyQuestion } =
    surveyHelper;

  const getSurvey = async () => {
    setLoading(true);
    const surveyById = await getSurveyAPICall(state.id);
    formik.resetForm({
      values: convertToRawSurvey(surveyById),
    });
    setLoading(false);
  };

  const resetState = () => {
    formik.setFieldValue('sections[0].questions', [
      formatSurveyQuestion(INITIAL_QUESTION_ID, '', RATING),
    ]);
  };

  const handleSurveyTitleChange = (event) => {
    formik.setFieldValue('title', event.target.value);
  };

  const setEnableCustomerDetail = (value) => {
    formik.setFieldValue('is_collecting_customer_info', value);
  };

  const checkSurveyValidity = () => {
    if (!formik.isValid || !isEmpty(duplicateOptionsSections.current)) {
      return false;
    }
    return validateSurvey(survey);
  };
  // eslint-disable-next-line consistent-return
  const saveSurvey = async () => {
    const isSurveyValid = checkSurveyValidity();
    if (!isSurveyValid) return;

    setIsOpenSimilarityModal(false);
    setLoading(true);
    const cleanedSurvey = cleanSurvey(survey, state.id && !state.template);
    const preview = await takeScreenshot(ref.current);

    try {
      const surveyData = {
        experience: state.experience.id,
        survey: { ...cleanedSurvey, preview },
      };
      let surveyRes = {};
      if (user.is_superuser) {
        surveyRes = await surveyTemplateCreateAPICall(surveyData);
        resetState();
        navigate(URLS.DASHBOARD_URL);
      } else if (user.type === PARENT_ENTITY_ADMIN) {
        let newSurveyId = null;
        if (state.id && !state.template) {
          surveyRes = await surveyUpdateAPICall(state.id, {
            ...cleanedSurvey,
            preview,
          });
          setBranch((branch) => {
            return {
              ...branch,
              experiences: {
                ...branch.experiences,
                [state.experience.id]: state.id,
              },
            };
          });
        } else {
          surveyRes = await surveyCreateAPICall(surveyData);
          newSurveyId = surveyRes?.id;
          setBranch((branch) => {
            return {
              ...branch,
              experiences: {
                ...branch.experiences,
                [state.experience.id]: surveyRes?.id,
              },
            };
          });
        }
        resetState();
        if (state.redirect) {
          navigate(state.redirect, {
            state: {
              ...state.redirectState,
              experience: state.experience,
              newSurveyId,
              surveyRes,
            },
          });
        } else navigate(URLS.ADD_CUSTOMER_FEEDBACK_FORM_URL);
      }
    } catch (e) {
      showErrorToast(e);
    }
    setLoading(false);
  };

  const checkSimilarityError = () => {
    const isSurveyValid = checkSurveyValidity();
    if (!isSurveyValid) {
      return;
    }
    if (isSimilarErrorActive) {
      setIsOpenSimilarityModal(true);
    } else {
      saveSurvey();
    }
  };

  const handleSetPreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  useEffect(() => {
    if (state?.id) getSurvey();
    if (!state?.experience)
      navigate(URLS.CREATE_SURVEY_TEMPLATE_URL, { state });
  }, [state]);

  useEffect(() => {
    resetState();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      {isMobileDevice && (
        <button className="button button-secondary" onClick={handleSetPreview}>
          {isPreviewOpen ? 'Go to Editing' : 'Go to Preview'}
        </button>
      )}
      {((isMobileDevice && !isPreviewOpen) || !isMobileDevice) && (
        <OnboardingLayout>
          <OnboardingLayout.Main>
            <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
            <OnboardingLayout.Form>
              <label className="control">
                <input
                  type="text"
                  value={survey.title}
                  onChange={handleSurveyTitleChange}
                  className="surveyFormTitle"
                  placeholder="Survey Title"
                />
                {formik.errors.title ? (
                  <span style={{ display: 'block' }} className="control-error">
                    {formik.errors.title}
                  </span>
                ) : null}
              </label>
              <SurveyBuilder
                surveySections={survey.sections}
                setIsSimilarErrorActive={setIsSimilarErrorActive}
                formik={formik}
                duplicateOptionsSections={duplicateOptionsSections}
              />
              <SurveyCustomerDetail
                enableCustomerDetail={survey.is_collecting_customer_info}
                setEnableCustomerDetail={setEnableCustomerDetail}
              />
            </OnboardingLayout.Form>
            <OnboardingLayout.Footer
              continueText="Save Survey Form"
              onClickContinue={checkSimilarityError}
              onClickBack={() => navigate(-1)}
            />
          </OnboardingLayout.Main>
          <OnboardingLayout.Sidepane>
            {((isMobileDevice && isPreviewOpen) || !isMobileDevice) && (
              <SurveyPreview survey={survey} innerRef={ref} />
            )}
          </OnboardingLayout.Sidepane>
          <SimilarityConfirmModal
            onClose={() => setIsOpenSimilarityModal(false)}
            isOpenModal={isOpenSimilarityModal}
            primaryAction={saveSurvey}
            secondaryAction={() => setIsOpenSimilarityModal(false)}
          />
        </OnboardingLayout>
      )}
    </>
  );
}
