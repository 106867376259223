import { useFormik } from 'formik';
import { billingInfoValidationSchema } from 'utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrg } from 'redux/settings/settingsActions';
import { toast } from 'react-toastify';
import { Modal } from 'components/ui';

export default function BillingInfoModal({ isOpenModal, onClose }) {
  const dispatch = useDispatch();
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const isLoading = useSelector((s) => s.settings.isLoading);

  const formik = useFormik({
    initialValues: {
      name: parentEntity.name ?? '',
      email: parentEntity.email ?? '',
    },
    onSubmit: async (values) => {
      dispatch(updateOrg({ ...values, id: parentEntity.id })).then(
        (payload) => {
          if (!payload.error) {
            toast.success('Billing information updated');
            onClose();
          }
        },
      );
    },
    validateOnChange: true,
    validationSchema: billingInfoValidationSchema,
  });

  const { values, errors, touched, handleChange } = formik;

  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header title="Billing Address" onClose={onClose} />
      <Modal.Body>
        <div className="cxmeter-form">
          <label
            className={`control ${
              touched.name && errors.name ? 'control-invalid' : ''
            }`}
          >
            <span className="required-field">
              Name <span>*</span>
            </span>
            <input
              disabled
              name="name"
              type="text"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <span className="control-error">{errors.name}</span>
            )}
          </label>
          <label
            className={`control ${
              touched.email && errors.email ? 'control-invalid' : ''
            }`}
          >
            <span className="required-field">
              Email<span>*</span>
            </span>
            <input
              name="email"
              type="text"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && (
              <span className="control-error">{errors.email}</span>
            )}
          </label>
          {/* eslint-disable-next-line */}
        </div>
      </Modal.Body>
      <Modal.Footer
        secondaryAction={onClose}
        isLoading={isLoading}
        primaryAction={formik.handleSubmit}
        primaryLabel="Update"
      />
    </Modal>
  );
}
