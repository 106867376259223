import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast } from 'utils/helpers';
import {
  compareDateRangeAPICall,
  compareEntityAPICall,
  compareExperienceAPICall,
} from 'apis/analytics';
import {
  entityListAPICallV2,
  getCitiesAPICall,
  getCountriesAPICall,
  getStatesAPICall,
} from 'apis/cxmeter';

export const getComparisonByBranch = createAsyncThunk(
  'branchComparison/getComparisonByBranch',
  async (params) => compareEntityAPICall(params).catch(showErrorToast),
);

export const getComparisonByExperience = createAsyncThunk(
  'branchComparison/getComparisonByExperience',
  async (params) => compareExperienceAPICall(params).catch(showErrorToast),
);

export const getComparisonByDateRange = createAsyncThunk(
  'branchComparison/getComparisonByDateRange',
  async (params) => compareDateRangeAPICall(params).catch(showErrorToast),
);

export const getLocationsV2 = createAsyncThunk(
  'branchComparison/getLocationsV2',
  async (params) => entityListAPICallV2(params).catch(showErrorToast),
);

export const getCountries = createAsyncThunk(
  'branchComparison/getCountries',
  async () => getCountriesAPICall().catch(showErrorToast),
);

export const getStates = createAsyncThunk(
  'branchComparison/getStates',
  async (countryId) => getStatesAPICall(countryId).catch(showErrorToast),
);

export const getCities = createAsyncThunk(
  'branchComparison/getCities',
  async (stateId) => getCitiesAPICall(stateId).catch(showErrorToast),
);
