/* eslint-disable no-return-await */
import {
  COMMENTS_API,
  FEEDBACKS_URL,
  REVIEWS_LISTING_API,
  REVIEW_NOTE_API,
} from './urls';

import { getAPICall, patchAPICall, postAPICall, putAPICall } from './utils';

export async function reviewsListingAPICall(params) {
  return await getAPICall(REVIEWS_LISTING_API, params);
}

export async function reviewDetailAPICall(reviewId) {
  return await getAPICall(`${REVIEWS_LISTING_API}${reviewId}/`);
}

export async function feedbackNoteAPICall(data) {
  return await postAPICall(REVIEW_NOTE_API, data);
}

export async function updateFeedbackNoteAPICall(id, data) {
  return await putAPICall(`${REVIEW_NOTE_API}${id}/`, data);
}

export async function postCommentAPICall(feedbackId, data) {
  return await postAPICall(`${COMMENTS_API(feedbackId)}`, data);
}

export async function resolveFeedbackAPICall(feedbackId, data) {
  return await patchAPICall(`${FEEDBACKS_URL}/${feedbackId}/status/`, data);
}
