import { useLocation, useNavigate } from 'react-router-dom';
import './_settingTabs.scss';
import { useEffect, useState } from 'react';
import { Icon } from 'components/ui';

function SettingTabs({ children }) {
  return <div className="cxmeter-settings">{children}</div>;
}

export function Item({ to, title, actvePaths = [] }) {
  const location = useLocation();
  const navigate = useNavigate();
  const openLink = () => navigate(to);

  const isActivePath = [to, ...actvePaths].find((path) =>
    location.pathname.includes(path),
  );

  return (
    <li>
      <button className={isActivePath && 'active'} onClick={openLink}>
        {title}
      </button>
    </li>
  );
}

function Menu({ children }) {
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  return (
    <div>
      <button
        className="cxmeter-settings-sidebar-toggle"
        onClick={() => setIsActive(!isActive)}
      >
        <Icon type="menu" />
      </button>
      <nav className={`cxmeter-settings-sidebar ${isActive ? 'active' : ''}`}>
        <button
          className="cxmeter-settings-sidebar-close"
          onClick={() => setIsActive(!isActive)}
        >
          <Icon type="close" />
        </button>
        <ul className="cxmeter-settings-menu">{children}</ul>
      </nav>
    </div>
  );
}
function Content({ children }) {
  return <article className="cxmeter-settings-content">{children}</article>;
}

SettingTabs.Item = Item;
SettingTabs.Menu = Menu;
SettingTabs.Content = Content;
export default SettingTabs;
