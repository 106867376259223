import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';

import { QUESTION_CONSTANTS } from 'utils/constants';
import { surveyHelper } from 'utils/helpers';
import { RichTextEditor } from 'components/richTextEditor';
import { Switch } from 'components/switch';
import MultipleChoiceAnswer from '../surveyBuilderAnswers/MultipleChoiceAnswer';
import _ from 'lodash';

import 'draft-js/dist/Draft.css';
import './_surveyBuilderQuestion.scss';

function SurveyBuilderQuestion({
  question,
  sectionId,
  isDeletable,
  setQuestion,
  setQuestionsEdited,
  setIsSimilarErrorActive,
  questionErrors,
  duplicateOptionsSections,
}) {
  const { formatSurveyQuestion, createMarkup } = surveyHelper;
  const {
    ATTACHMENT,
    ATTACHMENTS,
    CHECKBOX,
    QUESTION_TYPES,
    RADIO,
    RATING,
    TEXT,
  } = QUESTION_CONSTANTS;
  const ref = useRef();

  const isRequiredRatingQuestion =
    !isDeletable && question.question_type === 'rating' && question.is_required;

  const initialQuestionRef = useRef(question);
  const [isExpanded, setIsExpanded] = useState(false);

  const hasQuestionChanged = () => {
    const initialQuestion = initialQuestionRef.current;
    const initialOptions = initialQuestion.options.map(
      // eslint-disable-next-line no-underscore-dangle
      (opt) => createMarkup(opt.label).__html,
    );
    const newOptions = question.options.map(
      // eslint-disable-next-line no-underscore-dangle
      (opt) => createMarkup(opt.label).__html,
    );
    const optionsChanged =
      _.difference(initialOptions, newOptions).length !== 0 ||
      _.difference(newOptions, initialOptions).length !== 0;
    if (!initialQuestion.question_uuid) return false;
    return (
      // eslint-disable-next-line no-underscore-dangle
      createMarkup(initialQuestion.label).__html !==
        // eslint-disable-next-line no-underscore-dangle
        createMarkup(question.label).__html ||
      initialQuestion.question_type !== question.question_type ||
      optionsChanged ||
      !_.isEqual(initialQuestion.attachment_types, question.attachment_types) ||
      initialQuestion.is_required !== question.is_required ||
      initialQuestion.max_number_of_choices !== question.max_number_of_choices
    );
  };

  const handleSelectQuestionType = (selectedType) => {
    setQuestion(
      formatSurveyQuestion(
        question.id,
        question.label,
        selectedType,
        question.options,
        question.attachment_types,
        question.is_required,
        question.max_number_of_choices || question.options.length,
        question.question_uuid,
      ),
    );
  };

  const handleSetQuestion = (label) => {
    setQuestion(
      formatSurveyQuestion(
        question.id,
        label,
        question.question_type,
        question.options,
        question.attachment_types,
        question.is_required,
        question.max_number_of_choices,
        question.question_uuid,
      ),
    );
  };

  const handleSetOptions = (options) => {
    setQuestion(
      formatSurveyQuestion(
        question.id,
        question.label,
        question.question_type,
        options,
        question.attachment_types,
        question.is_required,
        question.max_number_of_choices,
        question.question_uuid,
      ),
    );
  };

  const handleRequiredChange = (e) => {
    if (isRequiredRatingQuestion)
      return toast.warn('This Question must be Required');
    return setQuestion(
      formatSurveyQuestion(
        question.id,
        question.label,
        question.question_type,
        question.options,
        question.attachment_types,
        e.target.checked,
        question.max_number_of_choices,
        question.question_uuid,
      ),
    );
  };

  const handleSetMaxAllowedAnswers = (maxAllowedAnswers) => {
    setQuestion(
      formatSurveyQuestion(
        question.id,
        question.label,
        question.question_type,
        question.options,
        question.attachment_types,
        question.is_required,
        maxAllowedAnswers,
        question.question_uuid,
      ),
    );
  };

  const handleAttachmentTypeChange = (event) => {
    const attachmentTypes = [...question.attachment_types] || [];
    const { value } = event.target;
    if (event.target.checked) attachmentTypes.push(value);
    else attachmentTypes.splice(attachmentTypes.indexOf(value), 1);
    setQuestion(
      formatSurveyQuestion(
        question.id,
        question.label,
        question.question_type,
        question.options,
        attachmentTypes,
        question.is_required,
        question.max_number_of_choices,
        question.question_uuid,
      ),
    );
  };

  useEffect(() => {
    if (setQuestionsEdited) {
      setQuestionsEdited(hasQuestionChanged());
    }
  }, [question]);

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="surveyBuilderQuestionWrapper" ref={ref}>
      <div className="surveyBuilderQuestionRow">
        <div className="survey-builder__question-row-question">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <img
            src="/assets/icons/icon_expand.svg"
            style={{ marginTop: '0.75rem' }}
            className={isExpanded ? 'up pointer' : 'down pointer'}
            onClick={() => setIsExpanded(!isExpanded)}
            alt=""
          />
          <div className="editor-container" style={{ flexGrow: 1 }}>
            <RichTextEditor
              placeholder="Type your question here"
              setState={handleSetQuestion}
              content={question.label}
              error={questionErrors?.label?.error}
            />
          </div>
        </div>
        <Dropdown onSelect={handleSelectQuestionType}>
          <Dropdown.Toggle variant="danger" disabled={isRequiredRatingQuestion}>
            {QUESTION_TYPES[question.question_type].label}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(QUESTION_TYPES).map((questionType, index) => (
              <Dropdown.Item eventKey={questionType} key={index}>
                {QUESTION_TYPES[questionType].label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {isExpanded &&
        (question.question_type === CHECKBOX ||
          question.question_type === RADIO ||
          question.question_type === RATING) && (
          <MultipleChoiceAnswer
            sectionId={sectionId}
            questionId={question.id}
            isCompulsoryQuestion={isRequiredRatingQuestion}
            type={question.question_type}
            options={question.options}
            setOptions={handleSetOptions}
            maxAllowedAnswers={question.max_number_of_choices}
            onMaxAllowedAnswersChange={handleSetMaxAllowedAnswers}
            setIsSimilarErrorActive={setIsSimilarErrorActive}
            optionsErrors={questionErrors?.options}
            duplicateOptionsSections={duplicateOptionsSections}
          />
        )}
      {isExpanded && question.question_type === TEXT && (
        <input type="text" disabled className="textInput" />
      )}
      {isExpanded && question.question_type === ATTACHMENT && (
        <div className="attachmentsWrapper">
          <p>Please choose which attachments you want to allow</p>
          <ul className="attachmentsList">
            <li className="attachmentElement">
              <input
                type="checkbox"
                name="attachmentRadio"
                id={`radio-image-${question.id}`}
                value={ATTACHMENTS.IMAGE}
                onChange={handleAttachmentTypeChange}
                defaultChecked
                checked={question.attachment_types.includes(ATTACHMENTS.IMAGE)}
              />
              <label htmlFor={`radio-image-${question.id}`}>Image/ Video</label>
            </li>
            <li className="attachmentElement">
              <input
                type="checkbox"
                name="attachmentRadio"
                id={`audio-image-${question.id}`}
                value={ATTACHMENTS.AUDIO}
                onChange={handleAttachmentTypeChange}
                checked={question.attachment_types.includes(ATTACHMENTS.AUDIO)}
              />
              <label htmlFor="radio-audio">Audio</label>
            </li>
          </ul>
        </div>
      )}
      <div className="questionFooter">
        {isRequiredRatingQuestion ? (
          <small>
            At least one rating question with 3 options is required in the
            survey.
          </small>
        ) : null}
        <div
          className={`compulsory-rating ${
            isRequiredRatingQuestion ? 'disabled-rating' : ''
          }`}
        >
          <p>Required</p>
          <Switch
            onChange={handleRequiredChange}
            checked={question.is_required}
            disabled={isRequiredRatingQuestion}
          />
        </div>
      </div>
    </div>
  );
}

export default SurveyBuilderQuestion;
