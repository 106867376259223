import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import CustomDropdownButton from 'components/customDropdownButton/CustomDropdownButton';
import { getSegments } from 'redux/reviewsListing/reviewsListingActions';

/**
 * @author
 * @function ReviewsList
 * */

import './_reviewsFilters.scss';
import { ComponentLoader } from 'components/loader';

function ReviewFilter({ value, onChange, ...rest }) {
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const { isLoading, data } = useSelector((state) => state.reviews.segments);
  const handleChange = (reviewId) => {
    // eslint-disable-next-line eqeqeq
    const val = data.find((x) => x.id == reviewId);
    setIsAllSelected(reviewId === null);
    if (onChange) onChange(val);
  };

  useEffect(() => {
    dispatch(getSegments());
  }, []);

  if (isLoading) <ComponentLoader />;

  return (
    <div>
      <CustomDropdownButton
        id="questions-list"
        name="question-types"
        value={value?.id}
        onChange={handleChange}
        title={isAllSelected ? 'All' : value?.name || 'Review'}
        {...rest}
      >
        {data?.map((review, i) => (
          <Dropdown.Item
            key={i}
            value={review.id}
            onClick={() => handleChange(review.id)}
            active={review.id === value?.id}
            className="text-capitalize"
          >
            {review.name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          id="all-questions"
          value={null}
          onClick={() => handleChange(null)}
          active={!value}
        >
          All
        </Dropdown.Item>
      </CustomDropdownButton>
    </div>
  );
}

export default ReviewFilter;
