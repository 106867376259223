import { ENTITY_ADMIN } from 'utils/constants';
import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import { isNumberOrParsableString } from 'utils/helpers';
import Yup from './Yup';

export const userSchema = Yup.object().shape({
  first_name: Yup.string()
    .requiredTrimmed(
      'First name cannot be only blank spaces.',
      'First name is required!',
    )
    .matches(onlyLettersAndSpacesRegex, 'First name can only contain letters')
    .range(2, 50),
  last_name: Yup.string()
    .requiredTrimmed(
      'Last name cannot be only blank spaces.',
      'Last name is required!',
    )
    .range(2, 50)
    .matches(onlyLettersAndSpacesRegex, 'Last name can only contain letters'),
  email: Yup.string().required('Email is required!').customEmail(),
  entities: Yup.array().when('type', (type) => {
    if (parseInt(type, 10) === ENTITY_ADMIN) {
      return Yup.array()
        .required('Please select a location')
        .min(1, 'Please select a location');
    }
    return Yup.array();
  }),
  type: Yup.string().trim().required('Type is required!'),
  showPasswordFields: Yup.boolean().required(),
  id: Yup.mixed().nullable(),
  password: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .when(['id', 'showPasswordFields'], {
      is: (id, showPasswordFields) =>
        !isNumberOrParsableString(id) ||
        (isNumberOrParsableString(id) && showPasswordFields),
      then: Yup.string().required('Password is required!'),
      otherwise: Yup.string(),
    }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .when(['id', 'showPasswordFields'], {
      is: (id, showPasswordFields) =>
        !isNumberOrParsableString(id) ||
        (isNumberOrParsableString(id) && showPasswordFields),
      then: Yup.string().required('Confirm your Password!'),
      otherwise: Yup.string(),
    }),
});

export const userUpdateSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too short')
    .max(50, 'Too long')
    .required('First name is required!'),
  last_name: Yup.string()
    .min(2, 'Too short')
    .max(50, 'Too long')
    .required('Last name is required!'),
  email: Yup.string().email('Invalid Email').required('Email is required!'),
  send_alerts: Yup.bool().required('Send Alerts is required!'),
  is_active: Yup.bool().required('Active Status is required!'),
});
