import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

import { LoginWithGoogle } from 'components';
import { usePageTitle } from 'utils/hooks';
import { SIGNUP, URLS } from 'utils/constants';
import { toFormData } from 'utils/helpers';
import { signupSchema } from 'utils/validations';

import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { signupUser } from 'redux/authentication/authenticationActions';

function Signup() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatchWithErrors();

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      tnc: false,
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line no-use-before-define
      await handleSubmit(values);
    },
    validationSchema: signupSchema,
  });

  async function handleSubmit(data) {
    setLoading(true);
    const signupData = toFormData(data);
    const { isError } = await dispatch(signupUser(signupData), formik);
    if (!isError) {
      toast.success('Signup successful');
      navigate(URLS.VERIFY_EMAIL_URL, {
        state: { email: data.email, password: data.password },
      });
    }
    setLoading(false);
  }

  usePageTitle(SIGNUP);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header showLogoutButton={false} showStepper={false} />
        <OnboardingLayout.Form title="Let's get you started">
          <div className="auth-content">
            <LoginWithGoogle />
            <div className="auth-divider">or</div>
            <h2 className="onboarding-field-title">
              Sign up using email address
            </h2>
            <Form onSubmit={formik.handleSubmit} className="auth-form">
              <label
                className={`control ${
                  formik.touched.first_name && formik.errors.first_name
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="first_name"
                  type="text"
                  placeholder="First Name *"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.first_name && (
                  <span className="control-error">
                    {formik.errors.first_name}
                  </span>
                )}
              </label>
              <label
                className={`control ${
                  formik.touched.last_name && formik.errors.last_name
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="last_name"
                  type="text"
                  placeholder="Last Name *"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
                {formik.touched.last_name && (
                  <span className="control-error">
                    {formik.errors.last_name}
                  </span>
                )}
              </label>

              <label
                className={`control ${
                  formik.touched.email && formik.errors.email
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address *"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && (
                  <span className="control-error">{formik.errors.email}</span>
                )}
              </label>
              <label
                className={`control ${
                  formik.touched.password && formik.errors.password
                    ? 'control-invalid'
                    : ''
                }`}
              >
                <input
                  name="password"
                  type="password"
                  placeholder="Password *"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && (
                  <span className="control-error">
                    {formik.errors.password}
                  </span>
                )}
              </label>
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <label className="checkbox">
                <input
                  name="tnc"
                  id="tnc"
                  type="checkbox"
                  checked={formik.values.tnc ? true : null}
                  onChange={formik.handleChange}
                />
                <span>
                  I agree to CXMeter
                  <Link to={URLS.TERMS_AND_CONDITIONS_URL} target="_blank">
                    {' '}
                    terms and conditions.{' '}
                  </Link>
                  See our
                  <Link to={URLS.PRIVACY_POLICY_URL} target="_blank">
                    {' '}
                    privacy policy{' '}
                  </Link>
                  for details on our privacy practices.
                </span>
                {formik.touched.tnc && (
                  <span className="txt-error">{formik.errors.tnc}</span>
                )}
              </label>

              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                Create Account
              </button>
            </Form>
            <span className="askLogin">
              Already have an account? <Link to={URLS.LOGIN_URL}>Log in</Link>
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}

export default Signup;
