import * as URLS from 'utils/constants/urlConstants';

export const ANONYMOUS = 'Anonymous';
export const HAPPY = 'happy';
export const UNHAPPY = 'unhappy';
export const NEUTRAL = 'neutral';

export const LOGIN = 'Login';
export const SIGNUP = 'Sign Up';
export const DASHBOARD = 'Dashboard';
export const BRANCH_COMPARISON = 'Compare Locations';
export const FEEDBACK_LISTING = 'Feedbacks';
export const REPORT = 'Report';
export const INSIGHT = 'Insight';
export const METRIC = 'Metric';
export const SETTINGS = 'Settings';
export const CX_BADGE = 'CX Badge';
export const ORGANIZATION = 'Select Organization';
export const CREATE_SURVEY = 'Create Survey';
export const CREATE_SURVEY_TEMPLATE = 'Templates';
export const DIGEST = 'Digest';
export const LUCKY_DRAW = 'Lucky Draw';
export const VERIFICATION = 'Verification';
export const TERMS_AND_CONDITIONS = 'Terms & Conditions';
export const SAAS_AGREEMENT = 'SaaS Agreement';
export const REGISTER_ORG = 'Register Organization';
export const RESET_PASSWORD = 'Reset Password';
export const REVIEWS_LISTING = 'Feedbacks';
export const PARENT_ENTITY_ADMIN = 1;
export const ENTITY_ADMIN = 2;
export const FINANCIAL_ADMIN = 3;

export const UNPROCESSED_STATUS = 'UNP';
export const RESOLVED_STATUS = 'RES';

export const UNPROCESSED = 'Unprocessed';
export const RESOLVED = 'Resolved';
export const RESTROOM = 'Restroom';

export const FORBIDDEN_CODE = 403;
export const UNAUTHORIZED_CODE = 401;
export const BAD_REQUEST_CODE = 400;

export const DEFAULT_PAGE_SIZE = 10;

export const MAX_IMAGE_SIZE = 1024 * 1024 * 10; // 10 MB
export const MIN_IMAGE_SIZE_FOR_COMPRESSION = 1024 * 1024; // 1MB
export const MIN_IMAGE_SIZE_AFTER_COMPRESSION = 1024 * 300; // 300KB

export const TIMELINE_MONTH = 'month';

export const OVERRIDE_STRINGS = {
  allItemsAreSelected: 'All',
  search: 'Select Location',
  selectSomeItems: 'Select Locations',
  selectAll: 'All',
};

export const DELIVERY = 'Delivery';
export const DRIVE_THRU = 'Drive Thru';

export const ON_TIME = 'On Time';
export const ORDER_ACCURACY = 'Order Accuracy';

export const CXMETER = 'CXMeter';

export const PERFORMANCE = {
  EXCEPTIONAL: 'Exceptional',
  OUTSTANDING: 'Outstanding',
  GREAT_GOING: 'Great Going',
  OTHERS: [
    "Let's push harder",
    "Let's keep going",
    'Never give up',
    "Let's try again",
    "Let's do our best",
    'Give it your all',
    'Stay focused',
  ],
};

export const DEVICES = {
  LAPTOP: 'LAPTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
};

export const BASIC_DETAIL_STEP = { name: 'Organization Detail' };
export const FEEDBACK_FORM_STEP = { name: 'Form Setup', link: '/' };
export const BRANCH_SETUP_STEP = {
  name: 'Location Setup',
  link: URLS.BRANCH_LIST_URL,
};
export const PAYMENT_SETUP_STEP = {
  name: 'Payment Setup',
  link: URLS.PAYMENT_URL,
};
export const SAAS_AGREEMENT_ONBOARDING_STEP = {
  name: 'SaaS Agreement',
  link: URLS.ONBOARDING_SAAS_AGREEMENT,
};
export const ONBOARDING_STEPS = [
  BASIC_DETAIL_STEP,
  BRANCH_SETUP_STEP,
  SAAS_AGREEMENT_ONBOARDING_STEP,
  PAYMENT_SETUP_STEP,
];

// rating api ranking
export const TOP_PERFORMING = 1;
export const WORST_PERFORMING = 2;
export const TABLE_PAGE_SIZE = 10;
export const STATUS = [
  { id: UNPROCESSED_STATUS, name: 'Unprocessed' },
  { id: RESOLVED_STATUS, name: 'Resolved' },
];

export const REVIEWS_TABLE_HEADERS = [
  { dataField: 'entity', text: 'Location', sort: true },
  { dataField: 'experience', text: 'Customer Experience', sort: true },
  { dataField: 'segment', text: 'Review', sort: true },
  { dataField: 'created_at', text: 'Date & Time', sort: true },
  { dataField: 'status', text: 'Status', sort: true },
];

export const DESCENDING = 'desc';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';

export const PRIMARY_PAYMENT_CARD_ACTIONS = [
  {
    label: 'Edit',
    key: 'edit',
  },
];
export const BACKUP_PAYMENT_CARD_ACTIONS = [
  ...PRIMARY_PAYMENT_CARD_ACTIONS,
  {
    label: 'Remove',
    key: 'remove',
  },
  {
    label: 'Make Primary',
    key: 'make_primary',
  },
];

export const APP_ENV = {
  local: 'LOCAL',
  dev: 'DEV',
  stage: 'STAGE',
  prod: 'PROD',
};

export const ALLOWED_SURVEY_IMAGES_TYPES = [
  'blp',
  'bmp',
  'dib',
  'bufr',
  'cur',
  'pcx',
  'dcx',
  'dds',
  'ps',
  'eps',
  'fit',
  'fits',
  'fli',
  'flc',
  'ftc',
  'ftu',
  'gbr',
  'gif',
  'grib',
  'h5',
  'hdf',
  'png',
  'apng',
  'jp2',
  'j2k',
  'jpc',
  'jpf',
  'jpx',
  'j2c',
  'icns',
  'ico',
  'im',
  'iim',
  'tif',
  'tiff',
  'jfif',
  'jpe',
  'jpg',
  'jpeg',
  'mpg',
  'mpeg',
  'mpo',
  'msp',
  'palm',
  'pcd',
  'pxr',
  'pbm',
  'pgm',
  'ppm',
  'pnm',
  'psd',
  'bw',
  'rgb',
  'rgba',
  'sgi',
  'ras',
  'tga',
  'icb',
  'vda',
  'vst',
  'webp',
  'wmf',
  'emf',
  'xbm',
  'xpm',
];

export const ALLOWED_SURVEY_IMAGES_TYPES_STRING =
  ALLOWED_SURVEY_IMAGES_TYPES.map((type) => `.${type}`).join(',');

export const PAGINATED_RESPONSE = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};
