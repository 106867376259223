/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'components';
import { OrganizationForm } from 'components/forms';
import { useDispatch, useSelector } from 'react-redux';
import { getOrg, updateOrg } from 'redux/settings/settingsActions';

import './_regOrganisation.scss';
import { useFormik } from 'formik';
import { regOrganizationSchema } from 'utils/validations';
import { useScreenshot } from 'utils/hooks';
import { dataURLtoFile } from 'utils/helpers';

export default function UpdateOrganisation() {
  const dispatch = useDispatch();
  const { org, isLoading } = useSelector((state) => state.settings);
  const avatarRef = useRef();
  const [, takeScreenshot] = useScreenshot();

  const onSubmit = async ({ name, logo, email, phone_number }) => {
    const payload = { name, logo, email, phone_number };
    if (!payload.logo) {
      payload.logo = dataURLtoFile(await takeScreenshot(avatarRef.current));
    }
    if (typeof payload.logo === 'string') {
      delete payload.logo;
    }
    if (!payload.email) {
      payload.email = '';
    }
    if (!payload.phone_number) {
      payload.phone_number = '';
    }
    dispatch(updateOrg({ id: org.id, ...payload })).then((action) => {
      if (!action.error) {
        toast.success('Organization Updated successfully');
      }
    });
  };

  const form = useFormik({
    initialValues: org,
    onSubmit,
    validationSchema: regOrganizationSchema,
  });

  useEffect(() => {
    dispatch(getOrg());
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="onboarding-content">
      <OrganizationForm form={form} isEditing avatarRef={avatarRef} />
      <button
        type="button"
        className="button button-primary"
        onClick={form.handleSubmit}
        disabled={!form.dirty}
      >
        Update
      </button>
    </div>
  );
}
