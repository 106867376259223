/* eslint-disable no-use-before-define */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { showErrorToast } from 'utils/helpers';
import { changePasswordSchema } from 'utils/validations';
import { changePassword } from 'redux/authentication/authenticationActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import './_changePassword.scss';

const initialValues = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

function ChangePassword() {
  const dispatch = useDispatchWithErrors();
  const { user: currentUser } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      await handleSubmit(values, resetForm);
    },
    validationSchema: changePasswordSchema(currentUser.is_google_login),
  });

  async function handleSubmit(data, resetForm) {
    try {
      const { isError } = await dispatch(
        changePassword({ id: currentUser.id, data }),
        formik,
      );
      if (!isError) {
        resetForm();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      resetForm();
      showErrorToast(error);
    }
  }

  return (
    <article className="password-change-container">
      <header className="heading">
        <h3>
          {currentUser.is_google_login ? 'Add ' : 'Change '}
          Password
        </h3>
        <p>Update or reset your password here.</p>
      </header>
      <form className="cxmeter-form small" onSubmit={formik.handleSubmit}>
        {!currentUser.is_google_login && (
          <label
            className={`control ${
              formik.touched.old_password && formik.errors.old_password
                ? 'control-invalid'
                : ''
            }`}
          >
            <span className="required-field">
              Old Password<span>*</span>
            </span>
            <input
              name="old_password"
              type="password"
              placeholder="Old Password"
              value={formik.values.old_password}
              onChange={formik.handleChange}
            />
            {formik.touched.old_password && (
              <span className="control-error">
                {formik.errors.old_password}
              </span>
            )}
          </label>
        )}
        <div className="control-group">
          <label
            className={`control ${
              formik.touched.new_password && formik.errors.new_password
                ? 'control-invalid'
                : ''
            }`}
          >
            <span className="required-field">
              New Password<span>*</span>
            </span>
            <input
              name="new_password"
              type="password"
              placeholder="New Password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
            />
            {formik.touched.new_password && (
              <span className="control-error">
                {formik.errors.new_password}
              </span>
            )}
          </label>

          <label
            className={`control ${
              formik.touched.confirm_password && formik.errors.confirm_password
                ? 'control-invalid'
                : ''
            }`}
          >
            <span className="required-field">
              Confirm Password<span>*</span>
            </span>
            <input
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
            />
            {formik.touched.confirm_password && (
              <span className="control-error">
                {formik.errors.confirm_password}
              </span>
            )}
          </label>
        </div>
        <button
          aria-busy={loading ? true : null}
          className="button button-primary"
          type="submit"
        >
          Save Password
        </button>
      </form>
    </article>
  );
}

export default ChangePassword;
