import React from 'react';
import '../_qrSetup.scss';
import { ColorPicker } from 'antd';

export default function ColorSelector({ value, onChange, ...props }) {
  return (
    <span className="colorSelectorContainer">
      <ColorPicker
        value={value}
        onChange={(color) => {
          onChange(color.toHexString());
        }}
        {...props}
      />
    </span>
  );
}
