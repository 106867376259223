// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';

export const calculatePointsRadius = (countMap, maxItemRadius) => {
  const sumOfRatings = _.sum(Object.values(countMap));
  const ratingsShareMap = {};
  // get the proportional shares of each rating.
  Object.keys(countMap).forEach((r) => {
    ratingsShareMap[r] = +(
      (countMap[r] / sumOfRatings) *
      maxItemRadius
    ).toFixed(2);
  });
  const highestRating = _.max(Object.values(ratingsShareMap));
  // Elevate proportional shares of each rating to maxItemRadius.
  Object.keys(ratingsShareMap).forEach((r) => {
    ratingsShareMap[r] = +(
      (ratingsShareMap[r] / highestRating) *
      maxItemRadius
    ).toFixed(2);
  });
  return ratingsShareMap;
};

export const generateRandomNumber = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertToGraphData = function (data) {
  return data.map((i) => {
    return {
      key: i.y,
      value: i.rating_stats,
    };
  }, {});
};
