/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faSpinner,
  faTrash,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import galleryIcon from '../../assets/icons/icon_gallery.svg';
import { Popconfirm } from 'antd';

import './_surveyThumbnail.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default function SurveyThumbnail({
  data,
  onChange,
  selected,
  template,
  onEdit,
  icon,
  isDeleteLoading = false,
  onDelete,
  onCopy,
  isCopyLoading,
}) {
  function handleDuplicateClick() {
    if (!isCopyLoading) {
      onCopy();
    }
  }

  return (
    <div
      className={`addItem ${selected ? 'selectedItem' : ''} ${
        isDeleteLoading ? 'disabled-actions' : ''
      }`}
      tabIndex={0}
      role="button"
      onClick={() => onChange?.(data)}
    >
      {template && <div className="template">template</div>}

      <div className="preview">
        <img src={data.preview} alt="survey thumbnail" />
        <img
          src={icon || galleryIcon}
          alt="experience"
          className="experienceIcon"
        />
      </div>

      <p className="survey-title">{data.title}</p>
      <div className="actions">
        {onEdit && (
          <FontAwesomeIcon
            className="actionIcon"
            icon={faPencilAlt}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          />
        )}
        {onDelete && !template && data.is_deletable && (
          <Popconfirm
            title="Are You Sure"
            description="Do you want to delete this survey?"
            onConfirm={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <FontAwesomeIcon
              className="actionIcon"
              icon={isDeleteLoading ? faSpinner : faTrash}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        )}
        {onCopy && !template && (
          <FontAwesomeIcon
            className="actionIcon"
            icon={isCopyLoading ? faSpinner : faCopy}
            onClick={(e) => {
              e.stopPropagation();
              handleDuplicateClick();
            }}
          />
        )}
      </div>
    </div>
  );
}
