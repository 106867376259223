/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './_dateFilter.scss';
import { Popover } from 'antd';
import DateSelector from './DateSelector';
import { Icon } from 'components/ui';

function DateFilter({ value, onChange, showText }) {
  const [showSelector, setShowSelector] = useState(false);

  const togglePicker = () => {
    setShowSelector(!showSelector);
  };

  const onDateChange = (val) => {
    onChange(val);
    togglePicker();
  };

  return (
    <Popover
      content={<DateSelector value={value} onChange={onDateChange} />}
      trigger="click"
      open={showSelector}
      onOpenChange={setShowSelector}
      overlayClassName="datePopOver"
    >
      <div className="dropdown">
        <button
          className="CalanderBtn"
          id="dropdown-basic"
          type="button"
          onClick={togglePicker}
        >
          {showText ? (
            <>
              {showText}
              <Icon type="arrow_drop_down" />
            </>
          ) : (
            <Icon type="calendar_month" />
          )}
        </button>
      </div>
    </Popover>
  );
}

export default DateFilter;
