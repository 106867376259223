import { SAAS_AGREEMENT_VERSION } from 'config';
import { useFormik } from 'formik';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateParentEntity } from 'redux/authentication/authenticationActions';
import { setLastOnboardingStep } from 'redux/authentication/authenticationSlice';
import { SAAS_AGREEMENT_ONBOARDING_STEP, URLS } from 'utils/constants';
import { getFullURL } from 'utils/helpers';
import SaasAgreement from './SaasAgreement';
import { saveSaasAgreementVersion } from 'redux/saasAgreement/saasAgreementActions';

function OnboardingSaasAgreement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, agreement } = useSelector((s) => s.saasAgreement);
  const { name } = useSelector((state) => state.authentication.parentEntity);

  const form = useFormik({
    initialValues: {
      saasChecked: false,
    },
    onSubmit: () => {
      if (agreement?.version_number) {
        navigate(URLS.PAYMENT_PLANS_URL);
      } else {
        dispatch(
          updateParentEntity({
            last_onboarding_url: getFullURL(URLS.PAYMENT_PLANS_URL),
            name,
          }),
        );
        dispatch(setLastOnboardingStep(URLS.PAYMENT_PLANS_URL));
        navigate(URLS.PAYMENT_PLANS_URL);

        dispatch(
          saveSaasAgreementVersion({ version_number: SAAS_AGREEMENT_VERSION }),
        ).then(() => {
          navigate(URLS.PAYMENT_PLANS_URL);
        });
      }
    },
  });

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={SAAS_AGREEMENT_ONBOARDING_STEP} />
        <OnboardingLayout.Form title="Saas Agreement">
          <SaasAgreement />
          <label className="checkbox">
            <input
              name="saasChecked"
              id="saasChecked"
              type="checkbox"
              checked={form.values.saasChecked}
              onChange={form.handleChange}
            />
            <span>I agree to CXMeter SaaS Agreement</span>
            {form.touched.saasChecked && (
              <span className="txt-error">{form.errors.saasChecked}</span>
            )}
          </label>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={isLoading}
          onClickContinue={form.submitForm}
          disableContinue={!form.values.saasChecked}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}

export default OnboardingSaasAgreement;
