import { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Loader } from 'components';
import { URLS } from 'utils/constants';
import { entityListAPICallV2 } from 'apis/cxmeter';

import './_locationList.scss';
import { useNavigate } from 'react-router-dom';
import { LocationThumbnail } from 'components/locationThumbnail';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import LocationStatusToggleModal from 'components/modals/LocationStatusToggleModal';

export default function LocationList() {
  const [loading, setLoading] = useState(false);
  const [branchChangeError, setBranchChangeError] = useState('');
  const [branches, setBranches] = useState([]);
  const { isLoading } = useSelector((state) => state.settings);
  const modalRef = useRef();
  const navigate = useNavigate();

  const addBranch = () => {
    navigate(`${URLS.BRANCH_SETUP_SETTINGS_URL}/new`);
  };

  const openBranch = (id) => {
    navigate(`${URLS.BRANCH_SETUP_SETTINGS_URL}/${id}`);
  };

  const fetchBranchList = async () => {
    setLoading(true);
    await entityListAPICallV2()
      .then((data) => {
        setLoading(false);
        setBranches(data);
      })
      .catch(() => setLoading(false));
  };

  const onBranchStatusToggle = (id) => {
    const newBranches = [...branches];
    const target = newBranches.find((branch) => branch.id === id);
    if (target) target.is_active = !target.is_active;
    setBranches(newBranches);
  };

  useEffect(() => {
    fetchBranchList();
  }, []);

  return (
    <>
      <LocationStatusToggleModal ref={modalRef} />
      <div>
        {loading && <Loader />}
        {branchChangeError ? (
          <Alert
            type="error"
            closable
            onClose={() => setBranchChangeError('')}
            message={branchChangeError}
          />
        ) : null}
        <Col md={12}>
          <div className="formContainer">
            <div className="fl-1">
              <div>
                <h1>Location Information</h1>
                <p>View and update location information here.</p>
              </div>
              <div className="mb-20">
                {branches.map((branch, index) => (
                  <LocationThumbnail
                    location={branch}
                    onEdit={() => openBranch(branch.id)}
                    key={index}
                    isActive={branch.is_active}
                    onStatusChange={() =>
                      modalRef.current.open(branch, onBranchStatusToggle)
                    }
                    isLoading={isLoading}
                  >
                    {branch.name}
                  </LocationThumbnail>
                ))}
                <LocationThumbnail onSelect={addBranch}>
                  <FontAwesomeIcon icon={faPlus} />
                </LocationThumbnail>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
}
