import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentPlan } from 'components';
import { PAYMENT_SETUP_STEP, URLS } from 'utils/constants';
import { fetchPaymentPlansAPICall } from 'apis/payments';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribedPlan } from 'redux/payments/paymentsActions';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';

import './_paymentPlans.scss';

import { usePageTitle } from 'utils/hooks';
import { useTranslation } from 'react-i18next';

function PaymentPlans() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: subscribedPlan, subscribedPlanLoading } = useSelector(
    (s) => s.payments?.subscribedPlan ?? {},
  );
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate = useNavigate();
  const {
    parentEntity: { type_name: orgType },
  } = useSelector((state) => state.authentication);

  usePageTitle('Payment Setup');

  const fetchPaymentPlans = async () => {
    setLoading(true);
    setPlans(await fetchPaymentPlansAPICall());
    setLoading(false);
  };

  const handlePlanSubscription = () => {
    if (selectedPlan)
      navigate(URLS.BRANCH_ACTIVATION_URL, { state: { plan: selectedPlan } });
  };

  const onPlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    if (subscribedPlan && !subscribedPlan.is_active) {
      fetchPaymentPlans();
    } else if (subscribedPlan?.is_active) {
      navigate(URLS.PAYMENT_URL);
    }
  }, [subscribedPlan?.is_active]);

  useEffect(() => {
    dispatch(getSubscribedPlan());
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={PAYMENT_SETUP_STEP} />
        <OnboardingLayout.Form
          title="Subscription Plans"
          subtitle={`Select a subscription plan that best describes your ${
            orgType || 'organization'
          } needs.`}
        >
          <div className="templatesList--payment templatesList">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`addItem addItem--payment ${
                  selectedPlan?.id === plan.id && 'selectedItem'
                }`}
                onClick={() => onPlanSelect(plan)}
                role="button"
                tabIndex={0}
              >
                <h4>{plan.title}</h4>
                {plan.overall_cost ? (
                  <div className="planPrice">
                    <h3 className="planCost">${plan.overall_cost}</h3>
                    <p>total</p>
                  </div>
                ) : (
                  <>
                    {plan.cost_per_user ? (
                      <div className="planPrice">
                        <h3 className="planCost">${plan.cost_per_user}</h3>
                        <p style={{ lineHeight: 1 }}>per user</p>
                      </div>
                    ) : null}
                    {plan.cost_per_branch ? (
                      <div className="planPrice">
                        <h3 className="planCost">${plan.cost_per_branch}</h3>
                        <p style={{ lineHeight: 1 }}>per location</p>
                      </div>
                    ) : null}
                  </>
                )}
                {plan.trial_period_days && (
                  <div className="planPrice">
                    <p style={{ lineHeight: 1 }}>
                      {t('settings.payments.plans.trialPeriod', {
                        days: plan.trial_period_days,
                      })}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          isLoading={loading || subscribedPlanLoading || !subscribedPlan}
          continueText="Continue to Payment"
          onClickContinue={handlePlanSubscription}
          disableContinue={!selectedPlan}
          hideBackButton
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {selectedPlan ? <PaymentPlan plan={selectedPlan} /> : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}

export default PaymentPlans;
