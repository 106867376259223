import { SurveyThumbnail } from 'components/surveyThumbnail';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityLinkedExperiences } from 'redux/experience/experienceActions';
import { resetEntityLinkedExperiences } from 'redux/experience/experienceSlice';
import { CXMETER_TEMPLATES_TAB } from 'utils/constants/manageEmails';

function FeedbackTemplatesList({
  formik,
  apiParams,
  loadMoreHandler,
  onConfirm,
}) {
  const dispatch = useDispatch();
  const {
    entitiyLinkedExperiences: {
      data: { count, results: experiences },
      loading,
    },
  } = useSelector((s) => s.experience);
  const [activeTab, setActiveTab] = useState(CXMETER_TEMPLATES_TAB);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const onSelecteExperience = () => {
    if (selectedExperience)
      formik.setFieldValue('entity_experience', selectedExperience);
    onConfirm();
  };

  useEffect(() => {
    const params = { ...apiParams };
    if (params.page === 1 && formik.values.entity_experience) {
      params.selected_ee_id = formik.values.entity_experience.id;
    }
    dispatch(getEntityLinkedExperiences(params));
  }, [apiParams]);

  useEffect(
    () => () => {
      dispatch(resetEntityLinkedExperiences());
    },
    [],
  );

  return (
    <>
      <div className="feedback-tmeplates">
        <div className="feedback-tmeplates-tabs manage-emails-tabs">
          <button
            className={`manage-emails-tab ${
              activeTab === CXMETER_TEMPLATES_TAB ? 'active' : ''
            }`}
            onClick={() => setActiveTab(CXMETER_TEMPLATES_TAB)}
          >
            Entity Experiences
          </button>
        </div>
        <div className="linked-experiences-container">
          {loading && !count ? (
            <p style={{ textAlign: 'center', color: '#de0024', marginTop: 1 }}>
              Loading...
            </p>
          ) : null}
          {experiences.length ? (
            experiences.map((exp, index) => {
              const hideFeedbackCard =
                index !== 0 && experiences[0].id === exp.id;
              return hideFeedbackCard ? null : (
                <SurveyThumbnail
                  selected={
                    (selectedExperience?.id ||
                      formik.values.entity_experience?.id) === exp.id
                  }
                  onChange={() => setSelectedExperience(exp)}
                  key={exp.id}
                  icon={exp.experience_icon}
                  data={exp}
                />
              );
            })
          ) : loading ? null : (
            <p>No Experience Found</p>
          )}
        </div>
        {count > experiences.length ? (
          <button
            className="load-more-btn"
            disabled={loading}
            style={{ opacity: loading ? 0.75 : 1 }}
            onClick={loadMoreHandler}
          >
            {loading ? 'Loading ...' : 'Load More'}
          </button>
        ) : null}
      </div>
      <button
        disabled={!selectedExperience && !formik.values.entity_experience}
        style={{ alignSelf: 'flex-end' }}
        className="cstm-btn secondary-cstm-btn confirm-overlay-value-btn"
        onClick={onSelecteExperience}
      >
        Confirm
      </button>
    </>
  );
}

export default FeedbackTemplatesList;
