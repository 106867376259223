export const QUESTION_TYPES = {
  radio: { id: 'radio', label: 'Select a single option only' },
  checkbox: { id: 'checkbox', label: 'Select Multiple Options' },
  rating: { id: 'ratings', label: 'Ratings' },
  text: { id: 'text', label: 'Provide text based answer' },
  attachment: { id: 'attachment', label: 'Upload a picture/video/audio' },
};

export const RADIO = 'radio';
export const CHECKBOX = 'checkbox';
export const TEXT = 'text';
export const ATTACHMENT = 'attachment';
export const RATING = 'rating';

export const ATTACHMENTS = { IMAGE: 'image/video', AUDIO: 'audio' };

export const QUESTION_TYPES_LIST = [
  { id: RADIO, label: 'Single option Question' },
  { id: CHECKBOX, label: 'Multiple Options Question' },
  { id: TEXT, label: 'Text based answer' },
  { id: ATTACHMENT, label: 'Picture/video/audio based' },
  { id: RATING, label: 'Ratings' },
];

export const OPTION_TYPE_MAPPING = {
  [RADIO]: 'radio_option',
  [CHECKBOX]: 'checkbox_option',
  [RATING]: 'rating_option',
};
