export const LOGIN_URL = '/';
export const SIGNUP_URL = '/signup';
export const ADD_CUSTOMER_FEEDBACK_FORM_URL = '/signup/add-feedback-form';
export const VERIFY_EMAIL_URL = '/signup/verify-email';
export const SUCCESSFUL_SIGNUP = '/signup/success';
export const ONBOARDING_SAAS_AGREEMENT = '/signup/saas-agreement';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const RESET_PASSWORD_URL = '/reset-password';
export const RESET_PASSWORD_SUCCESS_URL = '/reset-password-success';
export const ORGANIZATION_URL = '/organization';
export const ADMIN_CREATE_SURVEY_URL = '/create-survey';
export const ADMIN_SURVEY_TEMPLATES = '/survey-templates';
export const ADMIN_CREATE_SURVEY_TEMPLATE_URL = '/create-survey-template';
export const REGISTER_ORG_URL = '/signup/register-org';
export const GENERATE_QRS_URL = '/generate-qrs';
export const DASHBOARD_URL = '/dashboard';
export const REPORT_URL = '/report';
export const INSIGHT_URL = '/insight';
export const METRIC_URL = '/metric';
export const SETTINGS_URL = '/settings';
export const USERS_URL = `${SETTINGS_URL}/users`;
export const UPDATE_ORG_SETTINGS_URL = `${SETTINGS_URL}/update-org`;
export const BRANCH_SETUP_SETTINGS_URL = `${SETTINGS_URL}/branch-setup`;
export const INVOICES_SETTINGS_URL = `${SETTINGS_URL}/invoices`;
export const SURVEYS_URL = `${SETTINGS_URL}/surveys`;
export const FEEDBACK_FORMS_SETTINGS_URL = `${SETTINGS_URL}/feedback-forms`;
export const FEEDBACK_FORMS_DETAIL_SETTINGS_URL = `${FEEDBACK_FORMS_SETTINGS_URL}/:id`;
export const PAYMENT_DASHBOARD_SETTINGS_URL = `${SETTINGS_URL}/payment-dashboard`;
export const PAYMENT_PLANS_SETTINGS_URL = `${SETTINGS_URL}/payment-plans`;
export const MANAGE_EMAILS_SETTINGS_URL = `${SETTINGS_URL}/manage-emails`;
export const EDIT_PROFILE_URL = '/edit-profile';
export const EDIT_USER_PROFILE_URL = `${EDIT_PROFILE_URL}/edit-user`;
export const CHANGE_PASSWORD = '/edit-profile/change-password';
export const CX_BADGE_URL = '/cx-badge';
export const LUCKY_DRAW_URL = '/lucky-draw';
export const DIGEST_URL = '/digest';
export const FEEDBACK_NOTE_URL = 'feedback-note';
export const COMPARE_BRANCH_URL = '/compare-branch';
export const DASHBOARD_FEEDBACK_NOTE_URL = '/dashboard/feedback-note';
export const TERMS_AND_CONDITIONS_URL =
  'https://www.cxmeter.io/TermsConditions.html';
export const PRIVACY_POLICY_URL = 'https://www.cxmeter.io/PrivacyPolicy.html';
export const SIGNUP_WELCOME_URL = '/signup/welcome';
export const FEEDBACK_FORM_LIST_URL = '/signup/your-feedback-form-list';
export const PAYMENT_URL = '/signup/payment';
export const PAYMENT_PLANS_URL = '/signup/payment-plans';
export const BRANCH_ACTIVATION_URL = '/signup/branch/activation';
export const NOT_FOUND_URL = '*';
export const REVIEWS_LISTING_URL = '/reviews-listing';
export const SIGNUP_BRANCH_URL = '/signup/branch';
export const BRANCH_LIST_URL = `${SIGNUP_BRANCH_URL}/all`;
export const BRANCH_SETUP_URL = `${SIGNUP_BRANCH_URL}/setup`;
export const BRANCH_FORM_SETUP_URL = `${SIGNUP_BRANCH_URL}/experience`;
export const CREATE_SURVEY_URL = `${SIGNUP_BRANCH_URL}/survey/create`;
export const SURVEY_TEMPLATES = `${SIGNUP_BRANCH_URL}/survey/templates`;
export const CREATE_SURVEY_TEMPLATE_URL = `${SIGNUP_BRANCH_URL}/survey/templates/create`;
export const SAAS_AGREEMENT_URL = '/saas_agreement';
