import { createSlice } from '@reduxjs/toolkit';
import { createUser, getUserList, updateUser } from './usersActions';

const INITIAL_STATE = {
  userList: {
    isLoading: false,
    data: [],
    count: 0,
  },
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.userList.isLoading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.userList.isLoading = false;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.userList.count = action.payload.count;
      state.userList.isLoading = false;
      state.userList.data = action.payload.results;
    });

    builder.addCase(createUser.pending, (state) => {
      state.userList.isLoading = true;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.userList.isLoading = false;
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.userList.isLoading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.userList.isLoading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.userList.isLoading = false;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.userList.isLoading = false;
    });
    return builder;
  },
});

export default usersSlice;
