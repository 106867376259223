import { Icon } from 'components/ui';
import { useEffect, useState } from 'react';
import './modal.scss';

function ModalHeader({
  inputName,
  isEditable,
  formik,
  title,
  onClose,
  icon = 'close',
}) {
  return (
    <header className="cxmeter-modal-header">
      {isEditable ? (
        <div className="edit-invite-group-name">
          <input
            type="text"
            name="name"
            onChange={formik.handleChange}
            value={formik.values[inputName]}
          />
          {formik.touched[inputName] && (
            <span style={{ display: 'block' }} className="control-error">
              {formik.errors[inputName]}
            </span>
          )}
        </div>
      ) : (
        <h2>{title}</h2>
      )}
      <button type="button" onClick={onClose} className="cxmeter-modal-close">
        <Icon type={icon} />
      </button>
    </header>
  );
}

function ModalFooter({
  primaryAction,
  secondaryAction,
  isLoading = false,
  disablePrimaryAction = false,
  primaryLabel = 'Ok',
  secondaryLabel = 'Cancel',
  children,
}) {
  return (
    <footer className="cxmeter-modal-footer">
      {children ?? (
        <>
          {secondaryAction ? (
            <button
              onClick={secondaryAction}
              className="button button-secondary"
              type="button"
            >
              {secondaryLabel}
            </button>
          ) : null}
          <button
            aria-busy={isLoading ? true : null}
            onClick={primaryAction}
            disabled={disablePrimaryAction}
            className="button button-primary"
            type="button"
          >
            {primaryLabel}
          </button>
        </>
      )}
    </footer>
  );
}

function ModalBody({ children }) {
  return children;
}

export default function Modal({
  children,
  open,
  onClose,
  position = 'center',
  fullHeight = false,
  className = '',
  backDropClose = true,
}) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (open !== isOpen) setIsOpen(open);
  }, [open]);

  const backdropClickHandler = () => {
    if (backDropClose) {
      onClose();
    }
  };

  return (
    <article
      className={`cxmeter-modal ${isOpen ? 'cxmeter-modal-open' : ''} ${
        position !== 'center' ? `cxmeter-modal-${position}` : ''
      } ${fullHeight ? 'cxmeter-modal-full' : ''} ${className}`}
    >
      <div
        className="cxmeter-modal-backdrop"
        role="presentation"
        onClick={backdropClickHandler}
      />{' '}
      <div className="cxmeter-modal-body">{children}</div>
    </article>
  );
}

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Body = ModalBody;
