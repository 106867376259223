import React from 'react';
import { HAPPY, NEUTRAL, UNHAPPY } from 'utils/constants';
import './_surveyNpsSection.scss';

export default function SurveyNpsSection() {
  return (
    <div className="sectionWrapper-nps">
      <div className="surveyBuilderSection">
        <p>Please select your satisfaction level?</p>
        <div className="surveyPreviewQuestionWrapper experienceIcons">
          <img src="/assets/icons/icon_happy.svg" alt={HAPPY} />
          <img src="/assets/icons/icon_neutral.svg" alt={NEUTRAL} />
          <img src="/assets/icons/icon_unhappy.svg" alt={UNHAPPY} />
        </div>
      </div>
    </div>
  );
}
