import { BASIC_DETAIL_STEP, URLS } from 'utils/constants';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import {
  useOnboardingRedirect,
  usePageTitle,
  useScreenshot,
} from 'utils/hooks';
import useOrganizationSubmitHandler from './useOrganizationSubmitHandler';
import { OrganizationForm } from 'components/forms';
import { useFormik } from 'formik';
import { regOrganizationSchema } from 'utils/validations';
import './_regOrganisation.scss';
import { useEffect, useRef } from 'react';
import { dataURLtoFile } from 'utils/helpers';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustryTypes } from 'redux/settings/settingsActions';

export default function RegOrganisation() {
  const { isLoading: isSubmitting, onSubmit } = useOrganizationSubmitHandler();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [, takeScreenshot] = useScreenshot();
  const { user } = useSelector((s) => s.authentication);
  const form = useFormik({
    initialValues: {
      name: '',
      logo: '',
      type: {},
      email: '',
      phone_number: '',
      tnc: !user.is_google_login,
    },
    onSubmit: async ({ type: { id, name, isCustom }, ...rest }) => {
      const values = {
        ...rest,
        [isCustom ? 'custom_type' : 'industry_type']: isCustom ? name : id,
      };
      let logo = null;
      if (!values.logo) {
        logo = dataURLtoFile(await takeScreenshot(ref.current));
      }
      if (!values.email) {
        delete values.email;
      }
      if (!values.phone_number) {
        delete values.phone_number;
      }
      onSubmit({
        ...values,
        logo: values.logo ? values.logo : logo,
      });
    },
    validationSchema: regOrganizationSchema,
  });

  useOnboardingRedirect();
  usePageTitle('Organization Setup');

  useEffect(() => {
    dispatch(getIndustryTypes({ is_onboarding: true }));
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BASIC_DETAIL_STEP} />
        <OnboardingLayout.Form
          title="Let's Set Up Your Organization"
          subtitle="Please enter your organization details to continue"
        >
          <OrganizationForm form={form} avatarRef={ref} />
          {user.is_google_login && (
            <label className="checkbox">
              <input
                name="tnc"
                id="tnc"
                type="checkbox"
                checked={form.values.tnc ? true : null}
                onChange={form.handleChange}
              />
              <span>
                I agree to CXMeter
                <Link to={URLS.TERMS_AND_CONDITIONS_URL} target="_blank">
                  {' '}
                  terms and conditions.{' '}
                </Link>
                See our
                <Link to={URLS.PRIVACY_POLICY_URL} target="_blank">
                  {' '}
                  privacy policy{' '}
                </Link>
                for details on our privacy practices.
              </span>
              {form.touched.tnc && (
                <span className="txt-error">{form.errors.tnc}</span>
              )}
            </label>
          )}
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={isSubmitting}
          onClickContinue={form.submitForm}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
