import { React } from 'react';
import { Switch } from 'components/switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CUSTOMER_DETAIL_FORM_TITLE } from 'utils/constants';

import './_surveyCustomerDetail.scss';

export default function SurveyCustomerDetail({
  enableCustomerDetail,
  setEnableCustomerDetail,
}) {
  const handleRequiredChange = (e) => {
    setEnableCustomerDetail(e.target.checked);
  };

  return (
    <div className="customerDetailBlock">
      <div className="surveyBuilderSection">
        <div className="topMessage">
          <span>{CUSTOMER_DETAIL_FORM_TITLE}</span>
        </div>
        <div className="block">
          <div className="form">
            <div className="inputBlock">
              <div className="inputHolder">
                <div className="icons">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input placeholder="Name" disabled />
              </div>
            </div>
            <div className="inputBlock">
              <div className="inputHolder">
                <div className="icons">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input placeholder="Email Address" disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="enableDetailSwitch">
          <p>Enable</p>
          <Switch
            onChange={handleRequiredChange}
            checked={enableCustomerDetail}
          />
        </div>
      </div>
    </div>
  );
}
