import { CXMETER_SURVEY_TITLE } from 'config';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import PortalSuggestion from './PortalSuggestion';
import PortalSegment from './PortalSegment';
import './_portalFeedback.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'redux/authentication/authenticationActions';
import { getPortalFeedbackForms } from 'redux/portalFeedback/portalFeedbackActions';
import { Spinner } from 'react-bootstrap';
import selectFeedbackForm from 'utils/helpers/portalFeedbackHelpers';
import { useSubmitFeedback } from 'utils/hooks';

function PortalFeedback({ setShowPortalFeedbackSurvey }) {
  const dispatch = useDispatch();
  const { isLoading, forms } = useSelector((state) => state.portalFeedback);
  const { setExpId, setStartTime, submitFeedback } = useSubmitFeedback();
  const [feedbackStep, setFeedbackStep] = useState('segment');
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [suggestionInput, setSuggestionInput] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);

  const submitFeedbackHandler = () => {
    const feedback = [
      {
        key: selectedForm.survey.sections[0].questions[0].question_uuid,
        value: suggestionInput,
      },
    ];
    submitFeedback(selectedSegment, feedback).then(() => {
      setShowPortalFeedbackSurvey(false);
    });
  };

  const skipFeedback = () => {
    dispatch(updateUser({ survey_skipped_at: new Date() }));
    setShowPortalFeedbackSurvey(false);
  };

  useEffect(() => {
    dispatch(getPortalFeedbackForms());
  }, []);

  useEffect(() => {
    if (forms) {
      const [expId, survey] = selectFeedbackForm(forms, CXMETER_SURVEY_TITLE);
      setSelectedForm({
        survey,
        experienceId: expId,
      });
      setExpId(expId);
      setStartTime(new Date());
    }
  }, [forms]);

  return (
    <div className="portal-feedback">
      <div className="portal-feedback__backdrop" />
      <div className="portal-feedback__modal">
        {isLoading ? (
          <div className="portal-feedback__spinner">
            <Spinner />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faXmark}
              className="portal-feedback__close-icon"
              onClick={() => skipFeedback()}
            />
            {feedbackStep === 'segment' && (
              <PortalSegment
                setFeedbackStep={setFeedbackStep}
                setSelectedSegment={setSelectedSegment}
                skipFeedback={skipFeedback}
              />
            )}
            {feedbackStep === 'suggestion' && (
              <PortalSuggestion
                setSuggestionInput={setSuggestionInput}
                submitFeedback={submitFeedbackHandler}
                question={selectedForm.survey.sections[0].questions[0]}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PortalFeedback;
