/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorToast } from 'utils/helpers';
import {
  assignEntitySurveyForm,
  createExperienceAPICall,
  entityLinkedExperiencesGetAPICall,
  updateEntitySurveyAssignment,
} from 'apis/cxmeter';

export const postExperience = createAsyncThunk(
  'experience/postExperience',
  async (data) =>
    createExperienceAPICall(data.exp.name, data.industryType).catch(
      showErrorToast,
    ),
);

export const assignFormtoExperience = createAsyncThunk(
  'experience/assignForm',
  async (params) =>
    assignEntitySurveyForm(params.branchId, params.data).catch(showErrorToast),
);

export const updateFormtoExperience = createAsyncThunk(
  'experience/updateForm',
  async (params) =>
    updateEntitySurveyAssignment(params.branchId, params.data).catch(
      showErrorToast,
    ),
);

export const getEntityLinkedExperiences = createAsyncThunk(
  'experience/getEntityLinkedExperiences',
  async (params) =>
    entityLinkedExperiencesGetAPICall(params).catch(showErrorToast),
);
