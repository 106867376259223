/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Loader } from 'components';
import { FEEDBACK_FORMS_SETTINGS_URL } from 'utils/constants/urlConstants';
import { branchFormSetupSchema } from 'utils/validations';
import { useFormik } from 'formik';
import { FeedbackFormAssignmentSect } from 'pages/onboarding/branchFeedbackFormSetup/BranchFeedbackFormsSetupScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getBranchAssignments } from 'redux/settings/settingsActions';
import {
  setConfigWidgets,
  setEnabledWidgets,
} from 'redux/dashboard/dashboardSlice';
import {
  assignFormtoExperience,
  postExperience,
  updateFormtoExperience,
} from 'redux/experience/experienceActions';
import { resetExperienceSlice } from 'redux/experience/experienceSlice';

export default function ExperienceForm() {
  const { state } = useLocation();
  const { locationId } = useParams();
  const { isLoading, branchAssignments } = useSelector((s) => s.settings);
  const {
    redirectToSurvey,
    entityData: newExp,
    updatedForms,
  } = useSelector((s) => s.experience);
  const { industry_type: industryType } = useSelector(
    (s) => s.authentication.parentEntity ?? {},
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onExperienceInfoCreate = (values) => {
    dispatch(
      assignFormtoExperience({ branchId: locationId, data: values.forms }),
    );
  };

  const onExperienceInfoUpdate = (values) => {
    dispatch(
      updateFormtoExperience({ branchId: locationId, data: values.forms }),
    );
  };

  const formik = useFormik({
    initialValues: state?.formik
      ? { ...state?.formik }
      : {
          forms: [{}],
        },
    onSubmit: async (values) => {
      if (locationId) {
        return onExperienceInfoUpdate(values);
      }
      return onExperienceInfoCreate(values);
    },
    validationSchema: branchFormSetupSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const addMore = () => {
    formik?.setFieldValue('forms', [...formik.values.forms, {}]);
  };

  const onSave = useCallback(async () => {
    formik.submitForm();
  }, []);

  const handleOnFormChange = async (i, obj) => {
    if (obj.survey_id) {
      dispatch(setEnabledWidgets([]));
      dispatch(setConfigWidgets([]));
    }
    await formik.setFieldValue(`forms[${i}]`, obj);
    onSave();
  };

  const handleAddNewForm = async (exp, i) => {
    const errors = await formik.validateForm();
    if (errors.forms?.[i]?.experience) return;
    if (exp.isCustom) {
      dispatch(postExperience({ exp, industryType }));
    } else {
      navigate(
        `${FEEDBACK_FORMS_SETTINGS_URL}/new/?next=${location.pathname}&expId=${exp.id}&expName=${exp.name}`,
        {
          state: { formik: formik.values },
        },
      );
    }
  };

  const handleEdit = (id, exp) => {
    navigate(
      `${FEEDBACK_FORMS_SETTINGS_URL}/${id}/?next=${location.pathname}&expId=${exp.id}&expName=${exp.name}`,
      {
        state: { formik: formik.values },
      },
    );
  };

  const handleDelete = async (selectedId) => {
    const newForms = {
      ...formik,
      values: {
        ...formik.values,
        forms: formik.values.forms.filter((form) => form.id !== selectedId),
      },
    };
    if (locationId) {
      return onExperienceInfoUpdate(newForms.values);
    }
    return onExperienceInfoCreate(newForms.values);
  };

  useEffect(() => {
    if (locationId) {
      dispatch(getBranchAssignments(locationId));
    }
  }, [locationId]);

  useEffect(() => {
    // If page is not redirected from Feedback Form Detail page then don't reset the forms
    if (branchAssignments.length && !state?.formik) {
      let forms = [{}];
      forms = branchAssignments;
      formik.setValues({ forms });
    }
  }, [branchAssignments]);

  useEffect(() => {
    if (!_.isEmpty(formik.initialTouched))
      toast.error('Please fill in the complete form');
  }, [formik.errors]);

  useEffect(() => {
    if (redirectToSurvey) {
      formik.values.forms = formik.values.forms.filter(
        (form) => !form.experience?.isCustom,
      );
      formik.values.forms.push({
        experience: newExp.id,
      });
      navigate(
        `${FEEDBACK_FORMS_SETTINGS_URL}/new/?next=${location.pathname}&expId=${newExp.id}&expName=${newExp.name}`,
        {
          state: { formik: formik.values },
        },
      );
      dispatch(resetExperienceSlice());
    }
  }, [redirectToSurvey, newExp]);

  useEffect(() => {
    if (updatedForms) {
      formik.setFieldValue('forms', updatedForms);
    }
  }, [updatedForms]);

  if (isLoading) return <Loader />;

  return (
    <div className="assignmentFormContainer">
      <div>
        <h1 style={{ marginBottom: 0 }}>Link a survey</h1>
        <p>Link a survey with this branch here</p>
      </div>
      <h3>{state?.branch?.name}</h3>
      <div className="expListContainer">
        {formik?.values?.forms.map((f, i) => (
          <div className="mb-10" key={i}>
            <FeedbackFormAssignmentSect
              handleAddNewForm={(exp) => handleAddNewForm(exp, i)}
              onEdit={handleEdit}
              handleSurveyEdit={handleEdit}
              showAddNew={i === formik.values.forms.length - 1}
              selectedExperiences={formik.values.forms.map((o) => o.experience)}
              hideClose={
                formik.values.forms.length < 2 || updatedForms?.length < 2
              }
              onDelete={(selectedId) => {
                handleDelete(selectedId);
              }}
              onChange={(obj) => {
                handleOnFormChange(i, obj);
              }}
              value={{ ...f }}
              error={_.get(formik, `errors.forms[${i}]`, {})}
              onAddMore={addMore}
              redirectState={{
                branch: state?.branch,
                formik: { ...formik.values },
              }}
              selectedExperiencesIndex={i}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
