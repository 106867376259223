/* eslint-disable no-unsafe-optional-chaining */

import { isEmpty } from 'lodash';
import { toTitleCase } from 'utils/helpers';

/* eslint-disable no-underscore-dangle */
const lineChartOptions = (onClick, yScales, xLabel, yLabel) => ({
  responsive: true,
  spanGaps: true,
  plugins: {
    legend: {
      display: true,
    },
    datalabels: {
      display: true,
      color: 'white',
    },
    tooltip: {
      position: 'nearest',
      enabled: true,
      mode: 'point',
      backgroundColor: 'white',
      titleColor: 'black',
      titleFont: {
        size: 18,
      },
      titleFontSize: 28,
      caretSize: 10,
      borderColor: '#868584',
      bodyColor: '#868584',
      borderWidth: 0.5,
      boxHeight: 7,
      boxPadding: 2,
      padding: 20,
      usePointStyle: true,
      callbacks: {
        title(data) {
          return data[0].formattedValue;
        },
        label() {
          return '';
        },
        labelTextColor: () => 'black',
        beforeBody(data) {
          const { datasetLabel, additionalData } = data[0].dataset;
          if (additionalData && !isEmpty(additionalData[data[0].label])) {
            let labelStr = `${toTitleCase(datasetLabel)}\n\n`;
            Object.entries(additionalData[data[0].label]).forEach(
              ([key, value]) => {
                labelStr += `${toTitleCase(key)} - ${value}\n`;
              },
            );

            return labelStr;
          }
          return datasetLabel;
        },
      },
    },
  },
  clip: false,
  scales: {
    y: {
      min: yScales.min,
      max: yScales.max,
      title: {
        text: yLabel,
        display: true,
      },
    },
    x: {
      title: {
        text: xLabel,
        display: true,
      },
    },
  },
  maintainAspectRatio: false,
  onClick(e, item) {
    if (item.length < 1) return;
    const currentElement = {
      label: e.chart.data.labels[item[0]?.index],
      value: e.chart.data.datasets[item[0]?.datasetIndex].data[item[0].index],
      datasetLabel: e.chart.data.datasets[item[0]?.datasetIndex].datasetLabel,
    };
    onClick(currentElement);
  },
});

export default lineChartOptions;
